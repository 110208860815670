import * as openpgp from 'openpgp'

async function getPrimaryUser(key) {
    let email = null;
    try {
        let primary_user = await key.getPrimaryUser();
        if (primary_user !== null) {
            let user = primary_user.user;
            if (user !== null) {
                let user_id = user.userId;
                if (user_id !== null) {
                    email = user_id.email;
                }
            }
        }
    } catch (err) {

    }
    if (email === null) {
        try {
            if (key.users.length > 0) {
                for (const user of key.users) {
                    if (user.userId && user.userId.email) {
                        email = user.userId.email;
                        break;
                    }
                }
            }
        } catch (err) {

        }
    }
    if (email !== null) {
        return email;
    } else {
        return "";
    }
}

async function parseKeys(publicKeys) {
    let ret = {}
    for (const key of publicKeys.keys) {
        if (key.users && key.users.length > 0) {
            let primary_email = await getPrimaryUser(key)
            let emails = []
            for (const user of key.users) {
                if (user.userId && user.userId.email) {
                    emails.push(user.userId.email);
                }
            }
            let key_data = key.armor()
            let id = key.primaryKey.getKeyId().toHex()
            let expiry = 0;
            try {
                expiry = await key.getExpirationTime();
                if (typeof expiry.getTime === 'function') {
                    expiry = expiry.getTime();
                }
            } catch (err) {
                expiry = 0;
            }
            ret[id] = {"primary_email": primary_email, "emails": emails, "id": id, "expiry": expiry, "armored": key_data}
        }
    }
    return ret;
}

async function readKeys(file, callback) {
    let reader = new FileReader();
    reader.onload = async function () {
        const publicKeys = await openpgp.key.readArmored(reader.result);
        parseKeys(publicKeys).then(ret => {
            callback({"data": ret})
        })
    };
    reader.onerror = function (error) {
        callback({"error": "Failed to read file"})
    };
    reader.readAsText(file);
}

async function readMultipleKeyFiles(files, callback) {
    let keys = {}
    await readKeysHelper(files, keys, callback);
}

async function readKeysHelper(files, keys, callback) {
    if (files.length === 0) {
        callback({"data": keys});
        return;
    }
    let file = files.pop();
    let reader = new FileReader();
    reader.onload = async function () {
        const publicKeys = await openpgp.key.readArmored(reader.result);
        parseKeys(publicKeys).then(ret => {
            for (const [id, pub_key] of Object.entries(ret)) {
                keys[id] = pub_key;
            }
            readKeysHelper(files, keys, callback);
        })
    };
    reader.onerror = function (error) {
        readKeysHelper(files, keys, callback);
    };
    reader.readAsText(file);
}

async function readKey(data, callback) {
    const publicKeys = await openpgp.key.readArmored(data);
    if (publicKeys.length === 0) {
        callback({"error": "No keys found"})
        return
    }
    let key = publicKeys[0]
    callback({"key": key})
}

export {readKeys, readKey, readKeysHelper, readMultipleKeyFiles}