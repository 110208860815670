import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Container, Grid, Paper} from "@material-ui/core";
import clsx from "clsx";
import {get_org_directories, get_org_directories_link, save_org_state} from "../api";
import {DataGrid} from "@material-ui/data-grid";

class CCDirectories extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            selected: []
        };
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        get_org_directories(this.props.org.org_uid, this.props.org.org_auth_key, json => {
            json.dirs.forEach(dir => {
                let domain = dir.domain
                if (domain === undefined && dir.domains !== null && dir.domains !== undefined) {
                    dir.domains.forEach(dom => {
                        domain = dom.domain
                    })
                    dir.domain = domain
                }
            })
            this.setState({
                dirs: json.dirs,
                loading: false,
            })
        })
    }

    openWorkPortal() {
        this.setState({
            loading: true
        }, () => {
            get_org_directories_link(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                save_org_state(window, this.props.org.org_uid, "Directory Sync")
                window.location = json.link.link;
            })
        })
    }

    renderCreate(classes) {
        return [
            <Grid item xs={12}>
                <Button className={classes.button}
                        variant="contained"
                        onClick={this.openWorkPortal.bind(this)}>Connect Directory</Button>
            </Grid>
        ]
    }

    renderManage(classes) {
        const columns = [
            {field: 'name', headerName: 'Name', flex: 1,},
            {field: 'domain', headerName: 'Domain', flex: 1,},
            {field: 'state', headerName: 'State', flex: 1,},
            {field: 'type', headerName: 'Type', flex: 1,},
        ]
        return [
            <Grid item xs={12}>
                <DataGrid autoHeight columns={columns}
                          className={classes.paperGrid}
                          rows={this.state.dirs}
                          loading={this.state.loading}
                          checkboxSelection={true}
                          selectionModel={this.state.selected}
                          onSelectionModelChange={change => this.setState({selected: change})}
                          pageSize={20}
                          onRowClick={item => {
                              this.setState({
                                  active: item.row
                              })
                          }}/>
            </Grid>,
            <Grid item xs={12}>
                <Button className={classes.button}
                        variant="contained"
                        onClick={this.openWorkPortal.bind(this)}>Manage</Button>
            </Grid>];
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        let hasDirectories = this.state.dirs !== undefined && this.state.dirs !== null && this.state.dirs.length > 0;

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={3}>
                    {
                        !hasDirectories && this.renderCreate(classes)
                    }
                    {
                        hasDirectories && this.renderManage(classes)
                    }
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCDirectories)