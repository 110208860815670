import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import config from "../../config";
import React, {createContext} from "react";
import {getBaseUrl} from "./api";
import {console_log} from "./log";
import {rootAdminUrl} from "../constants";

class AuthHandler {
    constructor() {
        this.callbacks = [];
        this.loading = true;
        this.user = null;
        this.isSignedIn = false;
        this.authKey = null;
        this.firebaseApp = firebase.initializeApp(config);
        this.firebaseAppAuth = this.firebaseApp.auth();
        this.firebaseAppAuth.onAuthStateChanged(user => {
            this.setIsSignedIn(user);
        });

        this.setLoading = this.setLoading.bind(this);
        this.setIsSignedIn = this.setIsSignedIn.bind(this);
        this.signInWithGoogle = this.signInWithGoogle.bind(this);
        this.signInWithEmail = this.signInWithEmail.bind(this);
        this.signUpWithEmail = this.signUpWithEmail.bind(this);
        this.sendEmailVerification = this.sendEmailVerification.bind(this);
        this.signOut = this.signOut.bind(this);
        this.fetchAuthKey = this.fetchAuthKey.bind(this);
        this.addCallback = this.addCallback.bind(this);
        this.removeCallback = this.removeCallback.bind(this);
        this.callCallbacks = this.callCallbacks.bind(this);
    }

    setLoading(loading) {
        this.loading = loading;
        this.callCallbacks();
    }

    hadSignedIn() {
        return window.localStorage.getItem('didSignIn') === 'True'
    }

    setIsSignedIn(user) {
        this.user = user;
        this.isSignedIn = !!user;
        console_log('Sign in updated: ', this.isSignedIn)
        if (this.isSignedIn) {
            window.localStorage.setItem('didSignIn', 'True')
            this.fetchAuthKey();
        } else {
            window.localStorage.setItem('didSignIn', 'False')
            this.signInWithEmailLink();
        }
    }

    signInWithEmailLink() {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('verificationEmail');
            if (!email) {
                this.setLoading(false);
            } else {
                firebase.auth().signInWithEmailLink(email, window.location.href)
                    .then((result) => {
                        window.localStorage.removeItem('verificationEmail');
                        // Wait for login to finish
                    })
                    .catch((error) => {
                        this.setLoading(false);
                    });
            }
        } else {
            this.setLoading(false);
        }
    }

    signInWithGoogle() {
        console_log('Signing in')
        let provider = new firebase.auth.GoogleAuthProvider();
        this.setLoading(true);
        this.firebaseAppAuth.signInWithRedirect(provider).then(() => {

        });
    }

    signInWithEmail(email, callback) {
        this.signUpWithEmail(email, callback)
    }

    signUpWithEmail(email, callback) {
        console_log('Signing up with email')
        const url = rootAdminUrl + "?op=finishSignUp"
        this.setLoading(true);
        let actionCodeSettings = {
            url: url,
            handleCodeInApp: true,
        }
        this.firebaseAppAuth.sendSignInLinkToEmail(email, actionCodeSettings)
            .then(() => {
                window.localStorage.setItem('verificationEmail', email);
                console_log('Calling callback')
                callback()
                this.setLoading(false);
            })
            .catch((error) => {
                this.setLoading(false);
            })
    }

    sendEmailVerification() {
        console_log('Sending verification email')
        this.setLoading(true);
        this.user.sendEmailVerification().then(() => {
            this.setLoading(false);
        }).catch((error) => {
            console_log(error);
            this.setLoading(false);
        });
    }

    signOut() {
        this.firebaseAppAuth.signOut();
    }

    doc() {
        return firebase.firestore().collection("users").doc(this.user.email);
    }

    fetchAuthKey() {
        if (this.isSignedIn) {
            console_log('Fetching auth key')
            this.doc().get().then(doc => {
                let auth_key = doc.exists ? doc.get('auth_key') : null
                if (auth_key) {
                    console_log('Got auth key')
                    this.authKey = auth_key;
                    this.setLoading(false);
                } else {
                    console_log('No auth key, requesting new')
                    fetch(getBaseUrl() + "/admin/auth/" + this.user.email).then(r => {
                        this.fetchAuthKey()
                    })
                }
            })
        } else {
            this.setLoading(false);
        }
    }

    addCallback(callback) {
        this.callbacks.push(callback);
    }

    removeCallback(callback) {
        this.callbacks = this.callbacks.filter(item => item !== callback);
    }

    callCallbacks() {
        this.callbacks.forEach(callback => {
            callback();
        })
    }
}

const auth = new AuthHandler()

const AuthContext = createContext(auth)

export { AuthContext }

const authWrapper = ({ children }) => {
    return (
        <AuthContext.Provider value={{auth}}>
            {children}
        </AuthContext.Provider>
    )
};

export default authWrapper;