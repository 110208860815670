import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {loginStyles} from "../styles";
import {CircularProgress, Container, Grid, Typography} from "@material-ui/core";
import clsx from "clsx";
import logo from "../../../assets/canary-logo-fs8.png";

class CCCheckEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Grid container direction="column"
                     justify="center"
                     alignItems="center" spacing={3}>
            <Grid item><img className={clsx(classes.logo)} src={logo} alt=""/></Grid>
            <Grid item><Typography variant="h2"><b>Check your inbox</b></Typography></Grid>
            <Grid item><Typography variant="body2" style={{textAlign: "center", maxWidth: "300px"}}>We sent you an activation link. Please be sure to check your spam folder too.</Typography></Grid>
        </Grid>;
    }
}

export default withStyles(loginStyles)(CCCheckEmail)