import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "../styles";
import {Divider, Grid, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

class CCApiExample extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;
        const org_uid = this.props.config.org_uid;
        const api_key = this.props.config.api_key;
        const api_url = this.props.config.api_url;
        const example = this.props.config.example;
        const response = this.props.config.response;
        const params = this.props.config.params;

        return <Grid container spacing={3}>
            {
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        disabled
                        fullWidth
                        id="outlined-disabled"
                        label="Organization Id"
                        defaultValue={org_uid}
                        variant="outlined"
                        margin="none"
                    />
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <TextField
                        className={classes.textField}
                        disabled
                        fullWidth
                        id="outlined-disabled"
                        label="API Key"
                        defaultValue={api_key}
                        variant="outlined"
                        margin="none"
                    />
                </Grid>
            }
            {
                <Grid item xs={12} style={{height: "1px"}}>
                    <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <Typography variant={"body1"}>{this.props.config.info}</Typography>
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <code>Post: <code
                        className={classes.code}>{api_url}</code></code>
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <code>Content-type: <code
                        className={classes.code}>application/json</code></code>
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <code>
                        Params:
                        {
                            params.map(param => {
                                return <pre className={classes.code}
                                            style={{padding: "8px", borderRadius: "4px"}}>"{param.name}": {param.info}</pre>
                            })
                        }
                    </code>
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <code>
                        Example Request:
                        <pre className={classes.code}
                             style={{padding: "8px", borderRadius: "4px"}}>
                                {example}
                            </pre>
                    </code>
                </Grid>
            }
            {
                <Grid item xs={12}>
                    <code>Example Response: <pre
                        className={classes.code}>{response}</pre></code>
                </Grid>
            }
        </Grid>
    }
}

export default withStyles(useStyles)(CCApiExample)