import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "./styles";
import {Button, Container, Fab, Grid, Paper} from "@material-ui/core";
import {addMasterKeys, getMasterKeys, removeMasterKeys} from "./api";
import {DataGrid} from "@material-ui/data-grid";
import {DropzoneDialog} from "material-ui-dropzone";
import clsx from "clsx";
import AddIcon from '@material-ui/icons/Add';

class CCMasterKeys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drop: false,
            keys: [],
            selected: [],
            file: null,
            loading: false
        };
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        this.setState({
            loading: true,
        }, () => {
            getMasterKeys(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                this.setState({
                    keys: json,
                    loading: false,
                    selected: [],
                })
            });
        })
    }

    getColumns() {
        return [
            {
                field: 'id', headerName: 'Key ID', flex: 1,
                valueGetter: params => {
                    let id = params.value.toUpperCase().match(/.{1,4}/g);
                    return id.join(' ');
                }
            },
            {field: 'primary_email', headerName: 'Email', flex: 1,},
            {
                field: 'expiry', headerName: 'Expires', flex: 1,
                valueGetter: params => {
                    if (params.value === 0) {
                        return "Perpetual"
                    } else {
                        return new Date(params.value).toLocaleDateString();
                    }
                }
            }
        ]
    }

    renderActions() {
        const {classes} = this.props;
        return <div className={"row"}>
            {
                this.state.selected.length > 0 &&
                <Button variant="contained"
                        className={clsx(classes.button)}
                        onClick={() => {
                            this.setState({
                                loading: true,
                            }, () => {
                                removeMasterKeys(this.props.org.org_uid, this.props.org.org_auth_key, this.state.selected, json => {
                                    this.refresh()
                                })
                            })
                        }}>Delete</Button>
            }
            <Fab color="primary" className={classes.floating} aria-label="add"
                 onClick={e => {
                     this.setState({
                         drop: true
                     })
                 }}>
                <AddIcon/>
            </Fab>
        </div>
    }

    renderTable(classes) {
        return <DataGrid autoHeight
                         className={classes.paperGrid}
                         columns={this.getColumns()}
                         rows={this.state.keys}
                         rowCount={this.state.keys.length}
                         pageSize={10}
                         loading={this.state.loading}
                         selectionModel={this.state.selected}
                         disableColumnSelector={true}
                         checkboxSelection={true}
                         onSelectionModelChange={change => this.setState({selected: change})}
                         onRowClick={item => {
                             this.setState({
                                 active: item.row
                             })
                         }}/>
    }

    render() {
        const {classes} = this.props;

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <DropzoneDialog
                    open={this.state.drop}
                    onSave={files => {
                        this.setState({drop: false, loading: true})
                        if (files.length > 0) {
                            addMasterKeys(this.props.org.org_uid, this.props.org.org_auth_key,
                                files, json => {
                                    this.refresh();
                                });
                        }
                    }}
                    showPreviews={true}
                    maxFileSize={5000000}
                    onClose={() => this.setState({drop: false})}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {
                            this.renderTable(classes)
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            this.renderActions()
                        }
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCMasterKeys)