import {readKey, readMultipleKeyFiles} from "./pgp";
import {StripePrice, StripeProduct, StripePromoCode, StripeSubscription} from "./stripe/objects";
import {get_default_prefs_state, kPrefPGP, kPrefPreferredMode, kPrefSecureSend} from "./data";
import {baseUrl} from "../constants";

function postJson(url, data, callback) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(callback)
}

function requestSubscriptions(email, authKey, callback) {
    const url = baseUrl + "/subscriptions"
    postJson(url, {"email": email, "auth_key": authKey}, json => {
        let subscriptions = []
        let prices = {}
        let products = {}
        if (typeof(json) === "string") {
            json = JSON.parse(json)
        }
        if (json.hasOwnProperty('subscriptions')) {
            let json_subs = json['subscriptions']
            for (let i = 0; i < json_subs.length; i++) {
                subscriptions.push(new StripeSubscription(json_subs[i]))
            }
        }
        if (json.hasOwnProperty('products')) {
            let json_products = json['products']
            for (let i = 0; i < json_products.length; i++) {
                let product = new StripeProduct(json_products[i])
                products[product.getId()] = product
            }
        }
        if (json.hasOwnProperty('prices')) {
            let json_prices = json['prices']
            for (let i = 0; i < json_prices.length; i++) {
                let price = new StripePrice(json_prices[i]);
                prices[price.getId()] = price
            }
        }
        let ret = {'subscriptions': subscriptions, 'prices': prices, 'products': products,
            'seats_total': json.seats_total, 'seats_used': json.seats_used, 'seats_remaining': json.seats_remaining,
            'seats_needed': json.seats_needed, 'is_pilot': json.is_pilot,
            'pilot_expiry': json.pilot_expiry}
        callback(ret);
    });
}

function createSubscription(email, authKey, priceId, quantity, promoCode, callback) {
    const post = {"email": email, "auth_key": authKey, "price_id": priceId, "quantity": quantity}
    if (promoCode !== null && promoCode !== undefined && promoCode instanceof StripePromoCode) {
        post['promo_code'] = promoCode.getId();
    }
    const url = baseUrl + "/subscriptions/create"
    postJson(url, post, callback)
}

function resumeSubscription(email, authKey, subId, callback) {
    const post = {"email": email, "auth_key": authKey, "sub_id": subId}
    const url = baseUrl + "/subscriptions/resume"
    postJson(url, post, callback)
}

function updateSubscriptions(email, authKey, subId, quantity, priceId, promoCode, callback) {
    const post = {"email": email, "auth_key": authKey, "subscription_id": subId, "quantity": quantity}
    if (priceId !== null) {
        post['price_id'] = priceId;
    }
    if (promoCode !== null && promoCode !== undefined && promoCode instanceof StripePromoCode) {
        post['promo_code'] = promoCode.getId();
    }
    const url = baseUrl + "/subscriptions/update"
    postJson(url, post, callback)
}

function getPortalLink(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/portal"
    postJson(url, post, callback)
}

// Licenses

function requestLicenses(email, authKey, callback) {
    const url = baseUrl + "/licenses"
    postJson(url, {"email": email, "auth_key": authKey}, callback)
}

function getLicenseEmails(email, authKey, userEmail, callback) {
    const url = baseUrl + "/licenses/emails"
    postJson(url, {"email": email, "auth_key": authKey, "user_email": userEmail}, callback)
}

function getLicense(email, authKey, licenseKey, callback) {
    const post = {"email": email, "auth_key": authKey, "license_key": licenseKey}
    const url = baseUrl + "/licenses/get"
    postJson(url, post, callback)
}

function createLicenses(email, authKey, emails, callback) {
    const post = {"email": email, "auth_key": authKey, "emails": emails}
    const url = baseUrl + "/licenses/create"
    postJson(url, post, callback)
}

function saveLicense(email, authKey, newLicense, callback) {
    const post = {"email": email, "auth_key": authKey, "license": newLicense}
    const url = baseUrl + "/licenses/update"
    postJson(url, post, callback)
}

function deleteLicenses(email, authKey, keys, callback) {
    const post = {"email": email, "auth_key": authKey, "keys": keys}
    const url = baseUrl + "/licenses/delete"
    postJson(url, post, callback)
}

function inviteLicense(email, authKey, licenseEmail, licenseKey, callback) {
    const post = {"email": email, "auth_key": authKey, "license_email": licenseEmail, "license_auth_key": licenseKey}
    const url = baseUrl + "/licenses/invite"
    postJson(url, post, callback)
}

// Keys

function getKeys(email, authKey, pageNum, pageSize, callback) {
    const post = {"email": email, "auth_key": authKey, 'page_num': pageNum, 'page_size': pageSize}
    const url = baseUrl + "/keys"
    postJson(url, post, callback)
}

function getApprovedKeys(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/keys"
    postJson(url, post, callback)
}

function uploadKeyFiles(email, authKey, keyfiles, callback) {
    readMultipleKeyFiles(keyfiles, json => {
        if (json.data) {
            const post = {"email": email, "auth_key": authKey, "public_keys": json.data}
            const url = baseUrl + "/keys/upload"
            postJson(url, post, callback)
        } else {
            callback({"error": json.error})
        }
    })
}

function deleteKeys(email, authKey, key_ids, callback) {
    const post = {"email": email, "auth_key": authKey, "key_ids": key_ids}
    const url = baseUrl + "/keys/delete"
    postJson(url, post, callback)
}

function getKey(email, authKey, key_id, callback) {
    const post = {"email": email, "auth_key": authKey, "key_id": key_id}
    const url = baseUrl + "/keys/get"
    postJson(url, post, json => {
        if (json['armored']) {
            readKey(json['armored'], key => {
                callback(key)
            }).then()
        } else {
            callback({"error": "no key found"})
        }
    })
}

function getUnapprovedKeys(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/keys/unapproved"
    postJson(url, post, callback)
}

function getUnapprovedKey(email, authKey, keyId, callback) {
    const post = {"email": email, "auth_key": authKey, "key_id": keyId}
    const url = baseUrl + "/keys/unapproved/get"
    postJson(url, post, callback)
}

function deleteUnapprovedKeys(email, authKey, keyIds, callback) {
    const post = {"email": email, "auth_key": authKey, "key_ids": keyIds}
    const url = baseUrl + "/keys/unapproved/delete"
    postJson(url, post, callback)
}

function approveKeys(email, authKey, keyIds, callback) {
    const post = {"email": email, "auth_key": authKey, "key_ids": keyIds}
    const url = baseUrl + "/keys/unapproved/approve"
    postJson(url, post, callback)
}

// Prefs

function getPrefs(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/prefs"
    postJson(url, post, callback)
}

function savePrefs(email, authKey, prefs, callback) {
    const post = {"email": email, "auth_key": authKey, "prefs": prefs}
    const url = baseUrl + "/prefs/save"
    postJson(url, post, callback)
}

// Organization

function getOrgDetails(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/org"
    postJson(url, post, callback)
}

function saveOrgDetails(email, authKey, details, callback) {
    const post = {"email": email, "auth_key": authKey, "details": details}
    const url = baseUrl + "/org/save"
    postJson(url, post, callback)
}

function deleteOrg(orgId, authKey, email, callback) {
    const post = {"org_id": orgId, "auth_key": authKey, "admin_email": email}
    const url = baseUrl + "/org/delete"
    postJson(url, post, callback)
}

// Key Servers

function getKeyServers(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "GET"}
    const url = baseUrl + "/keys/servers"
    postJson(url, post, callback)
}

function addKeyServers(email, authKey, servers, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "ADD", "key_servers": servers}
    const url = baseUrl + "/keys/servers"
    postJson(url, post, callback)
}

function removeKeyServers(email, authKey, servers, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "DEL", "key_servers": servers}
    const url = baseUrl + "/keys/servers"
    postJson(url, post, callback)
}

// Key Servers

function getMasterKeys(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "GET"}
    const url = baseUrl + "/keys/master"
    postJson(url, post, callback)
}

function addMasterKeys(email, authKey, keyfiles, callback) {
    readMultipleKeyFiles(keyfiles, json => {
        if (json.data) {
            let ret = []
            for (let key in json.data) {
                ret.push(json.data[key])
            }
            const post = {"email": email, "auth_key": authKey, "keys": ret, "op": "ADD"}
            const url = baseUrl + "/keys/master"
            postJson(url, post, callback)
        } else {
            callback({"error": json.error})
        }
    }).then()
}

function removeMasterKeys(email, authKey, keys, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "DEL", "keys": keys}
    const url = baseUrl + "/keys/master"
    postJson(url, post, callback)
}

// Configs

function getConfigs(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "GET"}
    const url = baseUrl + "/configs"
    postJson(url, post, callback)
}

function addConfigs(email, authKey, configs, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "ADD", "configs": configs}
    const url = baseUrl + "/configs"
    postJson(url, post, callback)
}

function removeConfigs(email, authKey, configs, callback) {
    const post = {"email": email, "auth_key": authKey, "op": "DEL", "configs": configs}
    const url = baseUrl + "/configs"
    postJson(url, post, callback)
}

// Api

function getApiState(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/org/api"
    postJson(url, post, callback)
}

function setApiState(email, authKey, allow, callback) {
    const post = {"email": email, "auth_key": authKey, "allow": allow}
    const url = baseUrl + "/org/api"
    postJson(url, post, callback)
}

// Helpers

function guidGenerator() {
    let S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4());
}

function getBaseUrl() {
    return baseUrl;
}

// Admin Helpers

function get_orgs(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/admin/orgs"
    postJson(url, post, callback)
}

function add_org(email, authKey, name, callback) {
    const post = {"email": email, "auth_key": authKey, "name": name}
    const url = baseUrl + "/admin/orgs/create"
    postJson(url, post, callback)
}

function get_org_users(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/admin/orgs/getusers"
    postJson(url, post, callback)
}

function add_org_user(email, authKey, adminEmail, callback) {
    const post = {"email": email, "auth_key": authKey, "admin_email": adminEmail}
    const url = baseUrl + "/admin/orgs/adduser"
    postJson(url, post, callback)
}

function del_org_users(email, authKey, adminEmails, callback) {
    const post = {"email": email, "auth_key": authKey, "admin_emails": adminEmails}
    const url = baseUrl + "/admin/orgs/deluser"
    postJson(url, post, callback)
}

// State

function save_org_state(window, org_uid, tab) {
    window.localStorage.setItem("lastOrg", org_uid)
    window.localStorage.setItem("lastTab", tab)
}

function get_org_state(window) {
    let org = window.localStorage.getItem("lastOrg")
    let tab = window.localStorage.getItem("lastTab")
    window.localStorage.removeItem("lastOrg")
    window.localStorage.removeItem("lastTab")
    return [org, tab]
}

function peek_org_state(window) {
    let org = window.localStorage.getItem("lastOrg")
    let tab = window.localStorage.getItem("lastTab")
    return [org, tab]
}

function clear_org_state(window) {
    window.localStorage.removeItem("lastOrg")
    window.localStorage.removeItem("lastTab")
}

function has_orgs(window) {
    return window.localStorage.getItem("hasOrgs") === "true";
}

function put_orgs(window, count) {
    if (count > 0) {
        window.localStorage.setItem("hasOrgs", "true")
    } else {
        window.localStorage.setItem("hasOrgs", "false")
    }
}

// Directories

function get_org_directories(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/directories"
    postJson(url, post, json => {
        if (typeof(json) === "string") {
            json = JSON.parse(json)
        }
        callback(json);
    })
}

function get_org_directories_link(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/directories/link"
    postJson(url, post, callback)
}

// Promo codes

function validate_promo_code(code, callback) {
    const post = {"promo_code": code}
    const url = baseUrl + "/subscriptions/code"
    postJson(url, post, callback)
}

function get_promo_code(code_id, callback) {
    const post = {"promo_code_id": code_id}
    const url = baseUrl + "/subscriptions/code/get"
    postJson(url, post, callback)
}

// Api keys

function get_api_keys(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/api_keys/get"
    postJson(url, post, callback);
}

function create_api_key(email, authKey, scopes, callback) {
    const post = {"email": email, "auth_key": authKey, "scopes": scopes}
    const url = baseUrl + "/api_keys/create"
    postJson(url, post, callback);
}

function delete_api_keys(email, authKey, keyIds, callback) {
    const post = {"email": email, "auth_key": authKey, "key_ids": keyIds}
    const url = baseUrl + "/api_keys/delete"
    postJson(url, post, callback);
}

// Auto-setup

function quickstart_org(email, authKey, orgName, orgType, users, callback) {
    let prefsMap = get_default_prefs_state();
    let pgpPref = prefsMap[kPrefPGP];
    let secureSendPref = prefsMap[kPrefSecureSend];
    let lastUsedPref = prefsMap[kPrefPreferredMode];
    let prefs;
    if (orgType === "1") { // HIPAA: PGP off, SecureSend On, PreferredMode SecureSend
        pgpPref.value = false;
        secureSendPref.value = true;
        lastUsedPref.value = 1;
        prefs = {"pref_pgp": pgpPref, "pref_secure_send": secureSendPref, "pref_encryption_preferred_mode": lastUsedPref};
    } else if (orgType === "2") { // PGP: PGP on, SecureSend off, PreferredMode pgp
        pgpPref.value = true;
        secureSendPref.value = false;
        lastUsedPref.value = 0;
        prefs = {"pref_pgp": pgpPref, "pref_secure_send": secureSendPref, "pref_encryption_preferred_mode": lastUsedPref};
    } else {
        prefs = {}
    }

    const post = {"email": email, "auth_key": authKey, "name": orgName, "emails": users, "prefs": prefs}
    const url = baseUrl + "/admin/orgs/quickstart"
    postJson(url, post, callback)
}

// Console Prefs

function get_console_prefs(email, authKey, callback) {
    const post = {"email": email, "auth_key": authKey}
    const url = baseUrl + "/console_prefs"
    postJson(url, post, callback)
}

function save_console_prefs(email, authKey, prefs, callback) {
    const post = {"email": email, "auth_key": authKey, "prefs": prefs}
    const url = baseUrl + "/console_prefs/save"
    postJson(url, post, callback)
}

function set_default_team(email, authKey, teamId, callback) {
    const post = {"email": email, "auth_key": authKey, "team_id": teamId}
    const url = baseUrl + "/teams/default"
    postJson(url, post, callback)
}

// Teams

function get_teams(email, authKey, user_email, callback) {
    const post = {"email": email, "auth_key": authKey, "user_email": user_email}
    const url = baseUrl + "/teams"
    postJson(url, post, callback)
}

function create_teams(email, authKey, teams, callback) {
    const post = {"email": email, "auth_key": authKey, "teams": teams}
    const url = baseUrl + "/teams/create"
    postJson(url, post, callback)
}

function save_team(email, authKey, user_email, team, callback) {
    const post = {"email": email, "auth_key": authKey, "team": team, "user_email": user_email}
    const url = baseUrl + "/teams/update"
    postJson(url, post, callback)
}

function delete_teams(email, authKey, teamIds, callback) {
    const post = {"email": email, "auth_key": authKey, "team_ids": teamIds}
    const url = baseUrl + "/teams/delete"
    postJson(url, post, callback)
}


export {
    postJson, requestSubscriptions, guidGenerator, requestLicenses, getLicense,
    getLicenseEmails, saveLicense, deleteLicenses, getKeys, uploadKeyFiles, deleteKeys,
    getKey, getUnapprovedKeys, getUnapprovedKey, approveKeys, deleteUnapprovedKeys,
    getApprovedKeys, getPrefs, savePrefs, getOrgDetails, saveOrgDetails,
    getKeyServers, addKeyServers, removeKeyServers, getMasterKeys, addMasterKeys,
    removeMasterKeys, getConfigs, addConfigs, removeConfigs, updateSubscriptions,
    getApiState, setApiState, createLicenses, inviteLicense, getBaseUrl,
    get_orgs, add_org, get_org_users, add_org_user, del_org_users,
    createSubscription, resumeSubscription, getPortalLink, save_org_state,
    get_org_state, get_org_directories, get_org_directories_link, peek_org_state,
    clear_org_state, quickstart_org, has_orgs, put_orgs, validate_promo_code,
    get_promo_code, get_api_keys, create_api_key, delete_api_keys, deleteOrg,
    get_console_prefs, save_console_prefs, set_default_team, get_teams, create_teams, save_team, delete_teams
}