import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {
    Button, Checkbox,
    CircularProgress,
    Container,
    Fab, Grid,
    TextField, Typography,
} from "@material-ui/core";
import CCTeam from "./team";
import clsx from "clsx";
import {get_teams, create_teams, save_team, delete_teams, getLicenseEmails} from "./api";
import {DataGrid} from "@material-ui/data-grid";
import { Divider } from '@mui/material';
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

class CCTeams extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            teams: [],
            defaultTeamId: null,
            selected: [],
            licenseEmails: [],
            active: null,
            createTeamSelectedManagers: [],
            createTeamName: "",
            createTeamErrorMessage: "",
        }
        this.back = this.back.bind(this)
        this.createTeam = this.createTeam.bind(this)
        this.saveTeam = this.saveTeam.bind(this)
        this.deleteTeam = this.deleteTeam.bind(this)
        this.deleteTeams = this.deleteTeams.bind(this)
        this.refresh = this.refresh.bind(this)
    }

    back() {
        this.setState({
            active: null,
            selected: [],
            createTeamSelectedManagers: [],
            createTeamName: "",
            createTeamErrorMessage: "",
        });
    }

    // CRUD
    createTeam(name, managers, members) {
        this.back();
        let team = {
            name: name,
            managers: managers,
            members: members
        }
        this.setState({
            loading: true
        }, () => {
            create_teams(this.props.org.org_uid, this.props.org.org_auth_key, [team], json => {
                this.refresh();
            })
        })
    }

    saveTeam(team) {
        this.setState({
            loading: true
        }, () => {
            save_team(this.props.org.org_uid, this.props.org.org_auth_key, this.props.org.user_email, team, json => {
                this.refresh();
            })
        })
    }

    deleteTeam(team_id) {
        this.setState({
            loading: true
        }, () => {
            delete_teams(this.props.org.org_uid, this.props.org.org_auth_key, [team_id], json => {
                this.refresh();
            })
        })
    }

    deleteTeams(team_ids) {
        this.setState({
            loading: true
        }, () => {
            delete_teams(this.props.org.org_uid, this.props.org.org_auth_key, team_ids, json => {
                this.refresh();
            })
        })
    }

    // State
    refresh() {
        get_teams(this.props.org.org_uid, this.props.org.org_auth_key, this.props.org.user_email, json => {
            let defaultTeamId = null;
            let teams = []
            if (json) {
                defaultTeamId = json['default_team_id'];
                teams = json['teams'];
            }
            this.setState({
                loading: false,
                teams: teams,
                defaultTeamId: defaultTeamId,
            })
        })
    }

    componentDidMount() {
        getLicenseEmails(this.props.org.org_uid, this.props.org.org_auth_key, this.props.org.user_email, json => {
            this.setState({
                licenseEmails: json && json.length > 0 ? json : []
            }, () => {
                this.refresh();
            })
        })
    }

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        if (this.state.active !== null) {
            return <CCTeam back={this.back}
                           teams={this.state.teams}
                           team={this.state.active}
                           saveTeam={this.saveTeam}
                           deleteTeam={this.deleteTeam}
                           licenseEmails={this.state.licenseEmails}
                           isAdmin={this.props.org.is_admin}/>
        }

        const columns = [
            { field: 'name', headerName: 'Team', flex: 1 },
            {
                field: 'emails',
                headerName: 'Manager(s)',
                flex: 1,
                valueGetter: params => {
                    return params.row.managers.join(", ")
                },
            }
        ];

        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;

        return <Container className={classes.root}>
            <Grid container spacing={3}>
                {
                    this.state.teams && this.state.teams.length > 0 &&
                    <Grid item xs={12}>
                        {
                            <DataGrid autoHeight
                                      className={clsx(classes.paperGrid, classes.paperBackground)}
                                      columns={columns}
                                      rows={this.state.teams}
                                      pageSize={20}
                                      selectionModel={this.state.selected}
                                      disableColumnSelector={true}
                                      checkboxSelection={this.props.org.is_admin}
                                      onSelectionModelChange={change => this.setState({selected: change})}
                                      onRowClick={item => {
                                          this.setState({
                                              active: item.row
                                          })
                                      }}/>
                        }
                        <div className={"row"}>
                            {
                                this.props.org.is_admin && this.state.selected.length > 0 &&
                                <Button variant="contained"
                                        className={clsx(classes.button, classes.marginTop, classes.marginBottom)}
                                        onClick={() => {
                                            this.deleteTeams(this.state.selected);
                                        }}>Delete</Button>
                            }
                        </div>
                    </Grid>
                }
                {
                    this.props.org.is_admin &&
                    <Grid item xs={12} style={{height: "2px", marginTop: "16px"}}>
                        <Divider flexItem orientation="horizontal" style={{height: "2px"}}/>
                    </Grid>
                }
                {
                    this.props.org.is_admin &&
                    <Grid item xs={12}>
                        <Typography variant="h6" className={classes.bold}>
                            Add Team
                        </Typography>
                    </Grid>
                }
                {
                    this.props.org.is_admin &&
                    <Grid item xs={12}>
                    <div className={classes.rowFullTop}>
                        <TextField onChange={(e) =>
                            this.setState({createTeamName: e.target.value})}
                                   className={clsx(classes.marginRight, classes.textField)}
                                   required
                                   id="outlined-required"
                                   label="Team Name"
                                   value={this.state.createTeamName}
                                   variant="outlined"
                                   error={this.state.createTeamErrorMessage.length > 0}
                                   helperText={this.state.createTeamErrorMessage}
                        />
                        <Autocomplete
                            className={classes.autoCompleteCustom}
                            multiple
                            sx={{ width: 400}}
                            value={this.state.createTeamSelectedManagers}
                            options={this.state.licenseEmails}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option}
                            onChange={(event, newValue) => {
                                this.setState({
                                    createTeamSelectedManagers: newValue
                                });
                            }}
                            renderOption={(props, option, { selected }) => {
                                const { key, ...optionProps } = props;
                                return (
                                    <li key={key} {...optionProps} className={classes.autoCompleteOption}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {option}
                                    </li>
                                );
                            }}
                            filterOption={(option, query) => {
                                return option.toLowerCase().includes(query.toLowerCase());
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                           className={clsx(classes.textField)}
                                           label={"Manager(s)"}
                                           placeholder=""
                                           variant="outlined"
                                />
                            )}
                            renderTags={(value, getTagProps) => {
                                if (value.length === 0) {
                                    return ""
                                }
                                return value.map((option, index) => option).join(', ')
                            }}
                        />
                        <Button variant="contained" className={clsx(classes.button, classes.right)}
                                disabled={this.state.loading}
                                onClick={e => {
                                    if (this.state.createTeamName.length === 0) {
                                        this.setState({
                                            createTeamErrorMessage: "Team name is required"
                                        });
                                        return;
                                    }
                                    this.setState({
                                        loading: true,
                                    }, () => {
                                        this.createTeam(this.state.createTeamName, this.state.createTeamSelectedManagers, [])
                                    })
                                }}>
                            Add
                        </Button>
                    </div>
                </Grid>
                }
            </Grid>
        </Container>;
    }
}

export default withStyles(useStyles)(CCTeams)