// === App Preferences ===
const kPrefRemoteContent = "pref_remote_content"
const kPrefNotificationsType = "pref_notifications_type"
const kPrefSaveKeyPassword = "pref_save_key_password"
const kPrefCloudSync = "pref_cloud_sync"
const kPrefEncryptionMode = "pref_encryption_mode"
const kPrefPGP = "pref_pgp"
const kPrefPGPDisallowExpiredKeys = "pref_pgp_disallow_expired_keys"
const kPrefSecureSend = "pref_secure_send"
const kPrefPreferredMode = "pref_encryption_preferred_mode"
const kPrefAnalytics = "pref_analytics"
const kPrefReadReceipts = "pref_read_receipts"
const kPrefComposeSuggestions = "pref_compose_suggestions"
const kPrefCrossDeviceSync = "pref_cross_device_sync"
const kPrefCopilotCompose = "pref_copilot_compose"
const kPrefCopilotChatbot = "pref_copilot_chatbot"
const kPrefCopilotSummary = "pref_copilot_summary"
const kPrefCopilotInboxFilters = "pref_copilot_inbox_filters"

function get_default_prefs() {
    return [
        {
            id: kPrefRemoteContent,
            value: false,
            text: "Remote Content",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefAnalytics,
            value: true,
            text: "Analytics",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefReadReceipts,
            value: true,
            text: "Read Receipts",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefNotificationsType,
            value: 0,
            values: ["Push", "Fetch"],
            text: "Notifications Type",
            type: "Multi",
            lock: false,
        },
        {
            id: kPrefSaveKeyPassword,
            value: 0,
            values: ["Never", "Remember for 15 minutes", "Save in Keychain"],
            text: "Save Key Password",
            type: "Multi",
            lock: false,
        },
        // {
        //     id: kPrefCloudSync,
        //     value: false,
        //     text: "Cloud Sync",
        //     type: "Toggle",
        //     lock: false,
        // },
        // {
        //     id: kPrefEncryptionMode,
        //     value: 1,
        //     values: ["Automatic", "Manual", "Off"],
        //     text: "PGP",
        //     type: "Multi",
        //     lock: false,
        // },
        {
            id: kPrefPreferredMode,
            value: 2,
            values: ["PGP", "SecureSend", "Last Used"],
            text: "Preferred Encryption Mode",
            type: "Multi",
            lock: false,
        },
        {
            id: kPrefPGP,
            value: true,
            text: "PGP",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefPGPDisallowExpiredKeys,
            value: false,
            text: "PGP - Disallow Expired Keys",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefSecureSend,
            value: true,
            text: "SecureSend",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefComposeSuggestions,
            value: true,
            text: "Compose Suggestions",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefCrossDeviceSync,
            value: false,
            text: "Cross Device Sync",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefCopilotChatbot,
            value: true,
            text: "Copilot - Chatbot",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefCopilotCompose,
            value: true,
            text: "Copilot - Compose",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefCopilotSummary,
            value: true,
            text: "Copilot - Summary",
            type: "Toggle",
            lock: false,
        },
        {
            id: kPrefCopilotInboxFilters,
            value: true,
            text: "Copilot - Inbox Filters",
            type: "Toggle",
            lock: false,
        },
    ]
}

function get_default_prefs_state() {
    let prefsMap = {}
    get_default_prefs().forEach(pref => {
        prefsMap[pref.id] = {key: pref.id, value: pref.value, lock: pref.lock}
    })
    return prefsMap;
}

// === Console Preferences ===

const kConsolePrefEncryption = "console_pref_encryption"
const kConsolePrefConfiguration = "console_pref_configuration"
const kConsolePrefTeamsAndUsers = "console_pref_teams_and_users"
const kConsolePrefBilling = "console_pref_billing"

function get_default_console_prefs(userRole = kRoleOwner) {
    const isAdmin = userRole === kRoleAdmin || userRole === kRoleOwner
    return [
        {
            id: kConsolePrefEncryption,
            value: isAdmin,
            text: "Encryption",
            editable: true,
        },
        {
            id: kConsolePrefConfiguration,
            value: isAdmin,
            text: "Configuration",
            editable: true,
        },
        {
            id: kConsolePrefTeamsAndUsers,
            value: true,
            text: "Teams & Users",
            editable: true,
        },
        {
            id: kConsolePrefBilling,
            value: isAdmin,
            text: "Billing",
            editable: true,
        },
    ]
}


const kRoleOwner = "owner"
const kRoleAdmin = "admin"
const kRoleManager = "manager"
const kRoleMember = "member"

function get_all_roles() {
    let ret = {}
    ret[kRoleMember] = {
        'priority': 1,
        'allow_teams_selection': true,
        'allow_delete': true
    }
    ret[kRoleManager] = {
        'priority': 2,
        'allow_teams_selection': true,
        'allow_delete': true
    }
    ret[kRoleAdmin] = {
        'priority': 3,
        'allow_teams_selection': false,
        'allow_delete': true
    }
    ret[kRoleOwner] = {
        'priority': 0,
        'allow_teams_selection': false,
        'allow_delete': false
    }
    return ret;
}

export  {
    get_default_prefs, get_default_prefs_state,
    get_default_console_prefs,
    kPrefComposeSuggestions, kPrefNotificationsType, kPrefPreferredMode, kPrefEncryptionMode, kPrefPGP, kPrefSecureSend,
    kPrefReadReceipts, kPrefRemoteContent, kPrefSaveKeyPassword, kPrefAnalytics, kPrefCloudSync, kPrefCrossDeviceSync,
    kPrefPGPDisallowExpiredKeys, kPrefCopilotChatbot, kPrefCopilotCompose, kPrefCopilotSummary, kPrefCopilotInboxFilters,
    kConsolePrefEncryption, kConsolePrefConfiguration, kConsolePrefTeamsAndUsers, kConsolePrefBilling,
    kRoleOwner, kRoleAdmin, kRoleManager, kRoleMember, get_all_roles
}