import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Fab, Grid,
    ListItem,
    Menu,
    MenuItem,
    Paper,
    Typography
} from "@material-ui/core";
import CCUser from "./user";
import {ChevronRight} from "@material-ui/icons";
import clsx from "clsx";
import {createLicenses, deleteLicenses, get_teams, inviteLicense, requestLicenses, saveLicense} from "./api";
import {DataGrid, GridCellParams} from "@material-ui/data-grid";
import AddIcon from "@material-ui/icons/Add";
import CCUserCsv from "./import";
import {get_all_roles, kRoleAdmin, kRoleOwner} from "./data";

class CCUsers extends Component {
    constructor(props) {
        super(props);
        this.activeView = {
            kUsersView: 0,
            kAddNewUserView: 1,
            kEditUserView: 2,
            kImportUserView: 3,
        }
        this.state = {
            loading: true,
            fetching: false,
            users: [],
            selected: [],
            menuAnchor: null,
            showSeatsAlert: false,
            activeView: this.activeView.kUsersView,
        }
        this.teamsMap = {}
        this.orgOwner = ""
        this.allLicenseEmails = new Set()

        this.saveUser = this.saveUser.bind(this)
        this.saveUsers = this.saveUsers.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
        this.deleteUsers = this.deleteUsers.bind(this)
        this.inviteUser = this.inviteUser.bind(this)
        this.refresh = this.refresh.bind(this)
        this.fetchAllTeams = this.fetchAllTeams.bind(this)
        this.setActiveView = this.setActiveView.bind(this)
        this.hideSeatsAlert = this.hideSeatsAlert.bind(this)
        this.back = this.back.bind(this)
    }

    back() {
        this.setState({
            activeView: this.activeView.kUsersView,
            active: null,
            selected: []
        })
    }

    hideSeatsAlert() {
        this.setState({
            showSeatsAlert: false
        })
    }

    // Users - CRUD
    saveUser(email, license, devices, roles) {
        this.back()
        let user = {}
        user['email'] = email;
        if (license) {
            user['license'] = license;
        }
        user['devices'] = devices;
        user['roles'] = roles;

        this.setState({
            loading: true
        }, () => {
            saveLicense(this.props.org.org_uid, this.props.org.org_auth_key, user, json => {
                this.refresh();
            });
        })
    }

    saveUsers(emails) {
        this.back()
        this.setState({
            loading: true
        }, () => {
            createLicenses(this.props.org.org_uid, this.props.org.org_auth_key, emails, json => {
                this.refresh()
            })
        })
    }

    deleteUser(license) {
        this.back()
        this.setState({
            loading: true
        }, () => {
            deleteLicenses(this.props.org.org_uid, this.props.org.org_auth_key, [license], json => {
                this.refresh()
            })
        })
    }

    deleteUsers(licenses) {
        this.back()
        this.setState({
            loading: true
        }, () => {
            deleteLicenses(this.props.org.org_uid, this.props.org.org_auth_key, licenses, json => {
                this.refresh()
            })
        })
    }

    inviteUser(email, license) {
        this.back()
        this.setState({
            loading: true
        }, () => {
            inviteLicense(this.props.org.org_uid, this.props.org.org_auth_key, email, license, callback => {
                this.refresh()
            })
        })
    }

    // State
    refresh() {
        this.fetchAllTeams(() => {
            requestLicenses(this.props.org.org_uid, this.props.org.org_auth_key,
                json => {
                    this.orgOwner = json.hasOwnProperty('account_owner') ? json.account_owner : ""
                    this.allLicenseEmails.clear();
                    if (json.licenses) {
                        json.licenses.forEach(license => {
                            license["id"] = license["license"]
                            license.is_owner = license.email === this.orgOwner
                            this.allLicenseEmails.add(license.email)
                            let roleToTeamName = {}  // Data grid display data dictionary
                            if (license.is_owner) {
                                roleToTeamName[kRoleOwner] = ["All"]
                            } else {
                                for (const [role, teamIds] of Object.entries(license.roles)) {
                                    if (role === kRoleAdmin || role === kRoleOwner) {
                                        roleToTeamName = {}
                                        roleToTeamName[role] = ["All"]
                                        break
                                    }
                                    if (!roleToTeamName.hasOwnProperty(role)) {
                                        roleToTeamName[role] = []
                                    }
                                    teamIds.forEach(teamId => {
                                        if (teamId in this.teamsMap) {
                                            roleToTeamName[role].push(this.teamsMap[teamId].name)
                                        }
                                    })
                                }
                            }
                            const allRoles = get_all_roles()
                            license.display_roles = Object.keys(roleToTeamName).sort((role1, role2) => {
                                return allRoles[role1].priority - allRoles[role2].priority;
                            })
                            if (license.status) {
                                let display_role_teams = []
                                license.display_roles.forEach(role => {
                                    display_role_teams.push(roleToTeamName[role])
                                })
                                license.display_roles_teams = display_role_teams
                            } else {
                                license.display_roles_teams = []
                            }
                        })
                    }
                    this.setState({
                        loading: false,
                        selected: [],
                        seats_total: json.seats_total,
                        seats_used: json.seats_used,
                        seats_needed: json.seats_needed,
                        remaining: json.seats_remaining,
                        hasDirectories: json['has_directories'],
                        users: json.licenses ? json.licenses : []
                    });
                });
        })
    }

    fetchAllTeams(callback) {
        get_teams(this.props.org.org_uid, this.props.org.org_auth_key, this.props.org.user_email, json => {
            let teams = json && json.hasOwnProperty('teams') ? json['teams'] : []
            let teamsMap = {}
            teams.forEach(team => {
                teamsMap[team.id] = team
            })
            this.teamsMap = teamsMap
            this.setState({
                teams: teams
            }, () => {
                callback()
            })
        })
    }

    setActiveView(view: number, activeUser = null) {
        if (view !== this.activeView.kUsersView) {
            this.setState({
                loading: true,
                menuAnchor: null
            }, () => {
                this.fetchAllTeams(() => {
                    this.setState({
                        loading: false,
                        activeView: view,
                        active: activeUser
                    });
                })
            })
            return;
        }
        this.setState({
            activeView: view,
            loading: false,
            menuAnchor: null
        })
    }

    componentDidMount() {
        this.refresh()
        this.callback = (json) => {
            if (json.hasOwnProperty('op') && json.hasOwnProperty('type')) {
                const op = json.op
                const type = json.type
                if (op === 'REFRESH' && type === 'users') {
                    this.refresh();
                }
            }
        }
        this.props.ws.socket.addCallback(this.callback);
    }

    componentWillUnmount() {
        this.props.ws.socket.removeCallback(this.callback);
    }

    getListItem(user) {
        const {classes} = this.props;
        return <ListItem key={user.license}>
            <div className={"row"} style={{width: "100%"}}>
                <Typography variant="body2"><b>{user.email}</b></Typography>
                <Button className={classes.right} onClick={() => {
                    this.setState({active: user})
                }}><ChevronRight/></Button>
            </div>
        </ListItem>
    }

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        if (this.state.activeView === this.activeView.kAddNewUserView) {
            return <CCUser org={this.props.org}
                           all_emails={this.allLicenseEmails}
                           owner={this.orgOwner}
                           remaining={this.state.remaining}
                           teams={this.state.teams}
                           back={this.back}
                           save={this.saveUser}
                           delete={this.deleteUser}
                           invite={this.inviteUser}/>
        }

        if (this.state.activeView === this.activeView.kEditUserView) {
            return <CCUser user={this.state.active}
                           all_emails={this.allLicenseEmails}
                           owner={this.orgOwner}
                           org={this.props.org}
                           teams={this.state.teams}
                           remaining={this.state.remaining}
                           back={this.back}
                           save={this.saveUser}
                           delete={this.deleteUser}
                           invite={this.inviteUser}/>
        }

        if (this.state.activeView === this.activeView.kImportUserView) {
            return <CCUserCsv remaining={this.state.remaining}
                              back={this.back}
                              save={this.saveUsers}
                              delete={this.deleteUser}/>
        }

        const makeFirstLetterCapital = (str) => {
            return str.charAt(0).toUpperCase() + str.slice(1);
        }

        const columns = [
            {field: 'email', headerName: 'Email', flex: 1,},
            {field: 'license', headerName: 'License Key', flex: 1.5,},
            {
                field: 'display_roles', headerName: 'Roles', flex: 1,
                renderCell: (params: GridCellParams) => {
                    const roles = params.row.display_roles;
                    if (roles.length === 0) {
                        const role = params.row.status ? "Member" : "-";
                        return (
                            <Box>
                                <Typography variant="body2">{role}</Typography>
                            </Box>
                        );
                    } else {
                        return <Box>
                            {roles.map((role, index) => (
                                <Typography key={index} variant="body2">
                                    {makeFirstLetterCapital(role)}
                                </Typography>
                            ))}
                        </Box>
                    }
                }
            },
            {
                field: 'display_roles_teams',
                headerName: 'Teams',
                flex: 1,
                renderCell: (params: GridCellParams) => {
                    const display_roles_teams = params.row.display_roles_teams;
                    if (display_roles_teams.length === 0) {
                        return (
                            <Box>
                                <Typography variant="body2">-</Typography>
                            </Box>
                        );
                    } else {
                        return (
                            <Box>
                                {
                                    display_roles_teams.length > 0 ?
                                    display_roles_teams.map((team, index) => (
                                        <Typography key={index} variant="body2" className={classes.usersTeamDetails}>
                                            {team.join(', ')}
                                        </Typography>
                                    )) : <Typography variant="body2">-</Typography>
                                }
                            </Box>
                        );
                    }
                }
            },
            {
                field: 'status', headerName: 'Status', flex: 1, valueGetter: params => {
                    return params.value ? "Assigned" : "Not Enough Seats";
                }
            }
        ]

        return <Container className={classes.root}>
            <Grid container spacing={3}>
                {
                     <Grid item xs={12}>
                        {
                            this.state.remaining > 0 &&
                            <Typography variant={"body1"}>
                                You have {this.state.seats_total} total seats, of which {this.state.seats_used} have been assigned to users. You have {this.state.remaining} seats available.
                            </Typography>
                        }
                        {
                            this.state.remaining === 0 &&
                            <Typography variant={"body1"}>
                                You have {this.state.seats_total} total seats, of which {this.state.seats_used} have been assigned to users. You need to purchase {this.state.seats_needed} more seats.
                            </Typography>
                        }
                    </Grid>
                }
                <Grid item xs={12}>
                    {
                        this.state.users.length > 0 &&
                        <Paper className={clsx(classes.paper, classes.paperLight)}>

                                <DataGrid autoHeight columns={columns}
                                          className={classes.paperGrid}
                                          rows={this.state.users}
                                          isRowSelectable={(params) => params.row.is_owner !== true}
                                          pageSize={20}
                                          rowHeight={80}
                                          selectionModel={this.state.selected}
                                          disableColumnSelector={true}
                                          checkboxSelection={true}
                                          onSelectionModelChange={change => this.setState({selected: change})}
                                          onRowClick={item => {
                                              this.setActiveView(this.activeView.kEditUserView, item.row)
                                          }}/>
                        </Paper>
                    }
                    <div className={"row"}>
                        {
                            !this.state.hasDirectories &&
                            <Fab color="primary" className={classes.floating} aria-label="add"
                                 onClick={e => {
                                     if (this.state.remaining === 0) {
                                         this.setState({
                                             showSeatsAlert: true
                                         })
                                     } else {
                                         this.setState({
                                             menuAnchor: e.currentTarget
                                         })
                                     }
                                 }}>
                                <AddIcon/>
                            </Fab>
                        }
                        <Dialog
                            open={this.state.showSeatsAlert}
                            onClose={this.hideSeatsAlert}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title" className={classes.alertDialogTitle}>Would you like to
                                add more seats?</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    All available seats have already been assigned. To add a new user, please add seats
                                    via Billing.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={this.hideSeatsAlert} color="primary">
                                    Cancel
                                </Button>
                                <Button onClick={this.hideSeatsAlert} color="primary" autoFocus>
                                    Add Seats
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <Menu
                            id="simple-menu"
                            anchorEl={this.state.menuAnchor}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            keepMounted
                            open={Boolean(this.state.menuAnchor)}
                            onClose={e => {
                                this.setState({menuAnchor: null})
                            }}>
                            <MenuItem onClick={e => {
                                this.setActiveView(this.activeView.kAddNewUserView);
                            }}>Add Manually</MenuItem>
                            <MenuItem onClick={e => {
                                this.setActiveView(this.activeView.kImportUserView);
                            }}>Import via CSV</MenuItem>
                        </Menu>
                        {
                            this.state.selected.length > 0 &&
                            <Button variant="contained"
                                    style={{marginLeft: "24px"}}
                                    className={clsx(classes.button, classes.marginTop, classes.marginBottom)}
                                    onClick={() => {
                                        this.deleteUsers(this.state.selected);
                                    }}>Delete</Button>
                        }
                    </div>
                </Grid>
            </Grid>
        </Container>;
    }
}

export default withStyles(useStyles)(CCUsers)