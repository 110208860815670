import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {Button, ButtonGroup, CircularProgress, Container, Grid, Paper, TextField, Typography} from "@material-ui/core";
import "../../styles/common.css"
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";
import {ChevronLeft} from "@material-ui/icons";
import {useStyles} from "./styles";
import {get_promo_code, validate_promo_code} from "./api";
import {StripePromoCode} from "./stripe/objects";
import {renderFakeSubscription} from "./stripe/subscription";

class CCCheckout extends Component {
    constructor(props) {
        super(props);
        let priceIds = Object.keys(this.props.prices)
        let show_biyearly = this.props.org.show_biyearly;

        let monthly = null;
        let yearly = null;
        let biyearly = null;
        for (let i = 0; i < priceIds.length; i++) {
            let price = this.props.prices[priceIds[i]]
            if (price.isAnnual(2)) {
                biyearly = price;
            } else if (price.isAnnual(1)) {
                yearly = price;
            } else {
                monthly = price;
            }
        }

        let tabs = show_biyearly ? ["Bi-Yearly"] : ["Monthly", "Yearly"];

        if (this.props.subscription !== undefined && this.props.subscription != null) {
            let activePrice = this.props.subscription.getPrice();
            let activeProduct = this.props.products[activePrice.getProduct()]
            let activeTab = monthly.getId() === activePrice.getId() ? "Monthly" : yearly.getId() === activePrice.getId() ? "Yearly" : "Bi-Yearly"
            console.log(activePrice, activeProduct)
            this.state = {
                subscription: this.props.subscription,
                seats: this.props.subscription.getQuantity(),
                priceIds: priceIds,
                activePrice: activePrice,
                activeProduct: activeProduct,
                promoCode: null,
                appliedCode: null,
                monthlyPrice: monthly,
                yearlyPrice: yearly,
                biyearlyPrice: biyearly,
                show_biyearly: show_biyearly,
                tab: activeTab,
                tabs: tabs
            }
        } else {
            let activePrice = null;
            for (let i = 0; i < priceIds.length; i++) {
                let price = this.props.prices[priceIds[i]]
                if (show_biyearly) {
                    if (price.isAnnual(2)) {
                        activePrice = price;
                        break;
                    }
                } else {
                    if (!price.isAnnual()) {
                        activePrice = price;
                    }
                }
            }
            let activeProduct = this.props.products[activePrice.getProduct()]
            let activeTab = monthly.getId() === activePrice.getId() ? "Monthly" : yearly.getId() === activePrice.getId() ? "Yearly" : "Bi-Yearly"
            this.state = {
                seats: 1,
                subscription: null,
                priceIds: priceIds,
                activePrice: activePrice,
                activeProduct: activeProduct,
                promoCode: null,
                appliedCode: null,
                monthlyPrice: monthly,
                yearlyPrice: yearly,
                biyearlyPrice: biyearly,
                show_biyearly: show_biyearly,
                tab: activeTab,
                tabs: tabs
            }
        }
        this.setSeats = this.setSeats.bind(this);
        this.purchase = this.purchase.bind(this);
        this.updatePrices = this.updatePrices.bind(this);
        this.setPromoCode = this.setPromoCode.bind(this);
    }

    setTab(tab) {
        if (tab === this.state.tab) {
            return;
        }
        let price = null;
        let product = null;
        if (tab === 'Monthly') {
            price = this.state.monthlyPrice;
            product = this.props.products[price.getProduct()]
        } else if (tab === 'Yearly') {
            price = this.state.yearlyPrice;
            product = this.props.products[price.getProduct()]
        } else if (tab === 'Bi-Yearly') {
            price = this.state.biyearlyPrice;
            product = this.props.products[price.getProduct()]
        }
        if (price !== null && product !== null) {
            this.setState({
                activePrice: price,
                activeProduct: product,
                tab: tab,
            }, () => {
                this.updatePrices();
            })
        }
    }

    componentDidMount() {
        if (this.hasSubscription() && this.props.subscription.getDiscount() !== null) {
            let discount = this.props.subscription.getDiscount();
            let code_id = discount.getPromoCodeId();
            if (code_id !== null) {
                this.fetchPromoCode(code_id);
                return;
            }
        }
        this.updatePrices();
    }

    purchase() {
        if (this.hasSubscription()) {
            this.props.update(this.state.subscription.getId(), this.state.seats, this.state.activePrice.getId(), this.state.appliedCode);
        } else {
            this.props.create(this.state.activePrice.getId(), this.state.seats, this.state.appliedCode);
        }
    }

    updatePrices() {
        let unit_price = this.state.activePrice.getPrice()
        if (this.state.appliedCode !== null) {
            let discount = this.state.appliedCode.getPercentOff()
            if (discount >= 0) {
                unit_price *= ((100.0 - discount) / 100.0)
            }
        }
        this.setState({
            net: "$" + (unit_price * this.state.seats).toFixed(2),
            tax: "$" + 0,
            gross: "$" + (unit_price * this.state.seats).toFixed(2),
        })
    }

    setSeats(e) {
        this.setState({
            seats: e.target.value
        }, () => {
            this.updatePrices();
        })
    }

    setPromoCode(e) {
        this.setState({
            promoCode: e.target.value,
            promoCodeInvalid: false,
        })
    }

    applyPromoCode() {
        let code = this.state.promoCode;
        this.setState({
            checkingCode: true,
        })
        validate_promo_code(code, ret => {
            if (ret['status'] !== 200) {
                this.setState({
                    promoCodeInvalid: true,
                    appliedCode: null,
                    checkingCode: false,
                }, () => {
                    this.updatePrices();
                })
            } else {
                this.setState({
                    promoCodeInvalid: false,
                    appliedCode: new StripePromoCode(ret['code']),
                    checkingCode: false,
                }, () => {
                    this.updatePrices();
                })
            }
        })
    }

    fetchPromoCode(code_id) {
        this.setState({
            loading: true,
        })
        get_promo_code(code_id, ret => {
            if (ret['status'] !== 200) {
                this.setState({
                    promoCodeInvalid: true,
                    appliedCode: null,
                    loading: false,
                }, () => {
                    this.updatePrices();
                })
            } else {
                this.setState({
                    promoCodeInvalid: false,
                    appliedCode: new StripePromoCode(ret['code']),
                    loading: false,
                }, () => {
                    this.updatePrices();
                })
            }
        })
    }

    clearPromoCode() {
        this.setState({
            promoCode: null,
            appliedCode: null,
            promoCodeInvalid: false,
        }, () => {
            this.updatePrices();
        })
    }

    divider() {
        return <Grid item xs={12} style={{height: "1px"}}>
            <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
        </Grid>
    }

    hasSubscription() {
        return this.props.subscription !== null && this.props.subscription !== undefined;
    }

    renderPromoCode(classes) {
        return [
            this.divider(),
            <Grid item xs={3}>

            </Grid>,
            <Grid item xs={5}>
                <div className={classes.column}>
                    {
                        <TextField onChange={this.setPromoCode}
                                   className={classes.textField}
                                   fullWidth
                                   id="outlined"
                                   disabled={this.state.appliedCode !== null}
                                   label={this.state.promoCodeInvalid ? "This promo code is not valid" : "Promo code"}
                                   error={this.state.promoCodeInvalid}
                                   value={this.state.appliedCode !== null ? this.state.appliedCode.getCode() : this.state.promoCode !== null ? this.state.promoCode : ""}
                                   margin="normal"
                                   variant="outlined"
                        />
                    }
                </div>
            </Grid>,
            <Grid item xs={4} className={clsx(classes.rowFull, classes.justifyCenter)}>
                {
                    this.state.checkingCode && <CircularProgress/>
                }
                {
                    !this.state.checkingCode &&
                    <Button className={classes.button} variant="contained"
                            style={{width: "100%"}}
                            disabled={this.state.purchasing || ((this.state.promoCode === null || this.state.promoCode.length === 0) && this.state.appliedCode === null)}
                            onClick={() => {
                                if (this.state.appliedCode !== null) {
                                    this.clearPromoCode()
                                } else {
                                    this.applyPromoCode()
                                }
                            }
                            }>
                        {this.state.appliedCode !== null ? "Clear" : "Apply"}
                    </Button>
                }
            </Grid>
        ]
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        // let isNew = this.props.subscription === null || this.props.subscription === undefined;
        let renderFake = this.props.isPilot;
        if (this.hasSubscription()) {
            renderFake = false;
        }

        return <Container className={classes.root}>
            <Grid container spacing={3}>
                {
                    renderFake &&
                    <Grid item xs={12}>
                        <Paper className={clsx(classes.paper, classes.paperLight)}>
                            {
                                renderFakeSubscription(classes, this.props.pilotExpiry)
                            }
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper className={clsx(classes.paper, classes.paperLight)}>
                        <Grid container spacing={3} justify="center">
                            {
                                this.hasSubscription() &&
                                <Grid item xs={12}>
                                    <ChevronLeft className={classes.buttonIcon} onClick={() => this.props.clear()}/>
                                </Grid>
                                // <Button onClick={() => this.props.clear()}></Button>
                            }
                            <Grid item md={7} sm={12} xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5"><b>
                                            {
                                                this.hasSubscription() ? "Manage Subscription" : "Purchase Subscription"
                                            }
                                        </b></Typography>
                                    </Grid>
                                    {
                                        this.hasSubscription() && <Grid item xs={12}>
                                            <Typography variant="subtitle2">
                                                Your current plan
                                            </Typography>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <Paper className={clsx(classes.paper, classes.paperLight)}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={3}>
                                                    <div className={classes.row} style={{height: "100%"}}>
                                                        <Typography
                                                            variant="subtitle2"><b>{this.state.activeProduct.getName()}</b></Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={5}>
                                                    <div className={classes.column}>
                                                        <TextField onChange={this.setSeats}
                                                                   className={classes.textField}
                                                                   required
                                                                   fullWidth
                                                                   id="outlined-required"
                                                                   label="Number of seats"
                                                                   value={this.state.seats}
                                                                   margin="normal"
                                                                   variant="outlined"
                                                        />
                                                        <Typography variant="subtitle2" style={{marginTop: "10px"}}>
                                                            ${this.state.activePrice.getPrice()} per
                                                            user{this.state.activePrice.isAnnual(2) ? "/bi-yearly" : this.state.activePrice.isAnnual() ? "/year" : "/month"}
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <div className={classes.row} style={{height: "100%"}}>
                                                        <Typography variant="h6" className={classes.right}>
                                                            <b>
                                                                {this.state.gross}{this.state.activePrice.isAnnual(2) ? "/bi-yearly" : this.state.activePrice.isAnnual() ? "/year" : "/month"}
                                                            </b>
                                                        </Typography>
                                                    </div>
                                                </Grid>
                                                {
                                                    this.renderPromoCode(classes)
                                                }
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item md={5} sm={12} xs={12}>
                                <Paper className={clsx(classes.paper, classes.paperLight)}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Typography variant="h5">Summary</Typography>
                                        </Grid>
                                        {
                                            <Grid item xs={12}>
                                                <div className={classes.row}>
                                                    <Typography variant="subtitle2">Billing Cycle</Typography>
                                                    <ButtonGroup className={classes.right} color="primary"
                                                                 aria-label="outlined primary button group">
                                                        {
                                                            this.state.tabs.map(tab => {
                                                                return <Button
                                                                    className={tab === this.state.tab && classes.button}
                                                                    onClick={(e) => this.setTab(tab)}>{tab}</Button>
                                                            })
                                                        }
                                                    </ButtonGroup>
                                                </div>
                                            </Grid>
                                        }
                                        {
                                            this.divider()
                                        }
                                        <Grid item xs={12}>
                                            <div className={classes.row}>
                                                <div className={classes.column}>
                                                    <Typography
                                                        variant="subtitle2"><b>{this.state.activeProduct.getName()}</b></Typography>
                                                    <Typography
                                                        variant="subtitle2">{this.state.seats} seats</Typography>
                                                </div>
                                                <Typography variant="subtitle2"
                                                            className={classes.right}>{this.state.gross}</Typography>
                                            </div>
                                        </Grid>
                                        {
                                            this.divider()
                                        }
                                        <Grid item xs={12}>
                                            <div className={classes.row}>
                                                <div className={classes.column}>
                                                    <Typography variant="h6"><b>Total</b></Typography>
                                                </div>
                                                <Typography variant="h6"
                                                            className={classes.right}><b>{this.state.gross}</b></Typography>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} style={{marginTop: "-20px"}}>
                                            <Typography variant="subtitle2">+
                                                tax {this.state.activePrice.isAnnual() ? "/ year" : "/ month"}</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                <Button className={classes.button} variant="contained"
                                                        style={{width: "100%"}}
                                                        disabled={this.state.purchasing}
                                                        onClick={this.purchase}>
                                                    {this.hasSubscription() ? "Update" : "Purchase"}
                                                </Button>
                                            }
                                        </Grid>
                                        {
                                            this.hasSubscription() &&
                                            this.divider()
                                        }
                                        {
                                            this.hasSubscription() &&
                                            <Grid item xs={12}>
                                                {
                                                    <Button className={classes.button} variant="contained"
                                                            style={{width: "100%"}}
                                                            disabled={this.state.purchasing}
                                                            onClick={this.props.manage}>
                                                        Manage
                                                    </Button>
                                                }
                                            </Grid>
                                        }
                                    </Grid>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>;
    }
}

export default withStyles(useStyles)(CCCheckout)