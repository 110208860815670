import React from "react";
import img from "../assets/main-layout-fs8.png";
import {CircularProgress} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import CCSignIn from "./pages/login/signin";
import CCSignInEmail from "./pages/login/signinemail";
import {loginStyles} from "./pages/styles";
import CCSignUpEmail from "./pages/login/signupemail";
import CCCheckEmail from "./pages/login/checkemail";
import anim from "../assets/animation.json";
import Lottie from 'react-lottie';

class CCLogin extends React.Component {
    constructor() {
        super();
        this.state = {
            loginState: 0 // 0: root, 1: sign in, 2: sign up, 3: check email
        }
        this.setLoginState = this.setLoginState.bind(this);
    }

    setLoginState(state) {
        this.setState({
            loginState: state
        });
    }

    login() {
        return <CCSignIn signInWithGoogle={this.props.signInWithGoogle}
                         showSignInWithEmail={() => {this.setLoginState(1)}}
                         showSignUpWithEmail={() => {this.setLoginState(2)}}/>
    }

    loginWithEmail() {
        return <CCSignInEmail signInWithGoogle={this.props.signInWithGoogle}
                              signInWithEmail={(email) => this.props.signInWithEmail(email, () => {
                                  this.setLoginState(3)
                              })}
                              showSignUpWithEmail={() => {this.setLoginState(2)}}/>
    }

    signUpWithEmail() {
        return <CCSignUpEmail signInWithGoogle={this.props.signInWithGoogle}
                              signUpWithEmail={(email) => this.props.signUpWithEmail(email, () => {
                                  this.setLoginState(3)
                              })}
                              showSignInWithEmail={() => {this.setLoginState(1)}}/>
    }

    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: anim,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        const {classes} = this.props;
        return <div className={classes.root}>
            <div className={"row"}>
                <div className={clsx(classes.column, classes.solid)}>
                    {
                        this.props.loading && <CircularProgress/>
                    }
                    {
                        !this.props.loading && this.state.loginState === 0 && this.login()
                    }
                    {
                        !this.props.loading && this.state.loginState === 1 && this.loginWithEmail()
                    }
                    {
                        !this.props.loading && this.state.loginState === 2 && this.signUpWithEmail()
                    }
                    {
                        !this.props.loading && this.state.loginState === 3 && <CCCheckEmail/>
                    }
                </div>
                <div className={clsx(classes.column, classes.end)}>
                    <Lottie options={defaultOptions}
                            height={500}
                            width={500}
                            isStopped={this.state.isStopped}
                            isPaused={this.state.isPaused}/>
                    <div className="animated">
                        <div className="wrapper">
                            <div className="one"></div>
                            <div className="two"></div>
                            <div className="three"></div>
                            <div className="four"></div>
                        </div>
                    </div>
                    {/*<img className={classes.image} src={img} alt=""/>*/}
                </div>
            </div>
        </div>
    }
}

export default withStyles(loginStyles)(CCLogin);