import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "./styles";
import {Button, CircularProgress, Container, Grid, Paper, TextField} from "@material-ui/core";
import {add_org_user, del_org_users, get_org_users} from "./api";
import {DataGrid} from "@material-ui/data-grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";

class CCAdmins extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            adminEmail: "",
            admins: [],
            selected: [],
        };
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        get_org_users(this.props.org.org_uid, this.props.org.org_auth_key, json => {
            if (json.hasOwnProperty("admins")) {
                this.setState({
                    admins: json.admins,
                    loading: false,
                    adminEmail: "",
                    selected: [],
                })
            } else {
                this.setState({
                    loading: false,
                    adminEmail: "",
                    selected: [],
                })
            }
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const columns = [
            {field: 'email', headerName: 'Email', flex: 1,},
            {field: 'role', headerName: 'Role', flex: 1,},
        ];

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <DataGrid autoHeight
                                  className={classes.paperGrid}
                                  columns={columns}
                                  checkboxSelection={true}
                                  selectionModel={this.state.selected}
                                  onSelectionModelChange={change => this.setState({selected: change})}
                                  isRowSelectable={(params) => params.row.role !== 'Account Owner'}
                                  rows={this.state.admins}
                                  pageSize={10}
                                  disableSelectionOnClick
                                  loading={this.state.loading}
                                  disableColumnResize={true}
                                  disableColumnSelector={true}/>
                    </Grid>
                    {
                        this.state.selected.length > 0 &&
                        <Grid item xs={12}>
                            <Button variant="contained" disabled={this.state.loading} className={classes.button}
                                    onClick={e => {
                                        this.setState({
                                            loading: true,
                                        }, () => {
                                            del_org_users(this.props.org.org_uid, this.props.org.org_auth_key, this.state.selected, json => {
                                                this.refresh();
                                            })
                                        })
                                    }
                                    }>
                                Delete
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} style={{height: "1px"}}>
                        <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.column}>
                            <Typography variant="h6" style={{marginBottom: "24px"}}>
                                Add Administrator
                            </Typography>
                            <div className={classes.rowFull}>
                                <TextField onChange={(e) => {
                                    this.setState({adminEmail: e.target.value})
                                }}
                                           id="outlined-required"
                                           label="Admin Email Address"
                                           disabled={this.state.loading}
                                           value={this.state.adminEmail}
                                           variant="outlined"
                                           className={clsx(classes.marginRight, classes.textField)}
                                />
                                <Button variant="contained" className={clsx(classes.button, classes.right)}
                                        disabled={this.state.loading}
                                        onClick={e => {
                                            this.setState({
                                                loading: true,
                                            }, () => {
                                                add_org_user(this.props.org.org_uid, this.props.org.org_auth_key, this.state.adminEmail, json => {
                                                    this.refresh();
                                                })
                                            })
                                        }}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCAdmins)