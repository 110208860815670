import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "./styles";
import {Button, CircularProgress, Container, Fab, Grid, Paper, Tab, Tabs} from "@material-ui/core";
import {approveKeys, deleteKeys, deleteUnapprovedKeys, getApprovedKeys, getUnapprovedKeys, uploadKeyFiles} from "./api";
import {DataGrid} from "@material-ui/data-grid";
import {DropzoneDialog} from "material-ui-dropzone";
import clsx from "clsx";
import AddIcon from '@material-ui/icons/Add';

class CCKeys extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drop: false,
            display: "Approved Keys",

            approved: [],
            approvedTotalRows: 0,
            approvedTotalPages: 1,
            approvedPageNum: 1,
            approvedPageSize: 10,
            approvedSelected: [],
            approvedLoading: true,

            unapproved: [],
            unapprovedPageSize: 10,
            unapprovedLoading: true,
            unapprovedSelected: [],

            file: null,
            loading: false
        };
    }

    componentDidMount() {
        this.refresh()
    }

    refreshApproved() {
        this.setState({
            approvedLoading: true,
        }, () => {
            getApprovedKeys(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                this.setState({
                    approved: json.keys ? json.keys : [],
                    approvedLoading: false,
                })
            });
        })
    }

    refreshUnapproved() {
        this.setState({
            unapprovedLoading: true,
        }, () => {
            getUnapprovedKeys(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                this.setState({
                    unapproved: json.keys ? json.keys : [],
                    unapprovedLoading: false
                })
            })
        })
    }

    refreshActive() {
        if (this.state.display === 'Approved Keys') {
            this.refreshApproved();
        } else {
            this.refreshUnapproved();
        }
    }

    refresh() {
        this.refreshApproved()
        this.refreshUnapproved()
    }

    getColumns() {
        return [
            {
                field: 'id', headerName: 'Key ID', flex: 1,
                valueGetter: params => {
                    let id = params.value.toUpperCase().match(/.{1,4}/g);
                    return id.join(' ');
                }
            },
            {field: 'primary_email', headerName: 'Email', flex: 1,},
            {
                field: 'expires', headerName: 'Expires', flex: 1,
                valueGetter: params => {
                    let value = params.getValue(params.id, 'expiry')
                    if (!value || value === 0) {
                        return "Perpetual"
                    } else {
                        return new Date(value).toLocaleDateString();
                    }
                },
                sortComparator: (v1, v2, param1, param2) => {
                    let a1 = param1.api.getCellValue(param1.id, 'expiry')
                    let a2 = param2.api.getCellValue(param2.id, 'expiry')
                    if (!a1) { a1 = 3008443317000 }
                    if (!a2) { a2 = 3008443317000 }
                    return a1 - a2;
                }
            }
        ]
    }

    renderApproved() {
        const {classes} = this.props;
        return <DataGrid className={clsx(classes.noOverflow, classes.paperGrid)}
                         classes={{"DataGrid-window": classes.noOverflow}}
                         autoHeight
                         columns={this.getColumns()}
                         rows={this.state.approved}
                         pageSize={this.state.approvedPageSize}
                         loading={this.state.approvedLoading}
                         selectionModel={this.state.approvedSelected}
                         disableColumnSelector={true}
                         checkboxSelection={true}
                         onSelectionModelChange={change => this.setState({approvedSelected: change})}
                         onRowClick={item => {
                             this.setState({
                                 active: item.row
                             })
                         }}/>
    }

    renderApprovedWithPagination(classes) {
        return <DataGrid autoHeight
                         className={classes.paperGrid}
                         columns={this.getColumns()}
                         rows={this.state.approved}
                         pagination
                         pageSize={this.state.approvedPageSize}
                         rowCount={this.state.approvedTotalRows}
                         paginationMode="server"
                         onPageChange={params => {
                             this.setState({
                                 approvedSelected: [],
                                 approvedPageNum: (params.page + 1)
                             }, () => {
                                 this.refresh()
                             })
                         }}
                         loading={this.state.approvedLoading}
                         selectionModel={this.state.approvedSelected}
                         disableColumnSelector={true}
                         checkboxSelection={true}
                         onSelectionModelChange={change => this.setState({approvedSelected: change})}
                         onRowClick={item => {
                             this.setState({
                                 active: item.row
                             })
                         }}/>
    }

    renderApprovedActions() {
        const {classes} = this.props;
        return <div className={"row"}>
            {
                this.state.approvedSelected.length > 0 &&
                <Button variant="contained"
                        className={clsx(classes.button)}
                        onClick={() => {
                            let ids = this.state.approvedSelected;
                            this.setState({
                                approvedLoading: true,
                                approvedSelected: []
                            }, () => {
                                deleteKeys(this.props.org.org_uid, this.props.org.org_auth_key, ids, json => {
                                    this.refresh()
                                })
                            })
                        }}>Delete</Button>
            }
            <Fab color="primary" className={classes.floating} aria-label="add"
                 onClick={e => {
                     this.setState({
                         drop: true
                     })
                 }}>
                <AddIcon/>
            </Fab>
        </div>
    }

    renderUnapproved(classes) {
        return <DataGrid autoHeight
                         className={classes.paperGrid}
                         columns={this.getColumns()}
                         rows={this.state.unapproved}
                         rowCount={this.state.unapproved.length}
                         pageSize={this.state.unapprovedPageSize}
                         loading={this.state.unapprovedLoading}
                         selectionModel={this.state.unapprovedSelected}
                         disableColumnSelector={true}
                         checkboxSelection={true}
                         onSelectionModelChange={change => this.setState({unapprovedSelected: change})}
                         onRowClick={item => {
                             this.setState({
                                 active: item.row
                             })
                         }}/>
    }

    getUnapprovedSelectedIds() {
        return this.state.unapprovedSelected;
    }

    renderUnapprovedActions() {
        const {classes} = this.props;
        return <div className={"row"}>
            {
                this.state.unapprovedSelected.length > 0 &&
                <Button variant="contained"
                        className={clsx(classes.button)}
                        onClick={() => {
                            let ids = this.getUnapprovedSelectedIds()
                            this.setState({
                                unapprovedLoading: true,
                                unapprovedSelected: []
                            }, () => {
                                approveKeys(this.props.org.org_uid, this.props.org.org_auth_key, ids, json => {
                                    this.refresh()
                                })
                            })
                        }}>Approve</Button>
            }
            {
                this.state.unapprovedSelected.length > 0 &&
                <Button variant="contained"
                        style={{marginLeft: "24px"}}
                        className={clsx(classes.button)}
                        onClick={() => {
                            let ids = this.getUnapprovedSelectedIds()
                            this.setState({
                                unapprovedLoading: true,
                                unapprovedSelected: []
                            }, () => {
                                deleteUnapprovedKeys(this.props.org.org_uid, this.props.org.org_auth_key, ids, json => {
                                    this.refresh()
                                })
                            })
                        }}>Delete</Button>
            }
        </div>
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paperTabbed, classes.paperLight)}>
                <Tabs className={classes.paperTabs}
                      value={this.state.display}
                      onChange={(e, newValue) => {
                          this.setState({display: newValue},
                              () => {
                                  this.refreshActive();
                              })
                      }}
                      indicatorColor="primary"
                      textColor="primary"
                      centered
                >
                    <Tab label="Approved Keys" value="Approved Keys"/>
                    <Tab label="Unapproved Keys" value="Unapproved Keys"/>
                </Tabs>
                <DropzoneDialog
                    open={this.state.drop}
                    onSave={files => {
                        this.setState({drop: false, approvedLoading: true})
                        if (files.length > 0) {
                            uploadKeyFiles(this.props.org.org_uid, this.props.org.org_auth_key,
                                files, json => {
                                    this.refresh();
                                });
                        }
                    }}
                    showPreviews={true}
                    maxFileSize={50000000}
                    filesLimit={1000}
                    onClose={() => this.setState({drop: false})}
                />
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        {
                            this.state.display === 'Approved Keys' ?
                                this.renderApproved() :
                                this.renderUnapproved(classes)
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            this.state.display === 'Approved Keys' ?
                                this.renderApprovedActions() :
                                this.renderUnapprovedActions()
                        }
                    </Grid>
                </Grid>
                <div className={classes.column}>

                </div>

            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCKeys)