import {
    Avatar,
    Button,
    CircularProgress,
    Container, Dialog, DialogActions,
    DialogContent, DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    TextField
} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {deleteOrg, getOrgDetails, saveOrgDetails} from "./api";
import {useStyles} from "./styles";
import clsx from "clsx";

class Organization extends Component<{}> {
    constructor() {
        super();
        this.save = this.save.bind(this);
        this.change = this.change.bind(this);
        this.deleteOrganization = this.deleteOrganization.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.state = {
            loading: true,
            typed_name: "",
            saved_name: "",
            org_owner: "",
            image: null,
            show_delete_dialog: false,
        }
    }

    getDomainFromEmail() {
        return this.props.user.email.split("@")[1];
    }

    refresh() {
        getOrgDetails(this.props.org.org_uid, this.props.org.org_auth_key, json => {
            let image = null;
            if (!json.hasOwnProperty("organization_image") && this.getDomainFromEmail()) {
                image = "https://logo.clearbit.com/" + this.getDomainFromEmail();
            } else {
                image = json['organization_image'];
            }

            // let owner = json.hasOwnProperty("organization_owner") ? json['organization_owner'] : "";

            if (json && json.hasOwnProperty("organization_name")) {
                this.setState({
                    typed_name: json['organization_name'],
                    saved_name: json['organization_name'],
                    org_owner: json['organization_owner'],
                    image: image,
                    loading: false
                }, () => {

                })
            } else {
                this.setState({loading: false})
            }
        })
    }

    componentDidMount() {
        this.refresh()
    }

    change(e) {
        this.setState({
            typed_name: e.target.value
        })
    }

    save(e) {
        let name = this.state.typed_name;
        this.setState({loading: true},
            () => {
                saveOrgDetails(this.props.org.org_uid, this.props.org.org_auth_key, {
                    "organization_name": name,
                }, json => {
                    this.setState({
                        saved_name: name,
                        loading: false,
                    })
                })
            });
    }

    deleteOrganization(e) {
        this.setState({loading: true},
            () => {
                deleteOrg(this.props.org.org_uid, this.props.org.org_auth_key, this.props.user.email, json => {
                    this.setState({
                        loading: false,
                        show_delete_dialog: false,
                    }, () => {
                        this.props.clear();
                    })
                })
            });
    }

    hideDeleteDialog() {
        this.setState({show_delete_dialog: false})
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paperSmall, classes.paperLight)}>
                <Grid container spacing={3}>
                    <Grid item xs={12} className={"column"}>
                        <Avatar alt={this.state.saved_name}
                                src={this.state.image}
                                className={classes.largeImage}
                                margin="normal"/>
                    </Grid>
                    <Grid item xs={12} className={"column"}>
                        <TextField onChange={this.change}
                                   className={classes.textField}
                                   fullWidth
                                   required
                                   disabled={this.props.org.is_owner !== true}
                                   id="outlined-required"
                                   label="Organization Name"
                                   value={this.state.typed_name}
                                   variant="outlined"
                        />
                    </Grid>
                    <Grid item xs={12} className={"column"}>
                        <TextField
                            className={classes.textField}
                            disabled
                            fullWidth
                            id="outlined-disabled"
                            label="Organization Email"
                            defaultValue={this.state.org_owner}
                            variant="outlined"
                            style={{color: "#fff"}}
                        />
                    </Grid>
                    <Grid item xs={12} className={"column"}>
                        <TextField
                            className={classes.textField}
                            disabled
                            fullWidth
                            id="outlined-disabled"
                            label="Organization ID"
                            defaultValue={this.props.org.org_uid}
                            variant="outlined"
                        />
                    </Grid>
                    {
                        this.props.org.is_owner === true &&
                        <Grid item xs={12} className={"column"}>
                            <Button className={classes.button} style={{width: "100%"}} variant="contained" onClick={this.save}>Save</Button>
                        </Grid>
                    }
                    {
                        this.props.org.is_owner === true &&
                        <Grid item xs={12} className={"column"}>
                            <Button className={classes.button} style={{width: "100%", background: "red"}} variant="contained" onClick={() => {
                                this.setState({show_delete_dialog: true})
                            }}>Delete Organization</Button>
                        </Grid>
                    }
                </Grid>
            </Paper>
            <Dialog
                open={this.state.show_delete_dialog}
                onClose={this.hideDeleteDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" className={classes.alertDialogTitle}>Would you like to delete the {this.state.saved_name} organization?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        This cannot be undone.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.hideDeleteDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={this.deleteOrganization} color="primary" autoFocus>
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>;
    }
}

export default withStyles(useStyles)(Organization)