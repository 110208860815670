import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

function renderFakeSubscription(classes, pilotExpiry=null) {
    let expiryStr;
    let pilotStatus;
    if (pilotExpiry !== null && pilotExpiry !== undefined) {
        let date = new Date(pilotExpiry * 1000);
        expiryStr = date.toLocaleDateString(); // + " " + date.toLocaleTimeString()

        let now = new Date()
        let difference = date.getTime() - now.getTime();
        if (difference <= 0) {
            pilotStatus = 'Expired'
        } else {
            let days = Math.floor(difference / (1000 * 3600 * 24));
            if (days === 0) {
                pilotStatus = 'Expiring today'
            } else {
                pilotStatus = String(days) + ' days remaining'
            }
        }
    }
    return <Grid container spacing={3}>
        <Grid item xs={12}>
            <div className={classes.row}>
                <Typography variant={"h5"}><b>Pilot Status</b>{
                    pilotStatus && <b>: {pilotStatus}</b>
                }</Typography>
            </div>
        </Grid>
        {
            <Grid item xs={12}>
                <Typography variant={"subtitle2"}>
                    You are currently enrolled on the Enterprise trial. You and your team can use the Admin Console for free till <b>{expiryStr}</b>. Please ensure that you purchase the correct number of licenses for your team by <b>{expiryStr}</b> - after which your free trial will end and your team will no longer be able to access Canary unless you have a valid payment method in place. You can reach our support team here, in case you need help.
                </Typography>
                {/*<Typography variant={"subtitle2"}>You are currently enrolled on the enterprise pilot. You and your team can use the Admin Console for free. We will reach out to you when we’re ready to launch Canary for Enterprise. You can review&nbsp;*/}
                {/*    <a style={{textDecoration: "none"}} className={classes.url} href={"https://canarymail.io/pricing.html"} target={"_blank"} rel={"noreferrer"}>launch pricing here.</a></Typography>*/}
            </Grid>
        }
    </Grid>
}

export {
    renderFakeSubscription
}