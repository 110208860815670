import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import Organization from "./pages/organization";
import CCPreferences from "./pages/prefs";
import CCAppBar from "./appbar";
import {Button, Menu, MenuItem} from "@material-ui/core";
import {ArrowBack, ExpandMore} from "@material-ui/icons";
import CCBilling from "./pages/billing";
import CCEmailConfigurations from "./pages/configurations";
import CCUsers from "./pages/users";
import CCKeys from "./pages/keys";
import CCKeyServers from "./pages/keyservers";
import CCMasterKeys from "./pages/masterkeys";
import {withWebSocket} from "./pages/context";
import CCDeveloper from "./pages/developer";
import {
    add_org,
    clear_org_state, get_console_prefs,
    get_org_state,
    get_orgs,
    has_orgs,
    peek_org_state,
    put_orgs,
    quickstart_org, save_console_prefs
} from "./pages/api";
import {useStyles} from "./pages/styles";
import CCAdmins from "./pages/admins";
import CCOrganizations from "./pages/organizations";
import CCUnverified from "./pages/unverified";
import {getDrawer, getInfoText} from "./pages/info";
import CCPayments from "./pages/stripe/payments";
import CCDirectories from "./pages/workos/directory";
import CCDashboardLoader from "./pages/loaders/dashloader";
import CCQuickstart from "./pages/quickstart/quickstart";
import {console_log} from "./pages/log";
import CCConsolePreferences from "./pages/console_preferences";
import CCTeams from "./pages/teams";
import {get_default_console_prefs, kRoleAdmin, kRoleManager, kRoleOwner} from "./pages/data";
import CCLoader from "./pages/loaders/loader";

class CCDashboard extends React.Component<{}> {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            activeTab: "Overview",
            menuAnchor: null,
            showOrganizationMenu: true,
            showEncryptionMenu: true,
            showConfigurationMenu: true,
            showUserManagementMenu: true,
            showBillingMenu: true,
            orgs: [],
            org_uid: null,
            org_auth_key: null,
            isAdmin: false,
            userEmail: props.user.email,
            loading: true,
            lastTab: null,
            runWizard: false,
            createdOrg: null,
            consolePrefsState: {},
        }

        this.consolePrefs = get_default_console_prefs(kRoleManager);
        this.consolePrefsMap = {}
        let consolePrefsState = {}
        this.consolePrefs.forEach(pref => {
            this.consolePrefsMap[pref.id] = pref;
            consolePrefsState[pref.id] = {key: pref.id, value: pref.value}
        });
        this.state.consolePrefsState = consolePrefsState;

        this.setMobileOpen = this.setMobileOpen.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
        this.getOrganizations = this.getOrganizations.bind(this)
        this.addOrganization = this.addOrganization.bind(this)
        this.setOrganization = this.setOrganization.bind(this)
        this.clearOrganization = this.clearOrganization.bind(this)
        this.quickStartOrganization = this.quickStartOrganization.bind(this)
        this.fetchConsolePrefs = this.fetchConsolePrefs.bind(this)
        this.setConsolePref = this.setConsolePref.bind(this)
        this.toggleDrawerSection = this.toggleDrawerSection.bind(this)
    }

    componentDidMount() {
        let old_state = peek_org_state(window);
        let show_orgs = has_orgs(window);
        this.setState({
            lastOrg: old_state[0],
            lastTab: old_state[1],
            showOrgs: show_orgs,
        }, () => {
            this.refresh()
        })
    }

    refresh() {
        this.setState({
            loading: true
        }, () => {
            this.getOrganizations()
        })
    }

    getOrganizations() {
        get_orgs(this.props.user.email, this.props.authKey, json => {
            if (json.hasOwnProperty("orgs")) {
                let preferredOrg = null;
                let old = get_org_state(window)
                let oldOrg = old[0]
                let oldTab = old[1]

                if (oldOrg === null && this.state.createdOrg !== null) {
                    oldOrg = this.state.createdOrg;
                    oldTab = this.state.lastTab;
                }

                let sorted_orgs = []
                json.orgs.forEach(org => {
                    org['id'] = org['organization_uid']
                    org['owner'] = org['organization_owner']
                    if (oldOrg === org['id']) {
                        preferredOrg = org;
                    }
                    sorted_orgs.push(org);
                })
                sorted_orgs.sort((a, b) => {
                    let n1 = a.organization_name;
                    let n2 = b.organization_name;
                    return n1.localeCompare(n2);
                })

                put_orgs(window, json.orgs.length);

                this.setState({
                    showOrgs: json.orgs.length > 0,
                    orgs: sorted_orgs,
                    loading: false,
                    createdOrg: null,
                    lastTab: null,
                }, () => {
                    if (preferredOrg !== null && oldTab !== null) {
                        console_log('Restoring org', preferredOrg, oldTab)
                        this.setOrganization(preferredOrg.organization_uid, preferredOrg.auth_key,
                            preferredOrg.organization_name, preferredOrg.show_biyearly, oldTab)
                    }
                })
            } else {
                this.setState({
                    loading: false,
                })
            }
        })
    }

    addOrganization(name) {
        this.setState({
            loading: true
        }, () => {
            add_org(this.props.user.email, this.props.authKey, name, json => {
                this.getOrganizations()
            });
        })
    }

    quickStartOrganization(orgName, orgType, users) {
        this.setState({
            loading: true,
            runWizard: false,
            lastTab: "Overview",
        }, () => {
            quickstart_org(this.props.user.email, this.props.authKey, orgName, orgType, users, json => {
                console_log('Got response: ', json);
                if (json.hasOwnProperty("id")) {
                    this.setState({
                        createdOrg: json.id
                    }, () => {
                        this.getOrganizations();
                    })
                } else {
                    this.getOrganizations();
                }
            });
        })
    }

    setOrganization(org) {
        let isAdmin =  org.roles && (org.roles.hasOwnProperty(kRoleAdmin) || org.roles.hasOwnProperty(kRoleOwner));
        let isOwner = org.owner === this.props.user.email;
        this.setState({
            loading: true
        }, () => {
            this.setState({
                org_uid: org.id,
                org_auth_key: org.auth_key,
                org_name: org.name,
                show_biyearly: org.show_biyearly,
                isAdmin: isAdmin,
                isOwner: isOwner,
                activeTab: isAdmin ? "Overview" : "Manage Teams"
            }, () => {
                this.props.ws.socket.setCredentials(org.id, org.auth_key, this.props.user.email, org.auth_key);
                this.fetchConsolePrefs(() => {
                    this.setState({
                        loading: false
                    })
                });
            });
        });
    }

    clearOrganization() {
        clear_org_state(window);
        this.setState({
            org_uid: null,
            org_auth_key: null,
            org_name: null,
            show_biyearly: false,
            lastTab: null,
        }, () => {
            this.props.ws.socket.setCredentials(null, null, null, null);
            this.refresh()
        })
    }

    fetchConsolePrefs(callback) {
        if (this.state.isAdmin) {
            get_console_prefs(this.state.org_uid, this.state.org_auth_key, json => {
                let consolePrefsState = {}
                for (const [key, value] of Object.entries(json)) {
                    if (key in this.consolePrefsMap) {
                        consolePrefsState[key] = value
                    }
                }
                this.setState({
                    consolePrefsState: consolePrefsState
                }, () => {
                    callback()
                });
            })
        } else {
            callback()
        }
    }

    setConsolePref(key, value, callback) {
        let consolePrefsState = this.state.consolePrefsState
        consolePrefsState[key] = {key: key, value: value}
        this.setState({
            consolePrefsState: consolePrefsState
        }, () => {
            let update = {}
            update[key] = {key: key, value: value}
            save_console_prefs(this.state.org_uid,  this.state.org_auth_key, update, json => {
                callback()
            })
        });
    }

    setMobileOpen(open) {
        this.setState({
            mobileOpen: open
        })
    }

    setActiveTab(tab) {
        this.setState({
            activeTab: tab
        })
    }

    toggleDrawerSection(section) {
        let newState = {}
        newState[section] = !this.state[section]
        this.setState(newState)
    }

    render() {
        const {classes} = this.props;
        const {window} = this.props;

        const profile = <div className={"row"}>
            <div className={classes.profile}>
                <Button className={classes.profileButton} onClick={e => this.setState({menuAnchor: e.currentTarget})}>
                    {
                        this.props.user.photoURL &&
                        <img src={this.props.user.photoURL} className={classes.profileImage} alt=""/>
                    }
                    <Typography style={{marginRight: "12px"}} className={classes.hideSm}>
                        {this.props.user.displayName ? this.props.user.displayName : this.props.user.email}
                    </Typography>
                    <ExpandMore/>
                </Button>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.menuAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(this.state.menuAnchor)}
                    onClose={e => {
                        this.setState({menuAnchor: null})
                    }}>
                    <MenuItem onClick={e => {
                        this.props.signOut();
                        this.setState({menuAnchor: null});
                    }}>Sign Out</MenuItem>
                </Menu>
            </div>
        </div>

        if (!this.props.user.emailVerified) {
            return <CCUnverified sendEmail={this.props.sendEmailVerification} profile={profile}/>
        }

        if (this.state.loading && this.state.lastTab !== null) {
            // return <CCDashboardLoader lastTab={this.state.lastTab}/>
            return <CCLoader/>
        }

        if ((this.state.orgs.length === 0 || this.state.runWizard) && !this.state.showOrgs) {
            return <CCQuickstart profile={profile} orgs={this.state.orgs}
                                 user={this.props.user}
                                 loading={this.state.loading}
                                 quickStartOrganization={this.quickStartOrganization}/>
        }

        if (this.state.org_uid == null) {
            return <CCOrganizations profile={profile} orgs={this.state.orgs}
                                    loading={this.state.loading}
                                    addOrganization={this.addOrganization}
                                    setOrganization={this.setOrganization}/>
        }


        const user = this.props.user
        const auth_key = this.props.authKey
        const org = {
            "org_uid": this.state.org_uid,
            "org_auth_key": this.state.org_auth_key,
            "org_name": this.state.org_name,
            "show_biyearly": this.state.show_biyearly,
            "user_email": this.state.userEmail,
            "is_admin": this.state.isAdmin,
            "is_owner": this.state.isOwner,
        }

        const handleDrawerToggle = () => {
            this.setMobileOpen(!this.state.mobileOpen);
        };

        const drawer = getDrawer(classes, this.state, this.state.activeTab, this.setActiveTab, this.toggleDrawerSection, org);
        const container = window !== undefined ? () => window().document.body : undefined;
        const menuIcon = <div className={classes.row}>
            <IconButton onClick={this.clearOrganization}>
                <ArrowBack style={{color: "fff"}}/>
            </IconButton>
            <Hidden mdUp>
                <IconButton onClick={handleDrawerToggle}>
                    <MenuIcon style={{color: "fff"}}/>
                </IconButton>
            </Hidden>
        </div>

        const info = getInfoText(classes, this.state.activeTab)

        return (
            <div className={classes.dashboardRoot}>
                <CssBaseline/>
                <CCAppBar position="fixed" className={classes.appBar}
                          headerLeft={menuIcon} headerRight={profile}
                          orgName={this.state.org_name}/>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor="left"
                            open={this.state.mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>

                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    <Typography className={classes.activeTitle} variant="h1"><b>{this.state.activeTab}</b></Typography>
                    {
                        info && <Typography className={classes.activeSubtitle} variant="subtitle1">{info}</Typography>
                    }
                    {
                        this.state.activeTab === "Overview" &&
                        <Organization user={user} authKey={auth_key} org={org} clear={this.clearOrganization}/>
                    }
                    {
                        this.state.activeTab === "Admins" &&
                        <CCAdmins user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Console Preferences" &&
                        <CCConsolePreferences user={user} authKey={auth_key} org={org} consolePrefsMap={this.consolePrefsMap}
                                              consolePrefs={this.consolePrefs}
                                              consolePrefsState={this.state.consolePrefsState} setConsolePref={this.setConsolePref}/>
                    }
                    {
                        this.state.activeTab === "Key Management" &&
                        <CCKeys user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Key Servers" &&
                        <CCKeyServers user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Master Keys" &&
                        <CCMasterKeys user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Email Setup" &&
                        <CCEmailConfigurations user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "App Preferences" &&
                        <CCPreferences user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Manage Teams" &&
                        <CCTeams user={user} authKey={auth_key} org={org} ws={this.props.ws}/>
                    }
                    {
                        this.state.activeTab === "Active Users" &&
                        <CCUsers user={user} authKey={auth_key} org={org} ws={this.props.ws}/>
                    }
                    {
                        this.state.activeTab === "Subscription" &&
                        <CCBilling user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Payment" &&
                        <CCPayments user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "API Access" &&
                        <CCDeveloper user={user} authKey={auth_key} org={org}/>
                    }
                    {
                        this.state.activeTab === "Directory Sync" &&
                        <CCDirectories user={user} authKey={auth_key} org={org}/>
                    }
                </main>
            </div>
        );
    }
}

export default withWebSocket(withStyles(useStyles)(CCDashboard))
