class StripeObject {
    constructor(json) {
        this.json = json
    }

    getItem(key, defaultValue = null) {
        if (this.json !== null && this.json !== undefined && this.json.hasOwnProperty(key)) {
            return this.json[key]
        } else {
            return defaultValue
        }
    }

    getDate(key, defaultValue = null) {
        if (this.json !== null && this.json !== undefined && this.json.hasOwnProperty(key)) {
            return new Date(this.json[key] * 1000);
        } else {
            return defaultValue
        }
    }

    getId() {
        return this.getItem('id')
    }
}

class StripeSubscriptionItem extends StripeObject {
    getQuantity() {
        return this.getItem('quantity')
    }

    getPrice() {
        let price = this.getItem('price')
        if (price === null) {
            return null;
        }
        return new StripePrice(price)
    }
}

class StripeSubscription extends StripeObject {
    constructor(json) {
        super(json);
        this.discount = null;
    }

    getDiscount() {
        if (this.discount !== null) {
            return this.discount;
        }
        this.discount = new StripeDiscount(this.getItem('discount'))
        return this.discount;
    }

    getPercentOff() {
        let discount = this.getDiscount();
        if (discount !== null) {
            return discount.getPercentOff();
        } else {
            return 0;
        }
    }

    getStatus() {
        return this.getItem('status')
    }

    needsToUpdatePayment() {
        return this.getStatus() === 'incomplete' ||
            this.getStatus() === 'past_due' ||
            this.getStatus() === 'unpaid'
    }

    getTrialStart() {
        return this.getDate('trial_start')
    }

    getTrialEnd() {
        return this.getDate('trial_end')
    }

    getCreated() {
        return this.getDate('created')
    }

    getCurrentStart() {
        return this.getDate('current_period_start')
    }

    getCurrentEnd() {
        return this.getDate('current_period_end')
    }

    getItems() {
        let items = this.getItem('items')
        if (items === null) {
            return []
        }
        if (!items.hasOwnProperty('data')) {
            return []
        }
        items = items['data']
        let ret = []
        for (let i = 0; i < items.length; i++) {
            ret.push(new StripeSubscriptionItem(items[i]))
        }
        return ret
    }

    getPrice() {
        let items = this.getItems();
        if (items.length > 0) {
            let item = items[0];
            return item.getPrice();
        }
    }

    getQuantity() {
        let quantity = 0;
        let items = this.getItems();
        for (let i = 0; i < items.length; i++) {
            let subItem: StripeSubscriptionItem = items[i]
            quantity += subItem.getQuantity()
        }
        return quantity;
    }

    getEstimatedPrice() {
        let ret = 0
        let items = this.getItems();
        let percentOff = this.getPercentOff();
        for (let i = 0; i < items.length; i++) {
            let item: StripeSubscriptionItem = items[i]
            let amt = item.getPrice().getPrice();
            if (percentOff > 0) {
                amt *= ((100.0 - percentOff) / 100.0);
            }
            let quantity = item.getQuantity();
            ret += (amt * quantity)
        }
        return "$" + ret.toFixed(2)
    }

    willCancel() {
        return this.getStatus() === 'active' && this.getItem('cancel_at_period_end')
    }

    willCancelDate() {
        if (this.willCancel()) {
            return this.getDate('cancel_at')
        } else {
            return null
        }
    }

    cancelledDate() {
        return this.getDate('canceled_at')
    }
}

class StripePrice extends StripeObject {
    getProduct() {
        return this.getItem('product')
    }

    getPrice() {
        return this.getItem('unit_amount', 0.0) / 100.0
    }

    isAnnual(interval_count=1) {
        let recurring = this.getItem('recurring')
        if (recurring === null) {
            return false;
        }
        if (!recurring.hasOwnProperty('interval')) {
            return false;
        }
        if (recurring['interval'] !== 'year') {
            return false;
        }
        if (!recurring.hasOwnProperty('interval_count') || recurring['interval_count'] === 0) {
            return true;
        }
        return recurring['interval_count'] === interval_count;
    }
}

class StripeProduct extends StripeObject {
    constructor(json) {
        super(json);
        this.prices = []
    }

    getName() {
        return this.getItem('name')
    }

    getDescription() {
        return this.getItem('description')
    }

    getCreated() {
        return this.getDate('created')
    }

    getUpdated() {
        return this.getDate('updated')
    }

    addPrice(self, price: StripePrice) {
        if (this.getId() === price.getProduct()) {
            this.prices.append(price)
        }
    }
}

class StripeCoupon extends StripeObject {
    getPercentOff() {
        return this.getItem('percent_off', 0)
    }
}

class StripePromoCode extends StripeObject {
    constructor(json) {
        super(json);
        this.coupon = null
    }

    getCoupon() {
        if (this.coupon !== null) {
            return this.coupon;
        }
        this.coupon = new StripeCoupon(this.getItem('coupon'))
        return this.coupon
    }

    getCode() {
        return this.getItem('code')
    }

    getPercentOff() {
        return this.getCoupon().getPercentOff()
    }
}

class StripeDiscount extends StripeObject {
    constructor(json) {
        super(json);
        this.coupon = null;
    }

    getCoupon() {
        if (this.coupon !== null) {
            return this.coupon;
        }
        this.coupon = new StripeCoupon(this.getItem('coupon'))
        return this.coupon
    }

    getPromoCodeId() {
        return this.getItem('promotion_code')
    }

    getPercentOff() {
        return this.getCoupon().getPercentOff()
    }
}

export {
    StripeSubscription, StripeSubscriptionItem, StripeProduct, StripePrice, StripePromoCode, StripeCoupon
}