import logo from "../assets/canary-logo-fs8.png";
import "../styles/common.css"
import React from "react";
import {Toolbar, Typography} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";
import {EmojiObjects} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    url: {
        marginRight: "20px",
        color: "#006efe",
        textDecoration: "none",
        cursor: "pointer",
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        boxShadow: "none",
        borderBottom: "solid 1px " + theme.palette.divider,
    },
    appBarRight: {
        marginLeft: "auto",
        display: "flex",
        flexDirection: "row",
        alignItems: "center"
    },
    appBarTitle: {
        color: "inherit"
    },
    appBarItem: {
        width: "40px",
        height: "40px",
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
        }
    },
    buttonIcon: {
        width: "20px",
        height: "20px",
    },
    topButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        color: "#006efe",
        border: "1px solid #006efe",
        paddingLeft: "10px",
        paddingRight: "16px",
        marginRight: "20px",
        height: "40px",
        borderRadius: "10px",
        textTransform: "none",
        transition: ".2s background",
        cursor: "pointer",
        "&:hover": {
            background: "#006efe22"
        }
    },
    hideSm: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
}));

function CCAppToolbar(props) {
    const classes = useStyles();
    return <Toolbar>
        {
            props.headerLeft && props.headerLeft
        }
        {
            props.orgName && <Typography className={classes.appBarTitle} style={{marginLeft: "12px"}}>
                {
                    props.orgName
                }
            </Typography>
        }
        {
            !props.orgName && <img src={logo} className={"header-img"} alt=""/>
        }
        {
            !props.orgName && <Typography className={classes.appBarTitle}>Canary Mail</Typography>
        }
        <div className={clsx(classes.appBarRight)}>
            {
                <div className={classes.topButton} onClick={() => {
                    window.open('https://canarymail.io/help/quick-start/', '_blank');
                }
                }>
                    <EmojiObjects className={classes.buttonIcon}/>
                    <Typography style={{marginLeft: "8px"}}>Quickstart</Typography>
                </div>
            }
            {
                props.headerRight && props.headerRight
            }
        </div>
    </Toolbar>
}

export default function CCAppBar(props) {
    const classes = useStyles();

    return <AppBar position="fixed" className={classes.appBar}>
        <CCAppToolbar orgName={props.orgName} headerLeft={props.headerLeft} headerRight={props.headerRight}/>
    </AppBar>;
}