import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Button, CircularProgress, Container, Grid, Paper, Typography} from "@material-ui/core";
import CCAppBar from "../appbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import clsx from "clsx";

class CCUnverified extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <div className={classes.root}>
            <CssBaseline/>
            <CCAppBar position="fixed" className={classes.appBar} headerRight={this.props.profile}/>
            <div className={classes.column}>
                <Container className={classes.orgRoot}>
                    <div className={classes.toolbar}/>
                    <Paper className={clsx(classes.paper, classes.paperLight)} style={{"marginTop": "24px"}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Please open the verification link sent to your email.</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button className={classes.button} onClick={() => this.props.sendEmail(this.props)}>Resend Verification Email</Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Container>
            </div>
        </div>
    }
}

export default withStyles(useStyles)(CCUnverified)