import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {CircularProgress, Container, Grid} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import CCAppBar from "../../appbar";
import Typography from "@material-ui/core/Typography";
import {has_orgs} from "../api";

class CCLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_org_name: "",
            show_cards: true,
        };
    }

    componentDidMount() {

    }

    render() {
        const {classes} = this.props;

        let show_orgs = has_orgs(window);
        let rootClass = show_orgs ? classes.root : classes.root100;

        return <div className={rootClass}>
            <CssBaseline/>
            <CCAppBar position="fixed" className={classes.appBar} headerRight={this.props.profile}/>
            <div className={classes.column}>
                <div className={classes.toolbar}/>
                <Container className={classes.orgRoot}>
                    {
                        show_orgs && <Typography className={classes.activeTitle} variant="h1"><b>Organizations</b></Typography>
                    }
                    <div className={classes.paper}>
                        <Grid container spacing={6}>
                            <Grid item xs={12}>
                                <div className={classes.qsContainer}>
                                    <CircularProgress/>
                                </div>
                            </Grid>
                        </Grid>
                    </div>
                </Container>
            </div>
        </div>
    }
}

export default withStyles(useStyles)(CCLoader)