import React, {createContext} from 'react'
import {console_log} from "./log";
import {socketUrl} from "../constants";

class SocketHandler {
    constructor() {
        this.callbacks = []
        this.unauthorized = false
        this.connected = false
        this.org_id = ""
        this.auth_key = ""
        this.user_email = ""
        this.socket = null;
        this.modseq = 0;
    }

    setCredentials(org_id, auth_key, user_email, user_teams_auth_key) {
        this.org_id = org_id
        this.auth_key = auth_key !== null ? auth_key : user_teams_auth_key
        this.user_email = user_email
        if (this.socket) {
            this.socket.close();
        } else {
            this.connect()
        }
    }

    sendMessage(json) {
        this.socket.send(JSON.stringify(json))
    }

    receivedMessage(json) {
        if (json.hasOwnProperty("op")) {
            let op = json["op"]
            if (op === 'AUTH') {
                this.authenticate()
                return;
            } else if (op === 'SYNC') {
                this.sync(json)
                return;
            }
        }
        this.callCallbacks(json);
    }

    authenticate() {
        const json = JSON.stringify({
            email: this.org_id,
            auth_key: this.auth_key,
            user_email: this.user_email,
        })
        this.socket.send(json)
    }

    sync(json) {
        if (json.hasOwnProperty("modseq")) {
            let new_modseq = json["modseq"]
            if (this.modseq !== new_modseq) {
                this.callCallbacks(json);
            }
        }
    }

    addCallback(callback) {
        this.callbacks.push(callback)
    }

    removeCallback(callback) {
        this.callbacks = this.callbacks.filter(item => item !== callback)
    }

    callCallbacks(json) {
        this.callbacks.forEach(callback => {
            callback(json);
        })
    }

    connect() {
        if (this.unauthorized) {
            return;
        }
        if (this.auth_key === null && (this.org_id === null || this.user_email === null)) {
            return;
        }
        this.socket = new WebSocket(socketUrl);
        this.socket.onopen = () => {
            this.connected = true;
            console_log('Socket opened')
        }
        let self = this;
        this.socket.onclose = (e) => {
            this.connected = false;
            console_log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function() {
                self.connect()
            }, 1000);
        }
        this.socket.onerror = (e) => {
            this.socket.close()
        }
        this.socket.onmessage = e => {
            const message = JSON.parse(e.data);
            this.receivedMessage(message);
        };
    }
}

const socket = new SocketHandler()

const WebSocketContext = createContext(socket)

export { WebSocketContext }

const socketWrapper = ({ children }) => {
    return (
        <WebSocketContext.Provider value={{socket}}>
            {children}
        </WebSocketContext.Provider>
    )
};

export default socketWrapper;