import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    Grid,
    Paper,
    TextField,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import clsx from "clsx";
import {ChevronLeft} from "@material-ui/icons";
import Autocomplete from '@mui/material/Autocomplete';

class CCTeam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            teamName: "",
            selectedEmails: {
                managers: [],
                members: [],
            },
            options: [],
            teamNameErrorMessage: ""
        }

        this.teamNames = new Set();
        if (props.teams) {
            props.teams.forEach(team => {
                this.teamNames.add(team.name);
            });
            if (this.props.team) {
                this.teamNames.delete(this.props.team.name);
            }
        }
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        let managers = [];
        if (this.props.team !== null) {
            const uniqueManagers = [...new Set(this.props.team.managers)];
            const uniqueMembers = [...new Set(this.props.team.members)];
            this.setState({
                teamName: this.props.team.name,
                selectedEmails: {
                    managers: uniqueManagers,
                    members: uniqueMembers,
                }
            });
            managers = uniqueManagers;
        }

        let options = [];
        if (this.props.isAdmin) {
            options = this.props.licenseEmails
        } else {
            this.props.licenseEmails.forEach(email => {
                if (!managers.includes(email)) {
                    options.push(email);
                }
            })
        }
        this.setState({
            options: options
        })
    }

    save() {
        if (this.state.teamName.length === 0) {
            this.setState({
                teamNameErrorMessage: "This field is required",
            });
            return;
        } else if (this.teamNames.has(this.state.teamName)) {
            this.setState({
                teamNameErrorMessage: "Team name already exists",
            });
            return;
        }
        let team = this.props.team;
        team.name = this.state.teamName;
        team.managers = [...new Set(this.state.selectedEmails.managers)];
        team.members = [...new Set(this.state.selectedEmails.members)];
        this.props.saveTeam(team);
    }

    delete() {
        this.props.deleteTeam(this.props.team.id)
        this.props.back()
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;
        const getAutoCompleteComponent = (role, freeze) =>  {
            const roleFormatted = role.slice(0, -1).charAt(0).toUpperCase() + role.slice(1, -1) + "(s)";
            return <Autocomplete
                className={classes.autoCompleteCustom}
                fullWidth={true}
                multiple
                disabled={freeze}
                value={this.state.selectedEmails[role]}
                options={this.state.options}
                disableCloseOnSelect
                getOptionLabel={(option) => option}
                onChange={(event, newValue) => {
                    let update = this.state.selectedEmails;
                    update[role] = newValue;
                    Object.entries(update).forEach(([key, selectedTeams]) => {
                        if (key !== role) {
                            update[key] = selectedTeams.filter(item => !newValue.includes(item));
                        }
                    })
                    this.setState({
                        selectedEmails: update
                    });
                }}
                renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                        <li key={key} {...optionProps} className={classes.autoCompleteOption}>
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option}
                        </li>
                    );
                }}
                filterOption={(option, query) => {
                    return option.toLowerCase().includes(query.toLowerCase());
                }}
                renderInput={(params) => (
                    <TextField {...params}
                               className={clsx(classes.textField)}
                               label={"Team " + roleFormatted}
                               placeholder=""
                               variant="outlined"
                    />
                )}
                renderTags={(value, getTagProps) => {
                    if (value.length === 0) {
                        return ""
                    }
                    return value.map((option, index) => option).join(', ')
                }}
            />
        }

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button onClick={() => this.props.back()}><ChevronLeft/></Button>
                <Grid container spacing={3} className={classes.paper}>
                    <Grid item xs={12}>
                        <TextField onChange={(e) =>
                            this.setState({teamName: e.target.value})}
                                   className={clsx(classes.textField)}
                                   required
                                   id="outlined-required"
                                   label="Team Name"
                                   fullWidth
                                   value={this.state.teamName}
                                   variant="outlined"
                                   error={this.state.teamNameErrorMessage.length > 0}
                                   helperText={this.state.teamNameErrorMessage}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {
                            getAutoCompleteComponent("managers", !this.props.isAdmin)
                        }
                    </Grid>
                    <Grid item xs={12}>
                        {
                            getAutoCompleteComponent("members")
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <div className={"row"}>
                            <Button className={clsx(classes.button, classes.bottom)}
                                    variant="contained"
                                    onClick={() => {
                                        this.save()
                                    }}>
                                Save
                            </Button>
                            {
                                this.props.team !== null && this.props.isAdmin &&
                                <Button className={clsx(classes.button, classes.bottom)} variant="contained"
                                        onClick={() => {
                                            this.delete()
                                        }}
                                        style={{marginLeft: "24px"}}>
                                    Delete
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCTeam)