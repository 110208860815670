import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {Checkbox, Container, Grid, MenuItem, Paper, Switch, TextField} from "@material-ui/core";
import {DataGrid, GridCellParams} from "@material-ui/data-grid";
import {useStyles} from "./styles";
import {getPrefs, savePrefs} from "./api";
import clsx from "clsx";
import {get_default_prefs} from "./data";


class CCPreferences extends Component {
    constructor() {
        super();

        this.prefs = get_default_prefs().sort((a, b) => a.text.localeCompare(b.text));
        this.prefsMap = {}
        let state = {}
        this.prefs.forEach(pref => {
            this.prefsMap[pref.id] = pref;
            state[pref.id] = {key: pref.id, value: pref.value, lock: pref.lock}
        })
        state["loading"] = true
        state["hasLoaded"] = false
        this.state = state;
    }

    updateState(dict, key) {
        if (key in dict) {
            let update = {};
            update[key] = dict[key];
            this.setState(update);
        }
    }

    componentDidMount() {
        getPrefs(this.props.org.org_uid, this.props.org.org_auth_key, json => {
            for (const [key] of Object.entries(this.prefsMap)) {
                this.updateState(json, key)
            }
            this.setState({
                hasLoaded: true,
                loading: false,
            })
        })
    }

    save(prefs) {
        this.setState({
            loading: true,
        }, () => {
            this.setState(prefs, () => {
                savePrefs(this.props.org.org_uid, this.props.org.org_auth_key, prefs, json => {
                    this.setState({
                        loading: false
                    })
                })
            })
        })
    }

    setSwitchPref(pref, value) {
        let curr = this.state[pref];
        curr.value = value;
        let update = {}
        update[pref] = curr;
        this.save(update);
    }

    setComboPref(pref, options, value) {
        let curr = this.state[pref];
        curr.value = options.indexOf(value);
        let update = {}
        update[pref] = curr;
        this.save(update);
    }

    setLockPref(pref, lock) {
        let curr = this.state[pref];
        curr.lock = lock;
        let update = {}
        update[pref] = curr;
        this.save(update);
    }

    render() {
        const {classes} = this.props;

        const columns = [
            {field: 'text', headerName: 'Preference', flex: 4,},
            {
                field: 'id', headerName: 'Default', flex: 3,
                renderCell: (params: GridCellParams) => {
                    let pref = this.prefsMap[params.value]
                    if (pref.type === 'Multi') {
                        return <div className={classes.rowFull}>
                            <TextField
                                // className={classes.right}
                                id="outlined-select-currency"
                                select
                                className={clsx(classes.textField)}
                                value={pref.values[this.state[pref.id].value]}
                                onChange={(e) => this.setComboPref(pref.id, pref.values, e.target.value)}
                                variant="outlined">
                                {pref.values.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        {option}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    } else {
                        return <div className={classes.rowFull}>
                            <Switch
                                // className={classes.right}
                                checked={this.state[pref.id].value}
                                onChange={(e) => this.setSwitchPref(pref.id, e.target.checked)}
                                color="primary"
                                name="checkedB"
                                inputProps={{'aria-label': 'primary checkbox'}}
                            />
                        </div>
                    }
                }
            },
            {
                field: 'lock', headerName: 'Enforce', flex: 2,
                renderCell: (params: GridCellParams) => {
                    let id = params.id
                    let pref = this.prefsMap[id]
                    return <div className={classes.rowFull}>
                        <Checkbox
                            // className={classes.right}
                            checked={this.state[pref.id].lock}
                            onChange={(e) => this.setLockPref(pref.id, e.target.checked)}
                            color="primary"
                            name="checkedB"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </div>
                }
            }
        ];

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataGrid autoHeight
                                  className={classes.paperGrid}
                                  pageSize={this.prefs.length}
                                  rowHeight={80}
                                  pagination={false}
                                  columns={columns}
                                  disableSelectionOnClick
                                  rows={this.state.hasLoaded ? this.prefs : []}
                                  loading={this.state.loading}
                                  disableColumnResize={true}
                                  disableColumnSelector={true}/>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCPreferences)