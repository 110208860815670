import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Container, Paper} from "@material-ui/core";
import clsx from "clsx";
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import CCStripeForm from "./form";
import {ChevronLeft} from "@material-ui/icons";
import {rootStripeKey} from "../../constants";

const stripePromise = loadStripe(rootStripeKey);

class CCPurchase extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const appearance = {
            theme: 'night',
            labels: 'floating',
            rules: {
                '.Input, .Block': {
                    backgroundColor: '#132F4C',
                    border: '1px solid #173A5E',
                    boxShadow: 'none',
                }
            }
        };
        const options = {
            // passing the client secret obtained from the server
            clientSecret: this.props.clientSecret,
            appearance: appearance
        };

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button variant={"outlined"} className={clsx(classes.buttonOutlined, classes.bottom)} onClick={() => this.props.back()}><ChevronLeft/></Button>
                <Elements stripe={stripePromise} options={options}>
                   <CCStripeForm org={this.props.org}/>
                </Elements>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCPurchase)