// TODO: Disable this in production
// const rootUrl = "localhost:9300"
// const rootProtocol = "http://"
// const rootSocketProtocol = "ws://"
// const rootAdminUrl = "http://localhost:3000"
// const rootStripeKey = "pk_test_51K16zJLCDBXMMW8wdUTiwBPpz75cLsDZS5Iisp8ECvxKxU8ttn0xOVFC4JVQX3VcHTY73Kw81utF02DRQvymj0QY00FZPGGBlL"


// TODO: Enable this in production
const rootUrl = "api.canarymail.io"
const rootProtocol = "https://"
const rootSocketProtocol = "wss://"
const rootAdminUrl = "https://admin.canarymail.io"
const rootStripeKey = "pk_live_51K16zJLCDBXMMW8wHxjnyGwvppRBN7ypx3azOU70LPdsxfcOBvMkISLldqusX24RXVFVWTb7tjd8BsJ0v6cryemh00hDSi3MYe"

const baseUrl = rootProtocol + rootUrl;
const socketUrl = rootSocketProtocol + rootUrl + "/feed"

export {
    baseUrl, socketUrl, rootAdminUrl, rootStripeKey
}