import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Button, Card, CardContent, CircularProgress, Container, Grid, Paper, TextField} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import CCAppBar from "../appbar";
import Typography from "@material-ui/core/Typography";
import {DataGrid} from "@material-ui/data-grid";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

class CCOrganizations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_org_name: "",
            show_cards: true,
        };
    }

    componentDidMount() {

    }

    card(classes, org) {
        return <Grid item xs={12} sm={6} md={4} key={org.organization_uid}>
            <Card className={clsx(classes.card, classes.paperLight)} onClick={() => {
                this.props.setOrganization(org)
            }}>
                <CardContent>
                    <Typography variant="h6">{org.organization_name}</Typography>
                    <Typography variant="subtitle2">{org.organization_owner}</Typography>
                    <Typography variant="caption">{org.organization_uid}</Typography>
                </CardContent>
            </Card>
        </Grid>
    }

    divider() {
        return <Grid item xs={12} style={{height: "1px"}}>
            <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
        </Grid>
    }

    addOrg(classes) {
        return <Grid item xs={12}>
            <div className={classes.column}>
                <Typography variant="h6" style={{marginBottom: "24px"}}>
                    Create Organization
                </Typography>
                <div className={classes.rowFull}>
                    <TextField onChange={(e) => {
                        this.setState({new_org_name: e.target.value})
                    }}
                               id="outlined-required"
                               label="Organization Name"
                               disabled={this.props.loading}
                               value={this.state.new_org_name}
                               variant="outlined"
                               className={clsx(classes.marginRight, classes.textField)}
                    />
                    <Button variant="contained" className={clsx(classes.button, classes.right)}
                            disabled={this.props.loading || this.state.new_org_name.length === 0}
                            onClick={e => {
                                const name = this.state.new_org_name
                                this.setState({
                                    new_org_name: "",
                                }, () => {
                                    this.props.addOrganization(name)
                                })
                            }}>
                        Create
                    </Button>
                </div>
            </div>
        </Grid>
    }

    cards(classes) {
        return this.props.loading ? <Grid item xs={12}><CircularProgress/></Grid> :
            this.props.orgs.map((org, i) => {
                return this.card(classes, org)
            })
    }

    grid(classes) {
        const columns = [
            {field: 'organization_uid', headerName: 'ID', flex: 1,},
            {field: 'organization_name', headerName: 'Name', flex: 1,},
            {field: 'organization_owner', headerName: 'Owner', flex: 1,},
            // {field: 'auth_key', headerName: 'Auth Key', flex: 1},
        ]
        return <Grid item xs={12}>
            <DataGrid autoHeight columns={columns}
                      className={classes.paperGrid}
                      rows={this.props.orgs}
                      loading={this.props.loading}
                      pageSize={20}
                      onRowClick={item => {
                          this.props.setOrganization(item.row.organization_uid, item.row.auth_key, item.row.organization_name, item.row.show_biyearly)
                      }}/>
        </Grid>
    }

    render() {
        const {classes} = this.props;

        return <div className={classes.root}>
            <CssBaseline/>
            <CCAppBar position="fixed" className={classes.appBar} headerRight={this.props.profile}/>
            <div className={classes.column}>
                <div className={classes.toolbar}/>
                <Container className={classes.orgRoot}>
                    <Typography className={classes.activeTitle} variant="h1"><b>Organizations</b></Typography>
                    {
                        this.props.loading && <div className={classes.paper}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <div className={classes.qsContainer}>
                                        <CircularProgress/>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    }
                    {
                        this.state.show_cards && !this.props.loading &&
                        <div className={classes.paper}>
                            <Grid container spacing={3}>
                                {
                                    this.cards(classes)
                                }
                                {
                                    this.divider()
                                }
                                {
                                    this.addOrg(classes)
                                }
                            </Grid>
                        </div>
                    }
                    {
                        !this.state.show_cards &&
                        <Paper className={clsx(classes.paper, classes.paperLight)} style={{"marginTop": "24px"}}>
                            <Grid container spacing={3}>
                                {
                                    this.grid(classes)
                                }
                                {
                                    this.divider()
                                }
                                {
                                    this.addOrg(classes)
                                }
                            </Grid>
                        </Paper>
                    }
                </Container>
            </div>
        </div>
    }
}

export default withStyles(useStyles)(CCOrganizations)