import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Container, Paper} from "@material-ui/core";
import clsx from "clsx";
import {getPortalLink} from "../api";

class CCPayments extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.openPortal = this.openPortal.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    openPortal() {
        this.setState({
            loading: true,
        }, () => {
            getPortalLink(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                let url = json.portal.url;
                window.location = url;
            })
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button onClick={this.openPortal}>Manage Payment Methods</Button>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCPayments)