import {ElementsConsumer} from '@stripe/react-stripe-js';
import CCCheckout from "./checkout";

function CCStripeForm(props) {
    return (
        <ElementsConsumer>
            {({stripe, elements}) => (
                <CCCheckout org={props.org} stripe={stripe} elements={elements} />
            )}
        </ElementsConsumer>
    )
}

export default CCStripeForm;