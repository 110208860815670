import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {
    Button,
    ButtonGroup,
    Container,
    FormControl,
    Grid,
    InputLabel,
    Paper,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import clsx from "clsx";
import {guidGenerator} from "./api";
import {useStyles} from "./styles";


class CCConfiguration extends Component {
    constructor(props) {
        super(props);

        let configId = "", name = "", domain = "";
        let imapHostname = "", imapPort = 993, imapSSL = 4;
        let smtpHostname = "", smtpPort = 587, smtpSSL = 2;
        let tab = "IMAP", exchangeServer = "";

        if (props.configuration) {
            configId = props.configuration.id
            name = props.configuration.name
            tab = props.configuration.tab
            if (tab === 'Exchange') {
                exchangeServer = props.configuration.exchangeServer;
            } else if (tab === 'IMAP') {
                imapHostname = props.configuration.imapHostname;
                imapPort = props.configuration.imapPort;
                imapSSL = props.configuration.imapSSL;
                smtpHostname = props.configuration.smtpHostname;
                smtpPort = props.configuration.smtpPort;
                smtpSSL = props.configuration.smtpSSL;
            }
        } else {
            configId = guidGenerator()
        }

        this.state = {
            tab: tab,
            configurations: [],

            configId: configId,
            name: name,
            domain: domain,

            imapHostname: imapHostname,
            imapPort: imapPort,
            imapSSL: imapSSL,
            smtpHostname: smtpHostname,
            smtpPort: smtpPort,
            smtpSSL: smtpSSL,

            exchangeServer: exchangeServer,

            allowInsecureCertificate: false,
        }
        this.setImapSSL = this.setImapSSL.bind(this)
        this.setSmtpSSL = this.setSmtpSSL.bind(this)
    }

    setTab(tab) {
        this.setState({
            tab: tab
        });
    }

    imapSSL() {
        return this.state.imapSSL === 4 ? "SSL" :
            this.state.imapSSL === 2 ? "StartTLS" :
                "None"
    }

    setImapSSL(e) {
        let val = e.target.value;
        let num = val === 'SSL' ? 4 :
            val === 'StartTLS' ? 2 : 0;
        this.setState({
            imapSSL: num
        })
    }

    smtpSSL() {
        return this.state.smtpSSL === 4 ? "SSL" :
            this.state.smtpSSL === 2 ? "StartTLS" :
                "None"
    }

    setSmtpSSL(e) {
        let val = e.target.value;
        let num = val === 'SSL' ? 4 :
            val === 'StartTLS' ? 2 : 0;
        this.setState({
            smtpSSL: num
        })
    }

    render() {
        const {classes} = this.props;
        const isIMAP = this.state.tab === 'IMAP'
        const isExchange = this.state.tab === 'Exchange'
        // const isGSuite = this.state.tab === 'GSuite'
        // const isOffice365 = this.state.tab === 'Office 365'

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button className={classes.bottom} onClick={() => this.props.back()}><ChevronLeft/></Button>
                <Grid container spacing={3} justify="center">
                    <Grid item className={"column"} xs={12}>
                        <ButtonGroup color="primary" aria-label="outlined primary button group">
                            {
                                ["IMAP", "Exchange", "GSuite", "Office365"].map(tab => {
                                    return <Button className={tab === this.state.tab && classes.button}
                                                   onClick={(e) => this.setTab(tab)}>{tab}</Button>
                                })
                            }
                        </ButtonGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.column}>
                            {this.renderName(classes)}
                        </div>
                    </Grid>
                    {
                        isIMAP && this.renderIMAP()
                    }
                    {
                        isIMAP && this.renderSmtp()
                    }
                    {
                        isExchange && this.renderExchange()
                    }
                    <Grid className={"column"} item xs={12}>
                        <div className={"row"}>
                            <Button className={clsx(classes.button, classes.bottom)} variant="contained"
                                    onClick={() => {
                                        if (isExchange) {
                                            this.props.saveExchange(this.state.configId, this.state.tab,
                                                this.state.name, this.state.exchangeServer)
                                        } else if (isIMAP) {
                                            this.props.saveImap(this.state.configId, this.state.tab, this.state.name,
                                                this.state.imapHostname, this.state.imapPort, this.state.imapSSL,
                                                this.state.smtpHostname, this.state.smtpPort, this.state.smtpSSL)
                                        } else {
                                            this.props.save(this.state.configId, this.state.tab, this.state.name)
                                        }
                                    }}>Save</Button>
                            {
                                this.props.configuration &&
                                <Button className={clsx(classes.button, classes.bottom)} variant="contained"
                                        onClick={() => {
                                            this.props.delete(this.state.configId)
                                        }} style={{marginLeft: "24px"}}>
                                    Delete
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }

    renderName(classes) {
        // const {classes} = this.props;
        return <TextField onChange={(e) =>
            this.setState({name: e.target.value})}
                          required
                          id="outlined-required"
                          label="Account Name"
                          helperText="Will be displayed in provider screen"
                          value={this.state.name}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
        />;
    }

    renderDomain(classes) {
        // const {classes} = this.props;
        return <TextField onChange={(e) =>
            this.setState({domain: e.target.value})}
                          required
                          id="outlined-required"
                          label="Domains"
                          value={this.state.domain}
                          margin="normal"
                          variant="outlined"
                          className={classes.textField}
        />;
    }

    renderIMAP() {
        const {classes} = this.props;
        return <Grid item xs={12} md={6}>
            <div className={classes.column}>
                <Typography variant="body2"><b>IMAP</b></Typography>
                <TextField onChange={(e) =>
                    this.setState({imapHostname: e.target.value})}
                           required
                           fullWidth
                           id="outlined-required"
                           label="IMAP Hostname"
                           value={this.state.imapHostname}
                           margin="normal"
                           variant="outlined"
                           className={classes.textField}
                />
                <TextField onChange={(e) =>
                    this.setState({imapPort: e.target.value})}
                           className={classes.textField}
                           required
                           fullWidth
                           id="outlined-required"
                           label="IMAP Port"
                           value={this.state.imapPort}
                           margin="normal"
                           variant="outlined"
                />
                <FormControl variant="outlined" margin="normal" className={classes.textField}>
                    <InputLabel htmlFor="outlined-age-native-simple">IMAP SSL</InputLabel>
                    <Select
                        native
                        value={this.imapSSL()}
                        onChange={this.setImapSSL}
                        label="IMAP SSL">
                        {
                            ["SSL", "StartTLS", "None"].map(item => {
                                return <option value={item}>{item}</option>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        </Grid>
    }

    renderSmtp() {
        const {classes} = this.props;
        return <Grid item className={classes.column} xs={12} md={6}>
            <div className={classes.column}>
                <Typography variant="body2"><b>SMTP</b></Typography>
                <TextField onChange={(e) =>
                    this.setState({smtpHostname: e.target.value})}
                           required
                           fullWidth
                           id="outlined-required"
                           label="SMTP Hostname"
                           value={this.state.smtpHostname}
                           margin="normal"
                           variant="outlined"
                           className={classes.textField}
                />
                <TextField onChange={(e) =>
                    this.setState({smtpPort: e.target.value})}
                           required
                           fullWidth
                           id="outlined-required"
                           label="SMTP Port"
                           value={this.state.smtpPort}
                           margin="normal"
                           variant="outlined"
                           className={classes.textField}
                />
                <FormControl variant="outlined" margin="normal" className={classes.textField}>
                    <InputLabel htmlFor="outlined-age-native-simple">SMTP SSL</InputLabel>
                    <Select
                        native
                        value={this.smtpSSL()}
                        onChange={this.setSmtpSSL}
                        label="SMTP SSL">
                        {
                            ["SSL", "StartTLS", "None"].map(item => {
                                return <option value={item}>{item}</option>
                            })
                        }
                    </Select>
                </FormControl>
            </div>
        </Grid>
    }

    renderExchange() {
        const {classes} = this.props;
        return <Grid item className={classes.column} xs={12} md={12}>
            <div className={classes.column}>
                <Typography variant="body2"><b>Exchange</b></Typography>
                {/*{ this.renderDomain() }*/}
                <TextField onChange={(e) =>
                    this.setState({exchangeServer: e.target.value})}
                           required
                           fullWidth
                           id="outlined-required"
                           label="Server"
                           value={this.state.exchangeServer}
                           margin="normal"
                           variant="outlined"
                           className={classes.textField}
                />
            </div>
        </Grid>
    }
}

export default withStyles(useStyles)(CCConfiguration)