const config = {
    apiKey: "AIzaSyDoWjtAbAcfliR3dmR3KFrOhoQuS1wXn-8",
    authDomain: "admin.canarymail.io",
    projectId: "canary-admin-8326e",
    storageBucket: "canary-admin-8326e.appspot.com",
    messagingSenderId: "602956129836",
    appId: "1:602956129836:web:c6b1c58061450fe438d389",
    measurementId: "G-38YKW693MW"
};

export default config;