import * as React from 'react';
import './App.css';
import 'firebase/auth';
import {ThemeProvider} from '@material-ui/core/styles';
import CCDashboard from "./components/dashboard";
import CCLogin from "./components/login";
import WebSocketProvider from './components/pages/socket'
import {withAuth} from "./components/pages/context";
import {darkTheme} from "./components/theme";
import CCLoader from "./components/pages/loaders/loader";
import {peek_org_state} from "./components/pages/api";
import CCDashboardLoader from "./components/pages/loaders/dashloader";
import {get_default_console_prefs} from "./components/pages/data";

class App extends React.Component {
    constructor(props) {
        super(props);

        let consolePrefsState = {};
        get_default_console_prefs().forEach((pref) => {
            consolePrefsState[pref.id] = {key: pref.id, value: pref.value}
        })
        this.state = {
            loading: true,
            consolePrefsState: consolePrefsState
        }
    }

    componentDidMount() {
        this.callback = () => {
            this.setState({
                loading: this.props.auth.loading
            })
        };
        this.props.auth.addCallback(this.callback);
    }

    componentWillUnmount() {
        this.props.auth.removeCallback(this.callback);
    }

    render() {
        let inner = null;
        if (this.props.auth.user !== null && this.props.auth.authKey !== null) {
            inner = <CCDashboard user={this.props.auth.user}
                                 auth={this.props.auth}
                                 sendEmailVerification={this.props.auth.sendEmailVerification}
                                 signOut={this.props.auth.signOut}
                                 authKey={this.props.auth.authKey}/>
        } else if (this.props.auth.hadSignedIn() && this.props.auth.loading) {
            // let peek = peek_org_state(window);
            // if (peek[1] !== null) {
            //     inner = <CCDashboardLoader lastTab={peek[1]}/>
            // } else {
            //
            // }
            inner = <CCLoader/>
        } else {
            inner = <CCLogin signInWithGoogle={this.props.auth.signInWithGoogle}
                             signInWithEmail={this.props.auth.signInWithEmail}
                             signUpWithEmail={this.props.auth.signUpWithEmail}
                             loading={this.state.loading}
                             auth={this.props.auth.firebaseAppAuth}/>
        }
        return (
            <React.Fragment>
                <WebSocketProvider>
                    <ThemeProvider theme={darkTheme()}>
                        {
                            inner && inner
                        }
                    </ThemeProvider>
                </WebSocketProvider>
            </React.Fragment>
        );
    }
}

/** Wrap it */
export default withAuth(App);
