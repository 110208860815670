import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import CCCheckout from "./checkout";
import {Button, CircularProgress, Container, Grid, Paper} from "@material-ui/core";
import {
    createSubscription,
    getPortalLink,
    requestSubscriptions,
    resumeSubscription,
    save_org_state,
    updateSubscriptions
} from "./api";
import {useStyles} from "./styles";
import {withWebSocket} from "./context";
import clsx from "clsx";
import CCPurchase from "./stripe/purchase";
import {StripeProduct, StripeSubscription} from "./stripe/objects";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import {renderFakeSubscription} from "./stripe/subscription";

class CCBilling extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            active: null,
            selected: [],
            subscriptions: null,
            prices: null,
            products: null,
            isPilot: false,
        }
        this.refresh = this.refresh.bind(this);
        this.createSubscription = this.createSubscription.bind(this);
        this.updateSubscription = this.updateSubscription.bind(this);
        this.openPortal = this.openPortal.bind(this);
        this.back = this.back.bind(this);
        this.clearActive = this.clearActive.bind(this);
    }

    componentDidMount() {
        this.refresh()
        this.callback = (json) => {
            if (json.hasOwnProperty("modseq")) {
                this.refresh();
            }
        }
        this.props.ws.socket.addCallback(this.callback)
        // this.props.ws.socket.sendMessage({"Test": "Hi"})
    }

    componentWillUnmount() {
        this.props.ws.socket.removeCallback(this.callback)
    }

    setActive(subscription) {
        this.setState({
            active: subscription
        })
    }

    clearActive() {
        this.setState({
            active: null
        })
    }

    back() {
        this.setState({
            loading: true,
            clientSecret: null,
        }, () => {
            this.refresh();
        })
    }

    refresh() {
        requestSubscriptions(this.props.org.org_uid, this.props.org.org_auth_key,
            items => {
                this.setState({
                    loading: false,
                    subscriptions: items.subscriptions,
                    prices: items.prices,
                    products: items.products,
                    isPilot: items.is_pilot,
                    pilotExpiry: items.pilot_expiry,
                    seats_total: items.seats_total,
                    seats_used: items.seats_used,
                    seats_needed: items.seats_needed,
                    seats_remaining: items.seats_remaining,
                    // clientSecret: "pi_3KCHHOLCDBXMMW8w02kHmQIG_secret_JivBLi4AVUK9sdQuWSaqyNfZU",
                    // subId: "sub_1KCHHOLCDBXMMW8w5dGv72v6",
                });
            })
    }

    createSubscription(priceId, quantity, promoCode) {
        this.setState({
            loading: true
        }, () => {
            createSubscription(this.props.org.org_uid, this.props.org.org_auth_key, priceId, quantity, promoCode, json => {
                console.log('Created subscription: ', json);
                if (json.hasOwnProperty("error")) {
                    this.setState({
                        loading: false,
                        error: json['error']
                    });
                } else if (json.hasOwnProperty('client_secret') && json.client_secret !== null && json.client_secret !== undefined) {
                    this.setState({
                        loading: false,
                        clientSecret: json['client_secret'],
                        subId: json['sub_id']
                    });
                } else {
                    this.setState({
                        clientSecret: null,
                        subId: json['sub_id']
                    }, () => {
                        this.refresh();
                    })
                }
            })
        })
    }

    resumeSubscription(subId) {
        this.setState({
            loading: true
        }, () => {
            resumeSubscription(this.props.org.org_uid, this.props.org.org_auth_key, subId, json => {
                if (json.hasOwnProperty('client_secret')) {
                    this.setState({
                        loading: false,
                        clientSecret: json['client_secret'],
                        subId: json['sub_id']
                    });
                } else {
                    this.setState({
                        loading: false,
                    })
                }
            })
        })
    }

    updateSubscription(subId, quantity, priceId, promoCode) {
        this.setState({
            loading: true,
            active: null,
        }, () => {
            updateSubscriptions(this.props.org.org_uid, this.props.org.org_auth_key, subId, quantity, priceId, promoCode, json => {
                this.refresh();
            })
        })
    }

    divider() {
        return <Grid item xs={12} style={{height: "1px"}}>
            <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
        </Grid>
    }

    openPortal() {
        this.setState({
            loading: true,
        }, () => {
            getPortalLink(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                save_org_state(window, this.props.org.org_uid, "Subscription");
                window.location = json.portal.url;
            })
        })
    }

    renderSubscription(classes, subscription: StripeSubscription) {
        console.log(subscription);
        let price = subscription.getPrice();
        let isYearly = price.isAnnual(1);
        let isBiYearly = price.isAnnual(2);
        let willCancel = subscription.willCancel();
        let willCancelDate = subscription.willCancelDate();
        let nextBillDate = subscription.getCurrentEnd();
        let billStr = nextBillDate.toLocaleDateString();
        let product: StripeProduct = this.state.products[price.getProduct()]
        let incomplete = subscription.needsToUpdatePayment();
        let isTrial = subscription.getStatus() === 'trialing';
        let trialEnd = isTrial ? (new Date(subscription.getTrialEnd()).toLocaleDateString()) : null
        return <Grid container spacing={3} key={String(subscription.getId())}>
            <Grid item xs={12}>
                <div className={classes.rowSm}>
                    <Typography variant={"h5"}><b>Current Subscription</b></Typography>
                    <div className={clsx(classes.row, classes.rightSm)}>
                        {
                            !incomplete && <Button className={clsx(classes.button)} style={{width: "150px"}}
                                                   onClick={() => {
                                                       this.setActive(subscription);
                                                   }
                                                   }>
                                Update Seats
                            </Button>
                        }
                        <Button className={incomplete ? clsx(classes.button) : clsx(classes.button, classes.left3)} style={{width: "150px"}} onClick={() => {
                            if (incomplete) {
                                this.resumeSubscription(subscription.getId())
                            } else {
                                this.openPortal();
                            }
                        }
                        }>
                            { incomplete ? "Finish Payment" : "Manage" }
                        </Button>
                    </div>
                </div>
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"subtitle2"}>Your subscription status is <b>{subscription.getStatus()}</b>.
                    {
                        trialEnd &&
                        <span> Your trial ends on <b>{trialEnd}</b>.</span>
                    }
                </Typography>
            </Grid>
            {
                willCancel && willCancelDate &&
                <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>Your subscription will be cancelled on <b>{willCancelDate.toLocaleDateString()}</b>.</Typography>
                </Grid>
            }
            {
                !willCancel && !willCancelDate &&
                <Grid item xs={12}>
                    <Typography variant={"subtitle2"}>Your account is billed {isBiYearly ? "bi-yearly" : isYearly ? "annually" : "monthly"}
                        &nbsp;and the next payment is due on <b>{billStr}</b>.</Typography>
                </Grid>
            }
            <Grid item xs={12}>
                {
                    this.state.seats_remaining > 0 &&
                    <Typography variant={"subtitle2"}>
                        You have {this.state.seats_total} total seats, of which {this.state.seats_used} have been assigned to users. You have {this.state.seats_remaining} seats available.
                    </Typography>
                }
                {
                    this.state.seats_remaining === 0 &&
                    <Typography variant={"subtitle2"}>
                        You have {this.state.seats_total} total seats, of which {this.state.seats_used} have been assigned to users. You need to purchase {this.state.seats_needed} more seats.
                    </Typography>
                }
            </Grid>
            <Grid item xs={12}>
                <Typography variant={"subtitle2"}>
                    Use <b>Manage</b> to make changes to your account.
                </Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"}><b>Product</b></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"}><b>Seats</b></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"}><b>{isBiYearly ? "Bi-Yearly" : isYearly ? "Yearly" : "Monthly"} Cost</b></Typography>
            </Grid>
            {
                this.divider()
            }
            <Grid item xs={4}>
                <Typography variant={"subtitle2"}>{product.getName()}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"}>{subscription.getQuantity()}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"subtitle2"}>{subscription.getEstimatedPrice()}</Typography>
            </Grid>
            {
                this.divider()
            }
            <Grid item xs={4}>

            </Grid>
            <Grid item xs={4}>
                <Typography variant={"body1"}><b>Estimated total cost</b></Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography variant={"body1"}><b>{subscription.getEstimatedPrice()}</b></Typography>
            </Grid>
        </Grid>
    }

    render() {
        const {classes} = this.props;

        console.log(this.props.org);

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        if (this.state.clientSecret) {
            return <CCPurchase org={this.props.org} clientSecret={this.state.clientSecret} back={this.back}/>
        }

        if (this.state.active) {
            return <CCCheckout org={this.props.org} subscription={this.state.active} clear={this.clearActive} manage={this.openPortal.bind(this)}
                               update={this.updateSubscription} prices={this.state.prices} products={this.state.products}/>
        }
        
        if (this.state.subscriptions === null || this.state.subscriptions.length === 0) {
            return <CCCheckout org={this.props.org} isPilot={this.state.isPilot} prices={this.state.prices}
                               pilotExpiry={this.state.pilotExpiry} products={this.state.products} create={this.createSubscription}/>
        }

        let renderFake = this.state.isPilot;
        let subscription = this.state.subscriptions[0];
        if (subscription !== null) {
            renderFake = false;
        }

        return <Container className={classes.root}>
            <Grid container spacing={3}>
                {
                    renderFake &&
                    <Grid item xs={12}>
                        <Paper className={clsx(classes.paper, classes.paperLight)}>
                            {
                                renderFakeSubscription(classes, this.state.pilotExpiry)
                            }
                        </Paper>
                    </Grid>
                }
                <Grid item xs={12}>
                    <Paper className={clsx(classes.paper, classes.paperLight)}>
                        {
                            this.state.subscriptions.map(sub => {
                                return this.renderSubscription(classes, sub);
                            })
                        }
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    }
}

export default withWebSocket(withStyles(useStyles)(CCBilling))