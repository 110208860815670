import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {loginStyles} from "./../styles";
import {Button, CircularProgress, Container, Grid, Typography} from "@material-ui/core";
import clsx from "clsx";
import logo from "../../../assets/canary-logo-fs8.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGoogle} from "@fortawesome/free-brands-svg-icons";

class CCSignIn extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Grid container direction="column"
                     justifyContent="center"
                     alignItems="center" spacing={3}>
            <Grid item><img className={clsx(classes.logo)} src={logo} alt=""/></Grid>
            <Grid item><Typography variant="h2"><b>Welcome to Canary</b></Typography></Grid>
            <Grid item><Typography variant="body2">Don't have an account?
                <span className={classes.url} onClick={this.props.showSignUpWithEmail}> Sign up for
                free</span></Typography></Grid>
            <Grid item><Button className={clsx(classes.buttonOn, classes.fixedWidth)} variant="contained"
                               onClick={this.props.signInWithGoogle}>
                <FontAwesomeIcon icon={faGoogle} />&nbsp;Continue with Google
            </Button></Grid>
            <Grid item onClick={this.props.showSignInWithEmail}><Typography className={clsx(classes.url)} variant="body2">Sign
                in with email
                instead</Typography></Grid>
        </Grid>;
    }
}

export default withStyles(loginStyles)(CCSignIn)