import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import {withStyles} from '@material-ui/core/styles';
import CCAppBar from "../../appbar";
import {CircularProgress, Container} from "@material-ui/core";
import {ArrowBack} from "@material-ui/icons";
import {withWebSocket} from "../context";
import {useStyles} from "../styles";
import Typography from "@material-ui/core/Typography";
import {getDrawer} from "../info";

class CCDashboardLoader extends React.Component<{}> {
    constructor(props) {
        super(props);
        this.state = {
            mobileOpen: false,
            activeTab: props !== undefined && props !== null && props.lastTab !== null ? this.props.lastTab : "Overview",
            menuAnchor: null,
            showOrganizationMenu: true,
            showEncryptionMenu: true,
            showConfigurationMenu: true,
            showUserManagementMenu: true,
            showBillingMenu: true,
            orgs: [],
            org_uid: null,
            org_auth_key: null,
            loading: true,
        }
        this.setMobileOpen = this.setMobileOpen.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
    }

    componentDidMount() {

    }

    setMobileOpen(open) {
        this.setState({
            mobileOpen: open
        })
    }

    setActiveTab(tab) {
        this.setState({
            activeTab: tab
        })
    }

    render() {
        const {classes} = this.props;
        const {window} = this.props;

        const handleDrawerToggle = () => {
            this.setMobileOpen(!this.state.mobileOpen);
        };

        const drawer = getDrawer(classes, this.state, this.setState, this.state.activeTab, this.setActiveTab);
        const container = window !== undefined ? () => window().document.body : undefined;
        const menuIcon = <div className={classes.row}>
            <IconButton onClick={this.clearOrganization}>
                <ArrowBack style={{color: "fff"}}/>
            </IconButton>
            <Hidden mdUp>
                <IconButton onClick={handleDrawerToggle}>
                    <MenuIcon style={{color: "fff"}}/>
                </IconButton>
            </Hidden>
        </div>

        return (
            <div className={classes.dashboardRoot}>
                <CssBaseline/>
                <CCAppBar position="fixed" className={classes.appBar}
                          headerLeft={menuIcon} headerRight={null}
                          orgName={this.state.org_name}/>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden mdUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor="left"
                            open={this.state.mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>

                <main className={classes.content}>
                    <div className={classes.toolbar}/>
                    <Typography className={classes.activeTitle} variant="h1"><b>{this.state.activeTab}</b></Typography>
                    <Container className={classes.rootFull}><CircularProgress/></Container>
                </main>
            </div>
        );
    }
}

export default withWebSocket(withStyles(useStyles)(CCDashboardLoader))
