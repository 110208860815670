import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "./styles";
import {Button, Container, Grid, Paper, TextField} from "@material-ui/core";
import {addKeyServers, getKeyServers, guidGenerator, removeKeyServers} from "./api";
import clsx from "clsx";
import {DataGrid} from "@material-ui/data-grid";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";

class CCKeyServers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            serverName: "",
            serverUrl: "",
            selected: [],
            servers: [],
        };
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        getKeyServers(this.props.org.org_uid, this.props.org.org_auth_key, json => {
            this.setState({
                selected: [],
                servers: json,
                serverName: "",
                serverUrl: "",
                serverUrlError: null,
                loading: false,
            })
        })
    }

    isUrlValid() {
        const pattern = new RegExp('^((https|hkps)?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(this.state.serverUrl);
    }

    render() {
        const {classes} = this.props;

        const columns = [
            {field: 'name', headerName: 'Name', flex: 1,},
            {field: 'url', headerName: 'Server', flex: 1,},
        ];

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <DataGrid autoHeight
                                  className={classes.paperGrid}
                                  columns={columns}
                                  checkboxSelection={true}
                                  selectionModel={this.state.selected}
                                  onSelectionModelChange={change => this.setState({selected: change})}
                                  rows={this.state.servers}
                                  pageSize={10}
                                  disableSelectionOnClick
                                  loading={this.state.loading}
                                  disableColumnResize={true}
                                  disableColumnSelector={true}/>
                    </Grid>
                    {
                        this.state.selected.length > 0 &&
                        <Grid item xs={12}>
                            <Button variant="contained" disabled={this.state.loading} className={classes.button}
                                    onClick={e => {
                                        this.setState({loading: true}, () => {
                                            removeKeyServers(this.props.org.org_uid, this.props.org.org_auth_key, this.state.selected, json => {
                                                this.refresh()
                                            })
                                        })
                                    }
                                    }>
                                Delete
                            </Button>
                        </Grid>
                    }
                    <Grid item xs={12} style={{height: "1px"}}>
                        <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={classes.column}>
                            <Typography variant="h6" style={{marginBottom: "24px"}}>
                                Add Server
                            </Typography>
                            <div className={classes.rowFull}>
                                <TextField onChange={(e) => {
                                    this.setState({serverName: e.target.value})
                                }}
                                           id="outlined-required"
                                           label="Server Name"
                                           disabled={this.state.loading}
                                           value={this.state.serverName}
                                           variant="outlined"
                                           className={clsx(classes.marginRight, classes.textField)}
                                />
                                <TextField onChange={(e) => {
                                    this.setState({serverUrl: e.target.value, serverUrlError: null})
                                }}
                                           error={!!this.state.serverUrlError}
                                           id="outlined-required"
                                           label="Server URL"
                                           disabled={this.state.loading}
                                           value={this.state.serverUrl}
                                           variant="outlined"
                                           className={clsx(classes.marginRight, classes.textField)}
                                />
                                <Button variant="contained" className={clsx(classes.button, classes.right)}
                                        disabled={this.state.loading || this.state.serverName.length === 0 || this.state.serverUrl.length === 0}
                                        onClick={e => {
                                            if (!this.isUrlValid()) {
                                                this.setState({
                                                    serverUrlError: "Invalid Url"
                                                });
                                                return;
                                            }
                                            this.setState({
                                                loading: true,
                                            }, () => {
                                                let server = {
                                                    "url": this.state.serverUrl,
                                                    "name": this.state.serverName
                                                }
                                                server['id'] = guidGenerator();
                                                addKeyServers(this.props.org.org_uid, this.props.org.org_auth_key, [server], json => {
                                                    this.refresh();
                                                })
                                            })
                                        }}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCKeyServers)