import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "../styles";
import {
    Button,
    Checkbox,
    CircularProgress,
    Container,
    FormControlLabel,
    FormGroup,
    Grid,
    Paper
} from "@material-ui/core";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import {ChevronLeft} from "@material-ui/icons";

class CCApiKeyCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            scopesEnabled: [],
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    isScopeEnabled(scope) {
        return this.state[scope] === true;
    }

    toggleScope(scope) {
        if (this.isScopeEnabled(scope)) {
            let update = {}
            update[scope] = false;
            this.setState(update);
        } else {
            let update = {}
            update[scope] = true;
            this.setState(update);
        }
    }

    enabledScopes() {
        let ret = []
        Object.keys(this.props.scopeNames).forEach(key => {
            if (this.isScopeEnabled(key)) {
                ret.push(key);
            }
        })
        return ret;
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const scopeNames = this.props.scopeNames;

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button variant={"outlined"} className={clsx(classes.buttonOutlined, classes.bottom)} onClick={() => this.props.clear()}><ChevronLeft/></Button>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={"subtitle1"}>Please select scopes for the new API key:</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            <FormGroup row>
                                {
                                    Object.keys(scopeNames).map(scope => {
                                        let name = scopeNames[scope]
                                        return <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.isScopeEnabled(scope)}
                                                    onChange={() => { this.toggleScope(scope) }}
                                                    name="checkedB"
                                                    color="primary"
                                                />
                                            }
                                            label={name}
                                        />
                                    })
                                }
                            </FormGroup>
                        }
                    </Grid>
                    <Grid item xs={12}>
                        <Button variant="contained" className={clsx(classes.button, classes.right)}
                                disabled={this.props.loading || this.enabledScopes().length === 0}
                                onClick={e => {
                                    this.props.createApiKey(this.enabledScopes());
                                }}>
                            Create API Key
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCApiKeyCreate)