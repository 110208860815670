import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {Button, Container, Fab, Grid, Paper} from "@material-ui/core";
import CCConfiguration from "./configuration";
import {DataGrid} from "@material-ui/data-grid";
import {useStyles} from "./styles";
import AddIcon from "@material-ui/icons/Add";
import {addConfigs, getConfigs, removeConfigs} from "./api";
import clsx from "clsx";

class CCEmailConfigurations extends Component {
    constructor() {
        super();
        this.state = {
            configurations: [],
            selected: [],
            active: null,
            create: false,
            loading: true,
        }
        this.back = this.back.bind(this)
        this.saveIMAPConfiguration = this.saveIMAPConfiguration.bind(this)
        this.saveExchangeConfiguration = this.saveExchangeConfiguration.bind(this)
        this.saveStandardConfiguration = this.saveStandardConfiguration.bind(this)
        this.deleteConfiguration = this.deleteConfiguration.bind(this)
    }

    refresh() {
        this.setState({
            selected: [],
            loading: true,
        }, () => {
            getConfigs(this.props.org.org_uid, this.props.org.org_auth_key, json => {
                    this.setState({
                        configurations: json,
                        loading: false
                    });
                }
            )
        });
    }

    componentDidMount() {
        this.refresh()
    }

    deleteConfiguration(configId) {
        this.back()
        this.setState({
            loading: true
        }, () => {
            removeConfigs(this.props.org.org_uid, this.props.org.org_auth_key, [configId], json => {
                this.refresh();
            })
        })
    }

    saveConfiguration(configId, config) {
        this.back()
        this.setState({
            loading: true
        }, () => {
            addConfigs(this.props.org.org_uid, this.props.org.org_auth_key, [config], json => {
                this.refresh();
            })
        })
    }

    saveIMAPConfiguration(configId, tab, name, imapHostname, imapPort, imapSSL, smtpHostname, smtpPort, smtpSSL) {
        let config = {};
        config['id'] = configId
        config['tab'] = tab;
        config['name'] = name;
        config['imapHostname'] = imapHostname;
        config['imapPort'] = imapPort;
        config['imapSSL'] = imapSSL;
        config['smtpHostname'] = smtpHostname;
        config['smtpPort'] = smtpPort;
        config['smtpSSL'] = smtpSSL;
        this.saveConfiguration(configId, config)
    }

    saveExchangeConfiguration(configId, tab, name, server) {
        let config = {};
        config['id'] = configId
        config['tab'] = tab;
        config['name'] = name;
        config['exchangeServer'] = server;
        this.saveConfiguration(configId, config)
    }

    saveStandardConfiguration(configId, tab, name) {
        let config = {};
        config['id'] = configId
        config['tab'] = tab;
        config['name'] = name;
        this.saveConfiguration(configId, config)
    }

    back() {
        this.setState({
            create: false,
            active: null
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.create) {
            return <CCConfiguration back={this.back}
                                    saveImap={this.saveIMAPConfiguration}
                                    saveExchange={this.saveExchangeConfiguration}
                                    save={this.saveStandardConfiguration}
                                    delete={this.deleteConfiguration}/>
        }

        if (this.state.active) {
            return <CCConfiguration back={this.back}
                                    saveImap={this.saveIMAPConfiguration}
                                    saveExchange={this.saveExchangeConfiguration}
                                    save={this.saveStandardConfiguration}
                                    delete={this.deleteConfiguration}
                                    configuration={this.state.active}/>
        }

        const columns = [
            {field: 'name', headerName: 'Name', flex: 1,},
            {field: 'id', headerName: 'ID', flex: 1,},
            {field: 'tab', headerName: 'Type', flex: 1,},
        ]

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataGrid autoHeight columns={columns}
                                  className={classes.paperGrid}
                                  rows={this.state.configurations}
                                  loading={this.state.loading}
                                  checkboxSelection={true}
                                  selectionModel={this.state.selected}
                                  onSelectionModelChange={change => this.setState({selected: change})}
                                  pageSize={20}
                                  onRowClick={item => {
                                      this.setState({
                                          active: item.row
                                      })
                                  }}/>
                    </Grid>
                    {
                        this.state.selected.length > 0 &&
                        <Grid item xs={12}>
                            <Button variant="contained"
                                    className={clsx(classes.button)}
                                    onClick={() => {
                                        this.setState({
                                            loading: true,
                                        }, () => {
                                            removeConfigs(this.props.org.org_uid, this.props.org.org_auth_key, this.state.selected, () => {
                                                this.refresh();
                                            })
                                        })
                                    }}>
                                Delete
                            </Button>
                        </Grid>
                    }

                    <Grid item xs={12}>
                        <Fab color="primary" className={classes.floating} aria-label="add"
                             onClick={e => {
                                 this.setState({
                                     create: true
                                 })
                             }}>
                            <AddIcon/>
                        </Fab>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCEmailConfigurations)