import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {
    Button,
    CircularProgress,
    Container,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputAdornment,
    Paper,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import CCAppBar from "../../appbar";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {Delete} from "@material-ui/icons";
import {GridCheckCircleIcon} from "@material-ui/data-grid";
import {console_log} from "../log";

class CCQuickstart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            new_org_name: "",
            new_org_type: null,
            team_str: "",
            new_teammates: [],
            screen: 1,
        };
    }

    componentDidMount() {

    }

    renderBox(classes) {
        return <div className={classes.qsContainer}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>Canary Mail</Typography>
                        <Typography variant={"h2"}>Enterprise</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>• Free to use during pilot</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>• Send encrypted email</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>• Business associate agreement</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>• HIPAA compliant</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant={"body2"}>• GDPR compliant</Typography>
                    </Grid>
                </Grid>
            </Paper>
        </div>
    }

    renderScreen1(classes) {
        return <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
                <div className={classes.qsContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.activeTitle} style={{marginLeft: "0"}} variant="h1"><b>Let's Get Started</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(e) => {
                                this.setState({new_org_name: e.target.value})
                            }}
                                       id="outlined-required"
                                       label="Organization Name"
                                       value={this.state.new_org_name}
                                       disabled={false}
                                       variant="outlined"
                                       className={clsx(classes.marginRight, classes.textField, classes.gsField)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onChange={(e) => {
                                this.setState({new_org_name: e.target.value})
                            }}
                                       id="outlined-required"
                                       label="Email Address"
                                       disabled={true}
                                       value={this.props.user.email}
                                       variant="outlined"
                                       className={clsx(classes.marginRight, classes.textField, classes.gsField)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={clsx(classes.button, classes.gsField)} variant="contained"
                                    style={{height: "56px"}}
                                    disabled={this.state.new_org_name.length === 0}
                                    onClick={() => {
                                        this.setState({screen: 2})
                                    }
                                    }>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                { this.renderBox(classes) }
            </Grid>
        </Grid>
    }

    renderScreen2(classes) {
        return <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
                <div className={classes.qsContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.activeTitle} style={{marginLeft: "0"}} variant="h1"><b>Help us understand what you're looking for:</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                {/*<FormLabel component="legend">Gender</FormLabel>*/}
                                <RadioGroup aria-label="gender" name="gender1" value={this.state.new_org_type} onChange={(e) => {
                                    this.setState({
                                        new_org_type: e.target.value
                                    })
                                }
                                }>
                                    <FormControlLabel value="1" control={<Radio />} label="Simple HIPAA Compliance" />
                                    <FormControlLabel value="2" control={<Radio />} label="Easy to use PGP encryption" />
                                    <FormControlLabel value="3" control={<Radio />} label="Enterprise-grade email security for my team" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <Button className={clsx(classes.button, classes.gsField)} variant="contained"
                                    style={{height: "56px"}}
                                    disabled={this.state.new_org_type === null}
                                    onClick={() => {
                                        this.setState({screen: 3})
                                    }
                                    }>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                { this.renderBox(classes) }
            </Grid>
        </Grid>
    }

    is_email(item) {
        let comps = item.split("@")
        if (comps.length !== 2) {
            return false;
        }
        let username = comps[0];
        let domain = comps[1];
        if (username.length === 0 || domain.length === 0) {
            return false;
        }
        if (!domain.includes('.')) {
            return false;
        }
        return true;
    }

    parseEmails(items) {
        items = items.split(',')
        let emails = []
        items.forEach(email => {
            let cleaned = email.trim();
            if (cleaned.length > 0 && this.is_email(cleaned)) {
                emails.push(cleaned);
            }
        })
        if (emails.length === 0) {
            return;
        }
        let teammates = this.state.new_teammates;
        emails.forEach(email => {
            if (!teammates.includes(email)) {
                teammates.push(email);
            }
        })
        this.setState({team_str: "", new_teammates: teammates})
    }

    renderScreen3(classes) {
        return <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
                <div className={classes.qsContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <Typography className={classes.activeTitle} style={{marginLeft: "0"}} variant="h1"><b>Add teammates:</b></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField onKeyDown={(event) => {
                                if (event.key === 'Enter') {
                                    let items = event.target.value;
                                    this.parseEmails(items);
                                    event.preventDefault();
                                }
                            }
                            }
                                       onChange={event => {
                                           this.setState({team_str: event.target.value})
                                       }
                                       }
                                       id="outlined-required"
                                       label="Email Addresses"
                                       value={this.state.team_str}
                                       disabled={false}
                                       variant="outlined"
                                       className={clsx(classes.marginRight, classes.textField, classes.gsField)}
                            />
                            <Typography style={{marginTop: "10px"}} variant={"caption"}>Add 1 or more email addresses, separated by a comma. Press enter to add.</Typography>
                        </Grid>
                        {
                            this.state.new_teammates.length > 0 &&
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    {
                                        this.state.new_teammates.map(item => {
                                            return <Grid item xs={12}>
                                                <TextField id="outlined-required"
                                                           disabled={true}
                                                           value={item}
                                                           variant="outlined"
                                                           className={clsx(classes.marginRight, classes.textField, classes.gsField)}
                                                           InputProps={{
                                                               endAdornment:
                                                                   <InputAdornment position="end">
                                                                       <IconButton style={{color: "#265D97"}}
                                                                                   aria-label="delete email"
                                                                                   onClick={() => {
                                                                                       let teammates = this.state.new_teammates;
                                                                                       if (teammates.includes(item)) {
                                                                                           let ret = []
                                                                                           teammates.forEach(email => {
                                                                                               if (email !== item) {
                                                                                                   ret.push(email);
                                                                                               }
                                                                                           })
                                                                                           this.setState({
                                                                                               new_teammates: ret
                                                                                           });
                                                                                       }
                                                                                   }}
                                                                       >
                                                                           <Delete/>
                                                                       </IconButton>
                                                                   </InputAdornment>
                                                           }}
                                                />
                                            </Grid>
                                        })
                                    }
                                </Grid>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Button className={clsx(classes.button, classes.gsField)} variant="contained"
                                    style={{height: "56px"}}
                                    disabled={this.state.new_teammates.length === 0}
                                    onClick={() => {
                                        this.setState({screen: 4})
                                    }
                                    }>
                                Continue
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item xs={12} sm={6}>
                { this.renderBox(classes) }
            </Grid>
        </Grid>
    }

    renderScreen4(classes) {
        return <Grid container spacing={6}>
            <Grid item xs={12}>
                <div className={classes.qsContainer}>
                    <Grid container spacing={4}>
                        <Grid item xs={12} className={clsx(classes.column, classes.center)}>
                            <GridCheckCircleIcon style={{width: "64px", height: "64px"}}/>
                        </Grid>
                        <Grid item xs={12} className={clsx(classes.column, classes.center)}>
                            <Typography className={classes.activeTitle} style={{marginLeft: "0"}} variant="h1"><b>You're all set!</b></Typography>
                        </Grid>
                        <Grid item xs={12} className={clsx(classes.column, classes.center)}>
                            <Typography variant={"body1"} style={{textAlign: "center"}}>
                                You and your team can now start using Canary for Enterprise for free during the pilot. Please feel free to reach us via the Support button in case you have questions or need help!
                            </Typography>
                        </Grid>
                        <Grid item xs={12} className={clsx(classes.column, classes.center)}>
                            <Button className={clsx(classes.button, classes.gsField)} variant="contained"
                                    style={{height: "56px"}}
                                    disabled={this.state.new_org_name.length === 0 && false}
                                    onClick={() => {
                                        this.props.quickStartOrganization(this.state.new_org_name, this.state.new_org_type, this.state.new_teammates)
                                    }
                                    }>
                                Get Started
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    }

    render() {
        const {classes} = this.props;

        return <div className={classes.root100}>
            <CssBaseline/>
            <CCAppBar position="fixed" className={classes.appBar} headerRight={this.props.profile}/>
            <div className={classes.column}>
                <div className={classes.toolbar}/>
                <Container className={classes.orgRoot} style={{maxWidth: "940px"}}>
                    {
                        this.props.loading && <div className={classes.paper}>
                            <Grid container spacing={6}>
                                <Grid item xs={12}>
                                    <div className={classes.qsContainer}>
                                        <CircularProgress/>
                                    </div>
                                </Grid>
                            </Grid></div>
                    }
                    {
                        !this.props.loading && this.state.screen === 1 &&
                        <div className={classes.paper}>{ this.renderScreen1(classes) }</div>
                    }
                    {
                        !this.props.loading && this.state.screen === 2 &&
                        <div className={classes.paper}>{ this.renderScreen2(classes) }</div>
                    }
                    {
                        !this.props.loading && this.state.screen === 3 &&
                        <div className={classes.paper}>{ this.renderScreen3(classes) }</div>
                    }
                    {
                        !this.props.loading && this.state.screen === 4 &&
                        <div className={classes.paper}>{ this.renderScreen4(classes) }</div>
                    }
                </Container>
                <div className={classes.toolbar}/>
            </div>
        </div>
    }
}

export default withStyles(useStyles)(CCQuickstart)