import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Button, Checkbox, CircularProgress, Container, Paper, TextField, Typography} from "@material-ui/core";
import clsx from "clsx";
import {ChevronLeft, DeleteOutlined} from "@material-ui/icons";
import {getLicense} from "./api";
import {Grid} from "@mui/material";
import {DataGrid} from "@material-ui/data-grid";
import Autocomplete from '@mui/material/Autocomplete';
import {get_all_roles, kRoleAdmin, kRoleOwner} from "./data";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

class CCUser extends Component {
    constructor(props) {
        super(props);

        let license = null, devices = [], userStatus = false, showTeamsSection = true;
        let selectedTeamsForRole = {};
        let selectedRoles = [], remainingRoles = []
        this.email = "";
        this.isOwner = false;
        this.allRoles = get_all_roles()
        this.teamNameToId = {}
        this.teamIdToName = {}
        this.options = []

        if (props.teams) {
            props.teams.forEach(team => {
                this.options.push(team.name)
                this.teamNameToId[team.name] = team.id
                this.teamIdToName[team.id] = team.name
            });
        }
        if (props.user) {
            this.email = props.user.email
            this.isOwner = this.email.length > 0 && this.email === this.props.owner
            if (props.user.status !== true) {
                showTeamsSection = false
            }
            userStatus = props.user.status
            license = props.user.license
            devices = props.user.devices

            Object.entries(props.user.roles).forEach(([role, teams]) => {
                selectedTeamsForRole[role] = []
                teams.forEach(team_id => {
                    if (this.teamIdToName.hasOwnProperty(team_id)) {
                        selectedTeamsForRole[role].push(this.teamIdToName[team_id])
                    }
                })
                selectedRoles.push(role)
            })
            selectedRoles = this.getSortedRolesByPriority(selectedRoles)
        }
        if (selectedRoles.includes(kRoleOwner)) {
            this.allRoles[kRoleAdmin].allow_delete = false
        }
        Object.entries(this.allRoles).forEach(([role, value]) => {
            if (!selectedRoles.includes(role) && value.allow_delete) {
                remainingRoles.push(role)
            }
        })
        this.state = {
            loading: true,
            email: this.email,
            license: license,
            userStatus: userStatus,
            showTeamsSection: showTeamsSection,
            devices: devices,
            planError: null,
            selectedTeamsForRole: selectedTeamsForRole,
            selectedRoles: selectedRoles,
            remainingRoles: remainingRoles,
            emailErrorMessage: ""
        };
        this.refresh = this.refresh.bind(this);
        this.save = this.save.bind(this);
        this.getSortedRolesByPriority = this.getSortedRolesByPriority.bind(this);
    }

    getSortedRolesByPriority(arr) {
        return arr.sort((role1, role2) => {
            return this.allRoles[role1].priority - this.allRoles[role2].priority;
        });
    }

    save() {
        if (this.state.email.length === 0) {
            this.setState({
                emailErrorMessage: "Email cannot be empty",
            })
            return;
        }
        if (this.email !== this.state.email && this.props.all_emails.has(this.state.email)) {
            this.setState({
                emailErrorMessage: "License with " + this.state.email + " already exists",
            })
            return;
        }

        let roles = {}
        Object.entries(this.state.selectedTeamsForRole).forEach(([role, teamNames]) => {
            if (this.allRoles[role].allow_teams_selection === true && teamNames.length === 0) {
                return
            }
            roles[role] = []
            teamNames.forEach(teamName => {
                roles[role].push(this.teamNameToId[teamName])
            })
        })
        this.props.save(this.state.email, this.state.license, this.state.devices, roles)

    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        this.setState({
            loading: true
        }, () => {
            getLicense(this.props.org.org_uid, this.props.org.org_auth_key, this.state.license, json => {
                if (json.hasOwnProperty("license")) {
                    let item = json['license']
                    if (item.devices) {
                        item.devices.forEach(device => {
                            device["id"] = device["deviceID"]
                        })
                    }
                    this.setState({
                        loading: false,
                        devices: item.devices ? item.devices : [],
                    })
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const icon = <CheckBoxOutlineBlankIcon fontSize="small"/>;
        const checkedIcon = <CheckBoxIcon fontSize="small"/>;
        const getManageRoleAutoComplete = (value, disabled, allowMultipleSelection, required, disableCloseOnSelect, options, showAllOptions, textFieldLabel, onChange) => {
            return <Autocomplete
                className={classes.autoCompleteCustom}
                fullWidth={true}
                disabled={disabled}
                multiple={allowMultipleSelection}
                disableClearable={!allowMultipleSelection}
                value={value}
                options={options}
                disableCloseOnSelect={disableCloseOnSelect}
                onChange={onChange}
                renderOption={(props, option, {selected}) => {
                    const {key, ...optionProps} = props;
                    return (
                        <li key={key} {...optionProps} className={classes.autoCompleteOption}>
                            {
                                allowMultipleSelection &&
                                <Checkbox
                                    icon={icon}
                                    checkedIcon={checkedIcon}
                                    style={{marginRight: 8}}
                                    checked={selected}
                                />
                            }
                            {option}
                        </li>
                    );
                }}
                filterOption={(option, query) => {
                    return showAllOptions || option.toLowerCase().includes(query.toLowerCase());
                }}
                renderInput={(params) => (
                    <TextField {...params}
                               className={clsx(classes.textField)}
                               label={textFieldLabel}
                               placeholder=""
                               variant="outlined"
                               required={required}
                               InputProps={{
                                   ...params.InputProps,
                                   endAdornment: disabled ? null : params.InputProps.endAdornment,
                               }}
                    />
                )}
                renderTags={(value, getTagProps) => {
                    if (value.length === 0) {
                        return ""
                    }
                    return value.map((option, index) => option).join(', ')
                }}
            />
        }

        const getManageRoleGridItem = (role) => {
            const formattedRole = role.charAt(0).toUpperCase() + role.slice(1);
            const remainingRolesFormatted = this.state.remainingRoles.map(role => role.charAt(0).toUpperCase() + role.slice(1));
            return <Grid item xs={12}>
                <Grid container columnSpacing={3} columns={17}>
                    <Grid item xs={8}>
                        {
                            getManageRoleAutoComplete(
                                formattedRole,
                                this.allRoles[role].allow_delete === false,
                                false,
                                true,
                                false,
                                remainingRolesFormatted,
                                true,
                                "Role",
                                (event, value) => {
                                    const newRole = value.toLowerCase();
                                    const oldIndex = this.state.selectedRoles.indexOf(role);
                                    if (oldIndex !== -1) {
                                        let newSelectedRoles = this.state.selectedRoles;
                                        newSelectedRoles[oldIndex] = newRole;
                                        let newRemainingRoles = this.state.remainingRoles.filter(role => role !== newRole);
                                        newRemainingRoles.push(role);
                                        let newSelectedTeamsForRole = this.state.selectedTeamsForRole;
                                        newSelectedTeamsForRole[newRole] = newSelectedTeamsForRole[role];
                                        delete newSelectedTeamsForRole[role];
                                        this.setState({
                                            selectedRoles: newSelectedRoles,
                                            remainingRoles: newRemainingRoles
                                        })
                                    }
                                }
                            )
                        }
                    </Grid>
                    {
                        this.allRoles[role].allow_teams_selection && this.allRoles[role].allow_delete &&
                        <Grid item xs={8}>
                            {
                                getManageRoleAutoComplete(
                                    this.state.selectedTeamsForRole[role],
                                    false,
                                    true,
                                    true,
                                    true,
                                    this.options,
                                    false,
                                    "Teams",
                                    (event, newTeams) => {
                                        let update = this.state.selectedTeamsForRole;
                                        update[role] = newTeams;
                                        Object.entries(update).forEach(([key, value]) => {
                                            if (key !== role) {
                                                update[key] = value.filter(item => !newTeams.includes(item));
                                            }
                                        })
                                        this.setState({
                                            selectedTeamsForRole: update
                                        });
                                    })
                            }
                        </Grid>
                    }
                    {
                        this.allRoles[role].allow_delete &&
                        <Grid item xs={1}>
                            <Button className={clsx(classes.buttonOutlined, classes.buttonFullHeight)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    onClick={() => {
                                        let newSelectedTeamsForRole = this.state.selectedTeamsForRole;
                                        if (newSelectedTeamsForRole.hasOwnProperty(role)) {
                                            delete newSelectedTeamsForRole[role];
                                        }
                                        let newSelectedRoles = this.state.selectedRoles;
                                        newSelectedRoles = newSelectedRoles.filter(item => item !== role);
                                        let newRemainingRoles = this.state.remainingRoles;
                                        newRemainingRoles.push(role);
                                        this.setState({
                                            selectedRoles: newSelectedRoles,
                                            remainingRoles: newRemainingRoles,
                                            selectedTeamsForRole: newSelectedTeamsForRole
                                        });
                                    }}>
                                <DeleteOutlined/>
                            </Button>
                        </Grid>
                    }
                </Grid>
            </Grid>
        }

        const columns = [
            {field: 'deviceID', headerName: 'Device ID', flex: 1,},
            {field: 'name', headerName: 'Name', flex: 1,},
            {
                field: 'lastUpdated', headerName: 'Last Seen', flex: 1, valueGetter: params => {
                    if (!params.value || params.value === 0) {
                        return "Inactive"
                    } else {
                        let date = new Date(params.value * 1000);
                        return date.toLocaleDateString() + " " + date.toLocaleTimeString()
                    }
                }
            }
        ]

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button onClick={() => this.props.back()}><ChevronLeft/></Button>
                <Grid container spacing={3} className={classes.paper}>
                    <Grid item xs={12}>
                        <Typography variant="body2"><b>User</b></Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField onChange={(e) =>
                            this.setState({email: e.target.value})}
                                   className={clsx(classes.textField)}
                                   required
                                   fullWidth
                                   disabled={this.isOwner}
                                   id="outlined-required"
                                   label="Email Address"
                                   value={this.state.email}
                                   variant="outlined"
                                   error={this.state.emailErrorMessage.length > 0}
                                   helperText={this.state.emailErrorMessage}
                        />
                    </Grid>
                    {
                        this.state.license &&
                        <Grid item xs={12}>
                            <TextField onChange={(e) =>
                                this.setState({license: e.target.value})}
                                       className={clsx(classes.textField)}
                                       required
                                       disabled
                                       fullWidth
                                       id="outlined-required"
                                       label="License Key"
                                       value={this.state.license}
                                       variant="outlined"
                            />
                        </Grid>
                    }
                    <Grid item xs={12}>
                        {
                            this.state.devices.length === 0 ?
                                <Typography variant="body2"><b>No Devices Found</b></Typography> :
                                <Typography variant="body2"><b>Devices</b></Typography>
                        }
                    </Grid>
                    {
                        this.state.devices.length > 0 &&
                        <Grid item xs={12}>
                            <DataGrid autoHeight columns={columns}
                                      className={classes.paperGrid}
                                      rows={this.state.devices}
                                      pageSize={20}
                                // selectionModel={this.state.selected}
                                // disableColumnSelector={true}
                                // checkboxSelection={true}
                                // onSelectionModelChange={change => this.setState({selected: change})}
                                // onRowClick={item => {
                                //     this.setState({
                                //         active: item.row
                                //     })
                                // }}
                            />
                        </Grid>
                    }
                    {
                        this.state.showTeamsSection === true &&
                        <Grid item xs={12}>
                            <Typography variant="body2"><b>Teams</b></Typography>
                        </Grid>
                    }
                    {
                        this.state.showTeamsSection === true && this.state.selectedRoles.map(role => (
                            <React.Fragment key={role}>
                                {getManageRoleGridItem(role)}
                            </React.Fragment>
                        ))
                    }
                    {
                        this.state.selectedRoles.length >= 2 &&
                        <Grid item xs={12}>
                            <Typography variant="body2" color="textSecondary"> A user can hold either the Member role or the Manager role within one team, but not both simultaneously.</Typography>
                        </Grid>
                    }
                    {
                        this.state.showTeamsSection === true && this.state.remainingRoles.length > 0 &&
                        <Grid item xs={12}>
                            <div className={"row"}>
                                <Button className={classes.buttonOutlined}
                                        variant="outlined"
                                        size="large"
                                        onClick={() => {
                                            let remainingRoles = this.state.remainingRoles;
                                            remainingRoles = this.getSortedRolesByPriority(remainingRoles);
                                            const roleToAdd = remainingRoles[0];
                                            let newSelectedRoles = this.state.selectedRoles;
                                            newSelectedRoles.push(roleToAdd);
                                            let newRemainingRoles = remainingRoles.filter(role => role !== roleToAdd)
                                            let newSelectedTeamsForRole = this.state.selectedTeamsForRole;
                                            newSelectedTeamsForRole[roleToAdd] = [];
                                            this.setState({
                                                selectedRoles: newSelectedRoles,
                                                remainingRoles: newRemainingRoles,
                                                selectedTeamsForRole: newSelectedTeamsForRole
                                            });
                                        }}>
                                    Add Role
                                </Button>
                            </div>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <div className={"row"}>
                            <Button className={clsx(classes.button, classes.bottom)} variant="contained"
                                    onClick={() => {
                                        this.save()
                                    }}>
                                Save
                            </Button>
                            {
                                this.props.user &&  !this.isOwner &&
                                <Button className={clsx(classes.button, classes.bottom)} variant="contained"
                                        onClick={() => {
                                            this.props.delete(this.state.license)
                                        }} style={{marginLeft: "24px"}}>
                                    Delete
                                </Button>
                            }
                            {
                                this.props.user && this.state.userStatus === true &&
                                <Button className={clsx(classes.button, classes.bottom)} variant="contained"
                                        style={{marginLeft: "24px"}} onClick={(e) => {
                                    this.props.invite(this.state.email, this.state.license);
                                }
                                }>
                                    Re-send Invitation
                                </Button>
                            }
                        </div>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCUser)