import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Container,
    Grid,
    Paper,
    Typography
} from "@material-ui/core";
import {create_api_key, delete_api_keys, get_api_keys} from "./api";
import clsx from "clsx";
import {DataGrid, GridCellParams} from "@material-ui/data-grid";
import {ExpandMore} from "@material-ui/icons";
import CCApiExample from "./api/api_example";
import {
    api_delete_public_keys,
    api_get_public_key,
    api_list_public_keys,
    api_replace_public_keys,
    api_update_public_keys
} from "./api/api_configs";
import CCApiKeyCreate from "./api/api_key_create";


class CCDeveloper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            keys: null,
            selected: [],
            isCreating: false,
        };
        this.update = this.update.bind(this)
        this.scopeNames = {
            '/api/keys/update': 'Update Keys',
            '/api/keys/replace': 'Replace Keys',
            '/api/keys/delete': 'Delete Keys',
            '/api/keys/get': 'Get Key',
            '/api/keys/list': 'List Keys',
        }
        this.setCreate = this.setCreate.bind(this);
        this.clearCreate = this.clearCreate.bind(this);
        this.createApiKey = this.createApiKey.bind(this);
    }

    componentDidMount() {
        this.refresh()
    }

    refresh() {
        get_api_keys(this.props.org.org_uid, this.props.org.org_auth_key, json => {
            this.update(json)
        })
    }

    update(json) {
        console.log(json);
        if (json.hasOwnProperty("keys")) {
            json.keys.forEach(key => {
                key['id'] = key['key_id']
            })
            this.setState({
                keys: json.keys,
                available_scopes: json.available_scopes,
                loading: false,
            })
        } else {
            this.setState({
                keys: [],
                available_scopes: [],
                loading: false,
            })
        }
    }

    setCreate() {
        this.setState({
            isCreating: true,
        })
    }

    clearCreate() {
        this.setState({
            isCreating: false,
        })
    }

    createApiKey(scopes) {
        this.setState({
            isCreating: false,
            loading: true,
        }, () => {
            create_api_key(this.props.org.org_uid, this.props.org.org_auth_key, scopes, json => {
                this.refresh();
            })
        })
    }

    deleteApiKeys() {
        this.setState({
            loading: true,
        }, () => {
            let key_ids = []
            this.state.selected.forEach(key => {
                key_ids.push(key)
            })
            delete_api_keys(this.props.org.org_uid, this.props.org.org_auth_key, key_ids, json => {
                this.refresh();
            })
        });
    }

    renderScope(classes, scope) {
        let text = scope;
        if (scope in this.scopeNames) {
            text = this.scopeNames[scope];
        }
        return <code className={classes.code} style={{marginRight: "20px", paddingLeft: "8px", paddingRight: "8px"}}>{text}</code>
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        if (this.state.isCreating) {
            return <CCApiKeyCreate scopeNames={this.scopeNames} clear={this.clearCreate} createApiKey={this.createApiKey}/>
        }

        const columns = [
            // {field: 'key_id', headerName: 'ID', flex: 1,},
            {field: 'key_secret', headerName: 'API Key', flex: 1,},
            {field: 'scopes', headerName: 'Scopes', flex: 1, renderCell: (params: GridCellParams) => {
                return <div className={classes.row}>
                    {
                        params.value.map(item => {
                            return this.renderScope(classes, item);
                        })
                    }
                </div>
            }
            },
        ];

        const hasKeys = this.state.keys !== null && this.state.keys.length > 0;
        const key = hasKeys ? this.state.keys[0].key_secret : "YourApiKeyHere"
        const orgUid = this.props.org.org_uid
        let configs = [
            ['/api/keys/update', api_update_public_keys(orgUid, key)],
            ['/api/keys/replace', api_replace_public_keys(orgUid, key)],
            ['/api/keys/delete', api_delete_public_keys(orgUid, key)],
            ['/api/keys/get', api_get_public_key(orgUid, key)],
            ['/api/keys/list', api_list_public_keys(orgUid, key)]
        ]

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <div className={classes.rowFull}>
                            <Button variant="contained" className={clsx(classes.button, classes.right)}
                                    disabled={this.props.loading}
                                    onClick={e => {
                                        this.setCreate();
                                        // this.createApiKey(this.state.available_scopes)
                                    }}>
                                Create API Key
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <DataGrid autoHeight
                                  className={classes.paperGrid}
                                  columns={columns}
                                  checkboxSelection={true}
                                  selectionModel={this.state.selected}
                                  onSelectionModelChange={change => this.setState({selected: change})}
                                  rows={this.state.keys}
                                  pageSize={10}
                                  disableSelectionOnClick
                                  loading={this.state.loading}
                                  disableColumnResize={true}
                                  disableColumnSelector={true}/>
                    </Grid>
                    {
                        this.state.selected.length > 0 &&
                        <Grid item xs={12}>
                            <div className={classes.rowFull}>
                                <Button variant="contained" className={clsx(classes.button)}
                                        disabled={this.props.loading}
                                        onClick={e => {
                                            this.deleteApiKeys();
                                        }}>
                                    Delete
                                </Button>
                            </div>
                        </Grid>
                    }
                    {
                        <Grid item xs={12}>
                            <Typography>Examples:</Typography>
                        </Grid>
                    }
                    {
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                {
                                    configs.map(item => {
                                        let scope = item[0]
                                        let config = item[1]
                                        return <Grid item xs={12}>
                                            <Accordion className={classes.paperLight}>
                                                <AccordionSummary expandIcon={<ExpandMore style={{color: "#fff"}}/>}>
                                                    {
                                                        this.renderScope(classes, scope)
                                                    }
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <CCApiExample config={config}/>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    })
                                }
                            </Grid>
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCDeveloper)