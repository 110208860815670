import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Button, CircularProgress, Container, Grid, Paper, Typography} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import {DropzoneArea} from "material-ui-dropzone";
import {DataGrid} from "@material-ui/data-grid";
import clsx from "clsx";

const Papa = require("papaparse")

class CCUserCsv extends Component {
    constructor(props) {
        super(props);

        let license = null, devices = []

        this.state = {
            loading: true,
            emails: [],
            license: license,
            devices: devices,
            planError: null
        };

        this.readFile = this.readFile.bind(this)
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    readFile(file) {
        let self = this;
        Papa.parse(file, {
            complete: function (results) {
                let ret = []
                results.data.forEach(item => {
                    ret.push({
                        id: item[0],
                    })
                });
                self.setState({
                    emails: ret
                })
            }
        });
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const columns = [
            {field: 'id', headerName: 'Email', flex: 1,},
        ]

        let isValid = this.props.remaining >= this.state.emails.length;

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button onClick={() => this.props.back()}><ChevronLeft/></Button>
                <Grid container spacing={3} className={classes.paper}>
                    <Grid item xs={12}>
                        <Typography variant="body2"><b>Import CSV</b></Typography>
                    </Grid>
                    {
                        this.state.emails.length > 0 &&
                        <Grid item xs={12}>
                            <DataGrid autoHeight columns={columns}
                                      className={classes.paperGrid}
                                      rows={this.state.emails}
                                      pageSize={5}
                                      disableColumnSelector={true}/>
                        </Grid>

                    }
                    {
                        this.state.emails.length === 0 &&
                        <Grid item xs={12}>
                            <DropzoneArea onChange={(files) => {
                                if (files.length > 0) {
                                    this.readFile(files[0])
                                }
                            }} showPreviews={false}/>
                        </Grid>
                    }
                    {
                        this.state.emails.length > 0 &&
                        <Grid item xs={12}>
                            {
                                !isValid ?
                                    <Typography>
                                        Your list has {this.state.emails.length} users, but you only
                                        have {this.props.remaining} seats available. Please
                                        add more seats and try again.
                                    </Typography> :
                                    <Button className={classes.button} onClick={e => {
                                        let emails = []
                                        this.state.emails.forEach(email => {
                                            emails.push(email.id);
                                        });
                                        this.props.save(emails);
                                    }}>
                                        Save
                                    </Button>
                            }
                        </Grid>
                    }
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCUserCsv)