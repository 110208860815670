import {withStyles} from "@material-ui/core/styles";
import {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Grid} from "@material-ui/core";
import {PaymentElement} from "@stripe/react-stripe-js";
import {save_org_state} from "../api";
import Typography from "@material-ui/core/Typography";
import {rootAdminUrl} from "../../constants";

class CCCheckout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    handleSubmit = async (event) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        const {stripe, elements} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        this.setState({
            loading: true,
            error: null,
        })

        save_org_state(window, this.props.org.org_uid, "Subscription");

        const result = await stripe.confirmPayment({
            //`Elements` instance that was used to create the Payment Element
            elements,
            confirmParams: {
                return_url: rootAdminUrl
            },
        });

        console.log(result);

        if (result.error) {
            // Show error to your customer (for example, payment details incomplete)
            this.setState({
                error: result.error.message,
                loading: false,
            });
            console.log(result.error.message);
        } else {
            // Your customer will be redirected to your `return_url`. For some payment
            // methods like iDEAL, your customer will be redirected to an intermediate
            // site first to authorize the payment, then redirected to the `return_url`.
            this.setState({
                error: null,
                loading: false,
            })
        }
    };

    render() {
        const {classes} = this.props;
        return (
            <Grid container spacing={3}>
                {/*<Grid item xs={12}>*/}
                {/*    <Typography>{*/}
                {/*        this.state.error != null ? this.state.error : "Please enter your payment information:"*/}
                {/*    }</Typography>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    <PaymentElement/>
                </Grid>
                <Grid item xs={12}>
                    <div className={classes.rowFull}>
                        {
                            this.state.loading ? <CircularProgress/> :
                                <Button className={classes.button}
                                        disabled={!this.props.stripe}
                                        variant="contained"
                                        onClick={this.handleSubmit}>Submit</Button>
                        }
                        {
                            this.state.error && <Typography style={{marginLeft: "16px"}}>{this.state.error}</Typography>
                        }
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(useStyles)(CCCheckout)