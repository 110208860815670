import {
    Apartment, Dns,
    Email, Extension,
    Group,
    Help,
    Info, KeyboardArrowRightRounded,
    Lock, OpenInNewRounded,
    Payment, Settings,
    SupervisorAccount,
    Tune,
    VerifiedUser, VpnKey,
    SettingsOutlined, Person,
} from "@material-ui/icons";
import {
    Groups,
    WorkspacePremiumOutlined,
} from "@mui/icons-material"
import InboxIcon from "@material-ui/icons/MoveToInbox";
import {Box, Collapse} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import React from "react";
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import clsx from "clsx";
import {
    kConsolePrefBilling,
    kConsolePrefConfiguration, kConsolePrefEncryption,
    kConsolePrefOrganization, kConsolePrefTeamsAndUsers,
    kPrefComposeSuggestions,
    kPrefCopilotChatbot,
    kPrefCopilotCompose,
    kPrefCopilotInboxFilters,
} from "./data";

function getInfoText(classes, activeTab) {
    if (activeTab === 'Overview') {
        return <span>View & update your Organization’s key info.&nbsp;<a className={classes.urlClean} href={"https://canarymail.io/help/overview/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a></span>
    }
    if (activeTab === 'Admins') {
        return <span>Invite additional Admins for your Organization.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/admins/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === 'Key Management') {
        return <span>Manage public keys for all your users. Keys uploaded here are used by Canary automatically, users will not need to manually import these public keys when composing new emails. Any changes you make here (eg if you delete a key) are synced in realtime to all user devices. New public keys uploaded by users will appear in the ‘Unapproved Keys’ section for review.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/key-management/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "Key Servers") {
        return <span>Specify your preferred key server(s) that support HKPS. All user devices will automatically point to the key servers specified here.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/key-servers/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "Master Keys") {
        return <span>Specify your Organization’s public keys. Canary will use these keys to encrypt all outgoing emails sent by your users, in addition to the email recipients’ public keys.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/master-keys/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "Email Setup") {
        return <span>Specify your Organization’s email provider settings. Users will see these provider(s) at the top of the list in the in-app ‘Add Account’ screen.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/email-setup/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "App Preferences") {
        return <span>Set default in-app settings for all users, which will be applied automatically when they start using Canary. Select ‘Enforce’ if you would like to disallow changing that specific setting.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/app-preferences/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "API Access") {
        return <span>Add & manage your API keys here & review available API calls.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/api-access/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "Active Users") {
        return <span>Invite your teammates manually, or by importing a .csv file, or by setting up Directory Sync. Invited users will receive a ‘Getting Started’ email with a link to download Canary. This is a deep link that will automatically activate their license for Android & iOS. For macOS, they must click on the ‘Activate’ button when viewing the email in Canary, to activate the license.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/active-users/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "Subscription") {
        return <span>Specify the number of licenses for your Organization (1 license per user). Licenses are assigned per user and are valid across all platforms (macOS, iOS, Windows & Android) and geographies.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/subscription/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === "Directory Sync") {
        return <span>Connect your organization's directory for automated real-time provisioning and deprovisioning of users. All users will receive a ‘Getting Started’ email with a link to download Canary. This is a deep link that will automatically activate their license for Android & iOS. For macOS, they must click on the ‘Activate’ button when viewing the email in Canary, to activate the license.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/directory-sync/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === 'Manage Teams') {
        return <span>Add and manage teams and managers within your organization&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/manage-teams/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    if (activeTab === 'Console Preferences') {
        return <span>Customize your admin console preferences and default settings here.&nbsp;
            <a className={classes.urlClean} href={"https://canarymail.io/help/console-preferences/"} target={"_blank"} rel={"noreferrer"}>Learn More &gt;</a>
        </span>
    }
    return null
}

function getIcon(name) {
    if (name === 'Organization') {
        return <Apartment/>
    } else if (name === 'Overview') {
        return <Info/>
    } else if (name === 'Admins') {
        return <SupervisorAccount/>
    } else if (name === 'Console Preferences') {
       return <SettingsOutlined/>
    } else if (name === 'Encryption') {
        return <Lock/>
    } else if (name === 'Key Management') {
        return <VerifiedUser/>
    } else if (name === 'Email Setup') {
        return <Email/>
    } else if (name === 'App Preferences') {
        return <Tune/>
    } else if (name === 'User Management' || name === 'Manage Teams') {
        return <Group/>
    } else if (name === 'Teams & Users') {
        return <Groups/>
    } else if (name === 'Active Users') {
        return <Person/>
    } else if (name === 'Billing') {
        return <Payment/>
    } else if (name === 'Subscription') {
        return <WorkspacePremiumOutlined/>
    } else if (name === 'Support') {
        return <Help/>
    } else if (name === 'Key Servers') {
        return <Dns/>
    } else if (name === 'Master Keys') {
        return <VpnKey/>
    } else if (name === 'Configuration') {
        return <Settings/>
    } else if (name === 'API Access') {
        return <Extension/>
    } else if (name === 'Directory Sync') {
        return <InboxIcon/>
    } else {
        return <InboxIcon/>
    }
}

function getOpenIcon(classes, open) {
    return <KeyboardArrowRightRounded className={classes.itemOpenIcon} style={{
        transform: open && 'rotate(90deg)',
    }}/>
}

function getExternalIcon(classes, open) {
    return <OpenInNewRounded className={classes.itemOpenIcon}/>
}

function getListIcon(classes, text) {
    return <Box
        component="span"
        className={classes.itemIcon}>
        {getIcon(text)}
    </Box>
}

function getListItem(classes, text, className, onClick, depth, open, activeTab, setActiveTab, showExternalIcon=false) {
    return <ListItem button
                     className={className && className}
                     key={text}
                     selected={text === activeTab}
                     onClick={onClick != null ? onClick : () => {
                         setActiveTab(text)
                     }}>
        {getListIcon(classes, text)}
        <span>{text}</span>
        {depth === 0 && getOpenIcon(classes, open)}
        {showExternalIcon && getExternalIcon(classes, open)}
    </ListItem>
}

function getDrawer(classes, state, activeTab, setActiveTab, toggleDrawerSection, org) {
    return <div>
        <div className={classes.toolbar}/>
        <Divider/>
        <List className={classes.list}>
            {
                org.is_admin &&
                getListItem(classes, "Organization", clsx(classes.li), () => {
                    toggleDrawerSection("showOrganizationMenu")
                }, 0, state.showOrganizationMenu, activeTab, setActiveTab)
            }
            {
                org.is_admin &&
                <Collapse in={state.showOrganizationMenu} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {
                            getListItem(classes, "Overview", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            getListItem(classes, "Admins", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            getListItem(classes, "Console Preferences", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                    </List>
                </Collapse>
            }
            {
                state.consolePrefsState[kConsolePrefEncryption].value &&
                getListItem(classes, "Encryption", classes.li, () => {
                    toggleDrawerSection("showEncryptionMenu")
                }, 0, state.showEncryptionMenu, activeTab, setActiveTab)
            }
            {
                state.consolePrefsState[kConsolePrefEncryption].value &&
                <Collapse in={state.showEncryptionMenu} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {
                            getListItem(classes, "Key Management", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            getListItem(classes, "Key Servers", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            getListItem(classes, "Master Keys", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                    </List>
                </Collapse>
            }
            {
                state.consolePrefsState[kConsolePrefConfiguration].value &&
                getListItem(classes, "Configuration", classes.li, () => {
                    toggleDrawerSection("showConfigurationMenu")
                }, 0, state.showConfigurationMenu, activeTab, setActiveTab)
            }
            {
                state.consolePrefsState[kConsolePrefConfiguration].value &&
                <Collapse in={state.showConfigurationMenu} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {
                            getListItem(classes, "Email Setup", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            getListItem(classes, "App Preferences", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            getListItem(classes, "API Access", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                    </List>
                </Collapse>
            }
            {
                state.consolePrefsState[kConsolePrefTeamsAndUsers].value &&
                getListItem(classes, "Teams & Users", clsx(classes.li), () => {
                    toggleDrawerSection("showUserManagementMenu")
                }, 0, state.showUserManagementMenu, activeTab, setActiveTab)
            }
            {
                state.consolePrefsState[kConsolePrefTeamsAndUsers].value &&
                <Collapse in={state.showUserManagementMenu} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {
                            getListItem(classes, "Manage Teams", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            state.isAdmin &&
                            getListItem(classes, "Active Users", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                        {
                            state.isAdmin &&
                            getListItem(classes, "Directory Sync", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                    </List>
                </Collapse>
            }
            {
                state.consolePrefsState[kConsolePrefBilling].value &&
                getListItem(classes, "Billing", classes.li, () => {
                    toggleDrawerSection("showBillingMenu")
                }, 0, state.showBillingMenu, activeTab, setActiveTab)
            }
            {
                state.consolePrefsState[kConsolePrefBilling].value &&
                <Collapse in={state.showBillingMenu} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        {
                            getListItem(classes, "Subscription", clsx(classes.li, classes.item1), null, 1, false, activeTab, setActiveTab)
                        }
                    </List>
                </Collapse>
            }
        </List>
    </div>
}

export {
    getInfoText, getListItem, getDrawer, getIcon
}