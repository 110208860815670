import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Box, Checkbox, CircularProgress, Container, Paper, Switch, TextField, Typography,} from "@material-ui/core";
import {DataGrid, GridCellParams} from "@material-ui/data-grid";
import clsx from "clsx";
import {getIcon} from "./info";
import {Grid} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {get_teams, set_default_team} from "./api";

class CCConsolePreferences extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            hasLoaded: true,
            teams: [],
        };
        this.setSwitchPref = this.setSwitchPref.bind(this);
        this.setDefaultTeam = this.setDefaultTeam.bind(this);
        this.refresh = this.refresh.bind(this);
    }

    setSwitchPref(key, value) {
        this.setState({
            loading: true,
        }, () => {
            this.props.setConsolePref(key, value, () => {
                this.setState({
                    loading: false,
                    hasLoaded: true,
                });
            });
        });
    }

    setDefaultTeam(teamId) {
        this.setState({
            loading: true,
        }, () => {
            set_default_team(this.props.org.org_uid, this.props.org.org_auth_key, teamId, () => {
                this.refresh();
            });
        });
    }

    refresh() {
        get_teams(this.props.org.org_uid, this.props.org.org_auth_key, this.props.org.user_email, (json) => {
            let selectedTeam = null;
            let teams = [];
            if (json) {
                const defaultTeamId = json.hasOwnProperty('default_team_id') ? json.default_team_id : null;
                teams = json.hasOwnProperty('teams') ? json.teams : [];
                teams.forEach(team => {
                    if (team.id === defaultTeamId) {
                        selectedTeam = team;
                    }
                });
            }
            this.setState({
                loading: false,
                hasLoaded: true,
                selectedTeam: selectedTeam,
                teams: teams
            });
        });
    }

    componentDidMount() {
        this.refresh()
    }

    render() {
        const {classes, consolePrefs, consolePrefsMap} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        const columns = [
            {
                field: 'text', flex: 2,
                renderCell: (params: GridCellParams) => {
                    let pref = consolePrefsMap[params.id];
                    return <div className={classes.rowFull}>
                        <Box
                            component="span"
                            className={classes.itemIcon}>
                            {getIcon(pref.text)}
                        </Box>
                        <Typography>
                            {pref.text}
                        </Typography>
                    </div>
                }
            },
            {
                field: 'value', flex: 2,
                renderCell: (params: GridCellParams) => {
                    let pref = consolePrefsMap[params.id];
                    return <div className={classes.rowFull}>
                        <Switch
                            checked={this.props.consolePrefsState[pref.id].value}
                            onChange={(e) => this.setSwitchPref(pref.id, e.target.checked)}
                            color="primary"
                            disabled={!pref.editable}
                            name="checkedB"
                            inputProps={{'aria-label': 'primary checkbox'}}
                        />
                    </div>
                }
            }
        ];

        const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
        const checkedIcon = <CheckBoxIcon fontSize="small" />;

        return <Container className={classes.root}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={clsx(classes.paper, classes.paperLight)}>
                        <DataGrid autoHeight
                                  className={classes.paperGrid}
                                  pageSize={consolePrefs.length}
                                  rowHeight={80}
                                  pagination={false}
                                  columns={columns}
                                  disableSelectionOnClick
                                  hideFooter={true}
                                  headerHeight={0}
                                  rows={this.state.hasLoaded ? consolePrefs : []}
                                  loading={this.state.loading}
                                  disableColumnResize={true}
                                  disableColumnSelector={true}/>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className={classes.bold}>
                        Default Team
                    </Typography>
                </Grid>
                <Grid item={12} className={classes.paper}>
                    <Paper className={clsx(classes.paper, classes.paperLight)}>
                        <Grid container spacing={2} className={classes.center}>
                            <Grid item xs={6}>
                                <Typography>
                                    If no team is selected, add new users to
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Autocomplete
                                    className={classes.autoCompleteCustom}
                                    multiple={false}
                                    sx={{ width: 400 }}
                                    fullWidth={false}
                                    getOptionLabel={(option) => option.name}
                                    value={this.state.selectedTeam}
                                    options={this.state.teams}
                                    disableCloseOnSelect={false}
                                    onChange={(event, newValue) => {
                                        if (newValue === null || this.state.selectedTeam === null || newValue.id !== this.state.selectedTeam.id) {
                                            this.setDefaultTeam(newValue === null ? null : newValue.id);
                                        }
                                    }}
                                    renderOption={(props, option, { selected }) => {
                                        const { key, ...optionProps } = props;
                                        return (
                                            <li key={key} {...optionProps} className={classes.autoCompleteOption}>
                                                {option.name}
                                            </li>
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            className={clsx(classes.textField)}
                                            label={"Team"}
                                            placeholder="Select Team"
                                            variant="outlined"
                                        />
                                    )}
                                    renderTags={(value, getTagProps) => {
                                        if (value.length === 0) {
                                            return "";
                                        }
                                        return value.name;
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Container>;
    }
}

export default withStyles(useStyles)(CCConsolePreferences)