function api_update_public_keys(org_uid, api_key) {
    return {
        info: 'Add or update approved public keys. A key is updated when its key id is the same as a previously uploaded key.',
        org_uid: org_uid,
        api_key: api_key,
        api_url: 'https://api.canarymail.io/api/keys/update',
        example: '{\n' +
            '  "org_id": "' + org_uid + '",\n' +
            '  "api_key": "' + api_key + '",\n' +
            '  "public_key_data": "-----BEGIN PGP PUBLIC KEY BLOCK-----\\n...."\n' +
            '  }\n' +
            '}',
        response: '{\n' +
            '   "status": "200"\n' +
            '}',
        params: [
            {'name': 'org_id', 'info': 'Your organization id'},
            {'name': 'api_key', 'info': 'Your api key'},
            {'name': 'public_key_data', 'info': 'Ascii armored string containing all your organization public keys'}
        ]
    }
}


function api_replace_public_keys(org_uid, api_key) {
    return {
        info: 'Replace all approved public keys.',
        org_uid: org_uid,
        api_key: api_key,
        api_url: 'https://api.canarymail.io/api/keys/replace',
        example: '{\n' +
            '  "org_id": "' + org_uid + '",\n' +
            '  "api_key": "' + api_key + '",\n' +
            '  "public_key_data": "-----BEGIN PGP PUBLIC KEY BLOCK-----\\n...."\n' +
            '  }\n' +
            '}',
        response: '{\n' +
            '   "status": "200"\n' +
            '}',
        params: [
            {'name': 'org_id', 'info': 'Your organization id'},
            {'name': 'api_key', 'info': 'Your api key'},
            {'name': 'public_key_data', 'info': 'Ascii armored string containing all your organization public keys'}
        ]
    }
}

function api_delete_public_keys(org_uid, api_key) {
    return {
        info: 'Delete approved public keys that match the given key ids.',
        org_uid: org_uid,
        api_key: api_key,
        api_url: 'https://api.canarymail.io/api/keys/delete',
        example: '{\n' +
            '  "org_id": "' + org_uid + '",\n' +
            '  "api_key": "' + api_key + '",\n' +
            '  "key_ids": ["key_id_1", "key_id_2"]\n' +
            '  }\n' +
            '}',
        response: '{\n' +
            '   "status": "200"\n' +
            '}',
        params: [
            {'name': 'org_id', 'info': 'Your organization id'},
            {'name': 'api_key', 'info': 'Your api key'},
            {'name': 'key_ids', 'info': 'Array of string key ids to delete. ' +
                    'Each key id is the last 16 characters of the public key fingerprint in lower case.'}
        ]
    }
}

function api_get_public_key(org_uid, api_key) {
    return {
        info: 'Retrieve a single approved public key that matches the given key id.',
        org_uid: org_uid,
        api_key: api_key,
        api_url: 'https://api.canarymail.io/api/keys/get',
        example: '{\n' +
            '  "org_id": "' + org_uid + '",\n' +
            '  "api_key": "' + api_key + '",\n' +
            '  "key_id": "key_id_1"\n' +
            '  }\n' +
            '}',
        response: '{\n' +
            '   "status": 200,\n' +
            '   "key": {\n' +
            '       "primary_email": "someone@example.com",\n' +
            '       "emails": [\n' +
            '           "someone@example.com"\n' +
            '       ],\n' +
            '       "id": "key_id_1",\n' +
            '       "expiry": 1781929700000,\n' +
            '       "md5": "c188d8be16c4354d48a7152759fd3fd5",\n' +
            '       "armored": "-----BEGIN PGP PUBLIC KEY BLOCK-----\\n\\n..."\n' +
            '   }\n' +
            '}',
        params: [
            {'name': 'org_id', 'info': 'Your organization id'},
            {'name': 'api_key', 'info': 'Your api key'},
            {'name': 'key_id', 'info': 'String key id to retrieve'}
        ]
    }
}

function api_list_public_keys(org_uid, api_key) {
    return {
        info: 'List all approved public keys. Returns a dictionary of key ids to ascii armored key data.',
        org_uid: org_uid,
        api_key: api_key,
        api_url: 'https://api.canarymail.io/api/keys/list',
        example: '{\n' +
            '  "org_id": "' + org_uid + '",\n' +
            '  "api_key": "' + api_key + '",\n' +
            '  }\n' +
            '}',
        response: '{\n' +
            '   "status": "200"\n' +
            '   "keys": "{\n' +
            '       "key_id_1": "-----BEGIN PGP PUBLIC KEY BLOCK-----\\n....",\n' +
            '       "key_id_2": "-----BEGIN PGP PUBLIC KEY BLOCK-----\\n...."\n' +
            '   }"\n' +
            '}',
        params: [
            {'name': 'org_id', 'info': 'Your organization id'},
            {'name': 'api_key', 'info': 'Your api key'},
        ]
    }
}

export {
    api_update_public_keys,
    api_replace_public_keys,
    api_delete_public_keys,
    api_get_public_key,
    api_list_public_keys
}